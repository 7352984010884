import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
      currentPage: state.currentPage.value,
      searchTxt: state.searchTxt.value,
      totalFieldsFilter: state.totalFieldsFilter.value,
      orderBy: state.orderBy.value,
      showListviewFilter: state.showListviewFilter.value,
      showListview: () => {
        if (state.totalRows.value > 0) {
          return true
        }

        return false
      },
      showPager: () => {
        if (state.totalRows.value > state.perPage.value) {
          return true
        }

        return false
      },
    }),
    txt: state => ({
      searchPlaceholder: state.txtSearchPlaceholder,
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    form: state => ({
      tipos: state.formTipos.value,
      imoveis: state.formImoveis.value,
      tipologias: state.formTipologias.value,
      tipologiasEspecial: state.formTipologiaEspecial.value,
      condicaoImovel: state.formCondicaoImovel.value,
      periodos: state.formPeriodos.value,
      recorrerFinanciamento: state.formRecorrerFinanciamento.value,
      obrasImovel: state.formObrasImovel.value,
    }),
    fieldsOrder: state => state.fieldsOrder,
    showFormCreateNew: state => state.showFormCreateNew.value,
    loadingFormCreateNew: state => state.loadingFormCreateNew.value,
    formModeView: state => state.formModeView.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.orderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    addFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value = 0
    },
    setCreateNewSW372(state, payload) {
      state.showFormCreateNew.value = payload
    },
    setLoadingDataSW372(state, payload) {
      state.loadingFormCreateNew.value = payload
    },
    removeSW372(state, payload) {
      if (payload in state.listviewData.value) {
        state.listviewData.value.splice(payload, 1)
        state.totalRows.value -= 1

        if (state.totalRows.value === 0) {
          state.showListviewFilter.value = false
        }
      }
    },
    setFormTipos(state, payload) {
      state.formTipos.value = []
      if (Array.isArray(payload)) {
        state.formTipos.value = payload
      }
    },
    setFormCondicaoImovel(state, payload) {
      state.formCondicaoImovel.value = []
      if (Array.isArray(payload)) {
        state.formCondicaoImovel.value = payload
      }
    },
    setFormPeriodos(state, payload) {
      state.formPeriodos.value = []
      if (Array.isArray(payload)) {
        state.formPeriodos.value = payload
      }
    },
    setFormRecorrerFinanciamento(state, payload) {
      state.formRecorrerFinanciamento.value = []
      if (Array.isArray(payload)) {
        state.formRecorrerFinanciamento.value = payload
      }
    },
    setFormObrasImovel(state, payload) {
      state.formObrasImovel.value = []
      if (Array.isArray(payload)) {
        state.formObrasImovel.value = payload
      }
    },
    setFormImoveis(state, payload) {
      state.formImoveis.value = []
      state.formTipologiaEspecial.value = []
      if (Array.isArray(payload)) {
        state.formImoveis.value = payload
      } else {
        Object.keys(payload).forEach(index => {
          state.formImoveis.value.push(payload[index])
        })
      }
    },
    setFormTipologias(state, payload) {
      state.formTipologias.value = []
      if (Array.isArray(payload)) {
        state.formTipologias.value = payload
      }
    },
    setFormTipologiasEspecial(state, payload) {
      state.formTipologiaEspecial.value = []

      if (Array.isArray(payload)) {
        const options = []

        payload.forEach(o => {
          const newOption = {}
          newOption.id = null
          newOption.txt = null
          newOption.group = o.txt
          options.push(newOption)

          if (o.tipos.length > 0) {
            o.tipos.forEach(oTipo => {
              const newSubOption = {}
              newSubOption.id = oTipo.sw004s01
              newSubOption.txt = oTipo.sw004s02
              newSubOption.group = null
              options.push(newSubOption)
            })
          }
        })

        state.formTipologiaEspecial.value = options
      }
    },
    setFormModeView(state, payload) {
      state.formModeView.value = payload
    },
    setShowListviewFilter(state, payload) {
      state.showListviewFilter.value = payload
    },
  },
  actions: {

    async loadListviewData({
      state, commit, dispatch, rootGetters,
    }, payload) {
      if (rootGetters['crm_leads_form/loaded'] === true) {
        await dispatch('request/cancelTokensPendingByUrl', 'leads/loadListQualificacoesComprador', { root: true })
      }

      const request = await new Promise((resolve, reject) => {
        commit('resetFilterSelectedTotalFields')

        const formData = new FormData()
        formData.append('sw372s02', rootGetters['crm_leads_form/keyReg'])
        formData.append('sw372s03', rootGetters['crm_leads_form/dynamicIDForm'])
        formData.append('preview', rootGetters['crm_leads_form/modeFormAction'])
        formData.append('start', ((state.currentPage.value - 1) * state.perPage.value))
        formData.append('length', state.perPage.value)

        if (state.searchTxt.value !== '') {
          formData.append('fSearch', state.searchTxt.value)
          commit('addFilterSelectedTotalFields')
        }

        if (state.orderBy.value.column !== undefined) {
          formData.append('orderByColumn', state.orderBy.value.column)
        }

        if (state.orderBy.value.sort !== undefined) {
          formData.append('orderBySort', state.orderBy.value.sort)
        }

        if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
          commit('setListviewInit', 1)
        } else {
          commit('setListviewData', [])
          commit('setTotalRows', 0)
          commit('setLoadingListview', true)
          commit('setListviewInit', 1)
        }

        api.post(`${apiConfig.url_base_api}leads/loadListQualificacoesComprador`, formData)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setListviewData', response.data.data)
                commit('setTotalRows', response.data.recordsTotal)
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                if (!!payload && (payload.init !== undefined) && (payload.init === true) && (response.data.recordsTotal > 0)) {
                  commit('setShowListviewFilter', true)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar a qualificação como comprador')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar a qualificação como comprador')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar a qualificação como comprador')))
            }
          })
      })

      return request
    },

    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.orderBy.value.column) && (state.orderBy.value.column === field)) {
        if (state.orderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('loadListviewData')
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('loadListviewData')
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true })
    },

    setShowFormCreateNew({ commit }, value) {
      commit('setCreateNewSW372', value)
    },

    setLoadingDataSW372({ commit }, value) {
      commit('setLoadingDataSW372', value)
    },

    createNewSW372({ commit }) {
      commit('setFormModeView', false)
      commit('setLoadingDataSW372', false)
      commit('setCreateNewSW372', true)
    },

    setFormModeView({ commit }, value) {
      commit('setFormModeView', value)
    },

    setFormImoveis({ commit }, value) {
      commit('setFormImoveis', value)
    },

    async modalNewQualificacao({
      commit, dispatch, rootGetters,
    }) {
      return new Promise((resolve, reject) => {
        let sw025s01 = rootGetters['crm_leads_form/keyReg']
        const dynamicIDForm = rootGetters['crm_leads_form/dynamicIDForm']

        if (sw025s01 === '') {
          sw025s01 = 0
        }

        if (rootGetters['crm_leads_form/loaded'] === true) {
          dispatch('request/cancelTokensPendingByUrl', 'leads/modalNewQualificacao', { root: true })
        }

        api.get(`${apiConfig.url_base_api}leads/modalNewQualificacao/${sw025s01}${(dynamicIDForm) ? `/${dynamicIDForm}` : ''}`)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                commit('setFormTipos', response.data.data.tiposImoveisCategoria)
                commit('setFormCondicaoImovel', response.data.data.condicao_imovel)
                commit('setFormPeriodos', response.data.data.periodos)
                commit('setFormRecorrerFinanciamento', response.data.data.recorrerFinanciamento)
                commit('setFormObrasImovel', response.data.data.obrasImovel)
                commit('setFormTipologias', response.data.data.classes_tipologias_geral)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o formulário da qualificação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o formulário da qualificação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o formulário da qualificação')))
            }
          })
      })
    },

    async saveSW372({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        // Coloca os campos null em vazio
        Object.keys(payload).forEach(field => {
          if (payload[field] === null) {
            // eslint-disable-next-line no-param-reassign
            payload[field] = ''
          }
        })

        const formData = new FormData()
        formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'])
        formData.append('dynamicIDForm', rootGetters['crm_leads_form/dynamicIDForm'])

        if (payload.sw372s01 !== null) {
          formData.append('sw372s01', btoa(payload.sw372s01))
        } else {
          formData.append('sw372s01', '')
        }

        if ((payload.sw372s04 !== '') && ('sw087s01' in payload.sw372s04)) {
          formData.append('sw372s04', payload.sw372s04.sw087s01)
        }

        if (Array.isArray(payload.sw372s05)) {
          if (payload.sw372s05.length > 0) {
            payload.sw372s05.forEach(o => {
              formData.append('sw372s05[]', o.sw010s01)
            })
          }
        }

        formData.append('sw372s06', payload.sw372s06)

        if ((typeof payload.sw372s07 !== 'undefined' && payload.sw372s07 !== '') && ('id' in payload.sw372s07)) {
          formData.append('sw372s07', payload.sw372s07.id)
        }

        formData.append('sw372s08', payload.sw372s08)

        if ((typeof payload.sw372s09 !== 'undefined' && payload.sw372s09 !== '') && ('id' in payload.sw372s09)) {
          formData.append('sw372s09', payload.sw372s09.id)
        }

        formData.append('sw372s10', payload.sw372s10)
        formData.append('sw372s11', payload.sw372s11)
        formData.append('sw372s12', payload.sw372s12)

        if ((typeof payload.sw372s13 !== 'undefined' && payload.sw372s13 !== '') && ('id' in payload.sw372s13)) {
          formData.append('sw372s13', payload.sw372s13.id)
        }

        formData.append('sw372s14', payload.sw372s14)
        formData.append('sw372s15', payload.sw372s15)
        formData.append('sw372s16', payload.sw372s16)

        if (Array.isArray(payload.sw372s17)) {
          if (payload.sw372s17.length > 0) {
            payload.sw372s17.forEach(o => {
              formData.append('sw372s17[]', o.id)
            })
          }
        }

        formData.append('sw372s18', payload.sw372s18)
        formData.append('sw372s19', payload.sw372s19)
        formData.append('sw372s20', payload.sw372s20)
        formData.append('sw372s21', payload.sw372s21)
        formData.append('sw372s22', payload.sw372s22)
        formData.append('sw372s23', payload.sw372s23)
        formData.append('sw372s24', payload.sw372s24)
        formData.append('sw372s25', payload.sw372s25)
        formData.append('sw372s26', payload.sw372s26)
        formData.append('sw372s27', payload.sw372s27)
        formData.append('sw372s28', payload.sw372s28)
        formData.append('sw372s29', payload.sw372s29)
        formData.append('sw372s30', payload.sw372s30)
        formData.append('sw372s31', payload.sw372s31)
        formData.append('sw372s32', payload.sw372s32)
        formData.append('sw372s33', payload.sw372s33)
        formData.append('sw372s34', payload.sw372s34)
        formData.append('sw372s35', payload.sw372s35)
        formData.append('sw372s36', payload.sw372s36)
        formData.append('sw372s37', payload.sw372s37)
        formData.append('sw372s38', payload.sw372s38)
        formData.append('sw372s39', payload.sw372s39)
        formData.append('sw372s40', payload.sw372s40)
        formData.append('sw372s41', payload.sw372s41)

        if (Array.isArray(payload.sw026s03)) {
          if (payload.sw026s03.length > 0) {
            payload.sw026s03.forEach(o => {
              formData.append('sw026s03[]', o.id)
            })
          }
        }

        if (Array.isArray(payload.sw042s03)) {
          if (payload.sw042s03.length > 0) {
            payload.sw042s03.forEach(o => {
              formData.append('sw042s03[]', o.sw005s01)
            })
          }
        }

        if (Array.isArray(payload.sw230s03)) {
          if (payload.sw230s03.length > 0) {
            payload.sw230s03.forEach(o => {
              formData.append('sw230s03[]', o.id)
            })
          }
        }

        if (Array.isArray(payload.sw043s10)) {
          if (payload.sw043s10.length > 0) {
            payload.sw043s10.forEach(val => {
              formData.append('sw043s10[]', val)
            })
          }
        }

        if (Array.isArray(payload.sw043s03)) {
          if (payload.sw043s03.length > 0) {
            payload.sw043s03.forEach(val => {
              formData.append('sw043s03[]', val)
            })
          }
        }

        if (Array.isArray(payload.sw043s04)) {
          if (payload.sw043s04.length > 0) {
            payload.sw043s04.forEach(val => {
              formData.append('sw043s04[]', val)
            })
          }
        }

        if (Array.isArray(payload.sw043s07)) {
          if (payload.sw043s07.length > 0) {
            payload.sw043s07.forEach(val => {
              formData.append('sw043s07[]', val)
            })
          }
        }

        if (Array.isArray(payload.sw043s08)) {
          if (payload.sw043s08.length > 0) {
            payload.sw043s08.forEach(val => {
              formData.append('sw043s08[]', val)
            })
          }
        }

        api.post(`${apiConfig.url_base_api}leads/saveNewQualificacao`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a qualificação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a qualificação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a qualificação')))
            }
          })
      })
    },

    async deleteSW372({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw372s01', payload.sw372s01)

        api.post(`${apiConfig.url_base_api}leads/deleteQualificacao`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('removeSW372', payload.index)
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar a qualificação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar a qualificação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar a qualificação')))
            }
          })
      })
    },

    async editSW372(state, payload) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/modalQualificacaoComprador/${payload.sw372s01}/0`)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                resolve(response.data.data)
              } else {
                reject(new Error(i18n.t('Problema ao editar a qualificação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao editar a qualificação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao editar a qualificação')))
            }
          })
      })
    },

  },
}
