import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
      currentPage: state.currentPage.value,
      searchTxt: state.searchTxt.value,
      totalFieldsFilter: state.totalFieldsFilter.value,
      orderBy: state.orderBy.value,
      showListviewFilter: state.showListviewFilter.value,
      showListview: () => {
        if (state.totalRows.value > 0) {
          return true
        }

        return false
      },
      showPager: () => {
        if (state.totalRows.value > state.perPage.value) {
          return true
        }

        return false
      },
    }),
    txt: state => ({
      searchPlaceholder: state.txtSearchPlaceholder,
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    fieldsOrder: state => state.fieldsOrder,
    showFormCreateNew: state => state.showFormCreateNew.value,
    urlRequestListview: state => {
      if (state.typeList.value === 'angariados') {
        return 'leads/loadListImoveisAngariados'
      }
      if (state.typeList.value === 'pre-angariados') {
        return 'leads/loadListImoveisPreAngariados'
      }
      if (state.typeList.value === 'interesse') {
        return 'leads/loadListImoveisInteresse'
      }

      return ''
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.orderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    addFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value = 0
    },
    removeSW031(state, payload) {
      if (payload in state.listviewData.value) {
        state.listviewData.value.splice(payload, 1)
        state.totalRows.value -= 1

        if (state.totalRows.value === 0) {
          state.showListviewFilter.value = false
        }
      }
    },
    setShowListviewFilter(state, payload) {
      state.showListviewFilter.value = payload
    },
    setTypeList(state, payload) {
      state.typeList.value = payload
    },
  },
  actions: {

    async loadListviewData({
      state, commit, dispatch, rootGetters, getters,
    }, payload) {
      if (rootGetters['crm_leads_form/loaded'] === true) {
        await dispatch('request/cancelTokensPendingByUrl', getters.urlRequestListview, { root: true })
      }

      const request = await new Promise((resolve, reject) => {
        commit('resetFilterSelectedTotalFields')

        const formData = new FormData()
        formData.append('sw031s03', rootGetters['crm_leads_form/keyReg'])
        formData.append('sw031s08', rootGetters['crm_leads_form/dynamicIDForm'])
        formData.append('modeView', rootGetters['crm_leads_form/modeFormAction'])
        formData.append('start', ((state.currentPage.value - 1) * state.perPage.value))
        formData.append('length', state.perPage.value)

        if (state.searchTxt.value !== '') {
          formData.append('fSearch', state.searchTxt.value)
          commit('addFilterSelectedTotalFields')
        }

        if (state.orderBy.value.column !== undefined) {
          formData.append('orderByColumn', state.orderBy.value.column)
        }

        if (state.orderBy.value.sort !== undefined) {
          formData.append('orderBySort', state.orderBy.value.sort)
        }

        if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
          commit('setListviewInit', 1)
        } else {
          commit('setListviewData', [])
          commit('setTotalRows', 0)
          commit('setLoadingListview', true)
          commit('setListviewInit', 1)
        }

        api.post(`${apiConfig.url_base_api}${getters.urlRequestListview}`, formData)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setListviewData', response.data.data)
                commit('setTotalRows', response.data.recordsTotal)
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                if (!!payload && (payload.init !== undefined) && (payload.init === true) && (response.data.recordsTotal > 0)) {
                  commit('setShowListviewFilter', true)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os imóveis de interesse')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os imóveis de interesse')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os imóveis de interesse')))
            }
          })
      })

      return request
    },

    listviewFilterOrderBy({ state, commit, dispatch }, payload) {
      if ((!!state.orderBy.value.column) && (state.orderBy.value.column === payload.field)) {
        if (state.orderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: payload.field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: payload.field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: payload.field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('loadListviewData', payload || {})
    },

    setCurrentPage({ commit, dispatch }, payload) {
      if ((payload !== null) && (payload !== undefined)) {
        if ('page' in payload) {
          commit('setCurrentPage', payload.page)
        }
      }

      return dispatch('loadListviewData', payload || {})
    },

    setSearchTxt({ commit, dispatch }, payload) {
      if ((payload !== null) && (payload !== undefined)) {
        if ('txt' in payload) {
          commit('setSearchTxt', payload.txt)
        }
      }

      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true, ...payload || {} })
    },

    setTypeList({ commit }, payload) {
      commit('setTypeList', payload)
    },

    async saveSW031({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw012s02', payload)

        api.post(`${apiConfig.url_base_api}leads/findListingSearchByPID/1`, formData)
          .then(async response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else if (response.data.list[0].id) {
                  const formDataSW031 = new FormData()
                  formDataSW031.append('sw031s03', rootGetters['crm_leads_form/keyReg'])
                  formDataSW031.append('sw031s08', rootGetters['crm_leads_form/dynamicIDForm'])
                  formDataSW031.append('sw031s04', response.data.list[0].id)

                  await api.post(`${apiConfig.url_base_api}leads/addImovelInteresse`, formDataSW031)
                    .then(responseSave => {
                      try {
                        if (typeof responseSave.data === 'object') {
                          if (responseSave.data.error === 1) {
                            reject(new Error(responseSave.data.msg))
                          } else {
                            resolve({ message: responseSave.data.msg })
                          }
                        } else {
                          reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
                        }
                      } catch (errorSaveResponse) {
                        if ((errorSaveResponse.response !== undefined) && (errorSaveResponse.response.status !== undefined)) {
                          reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
                        }
                      }
                    })
                    .catch(errorSave => {
                      if ((errorSave.response !== undefined) && (errorSave.response.status !== undefined)) {
                        reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
                      }
                    })
                } else {
                  reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao adicionar o imóvel de interesse')))
            }
          })
      })
    },

    async deleteSW031({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('regDel', payload.sw031s01)

        api.post(`${apiConfig.url_base_api}leads/deleteImovelInteresse`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('removeSW031', payload.index)
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar o imóvel de interesse')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar o imóvel de interesse')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar o imóvel de interesse')))
            }
          })
      })
    },

  },
}
