<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="formModeView===false"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="createNewSW029"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content email-user-list"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((data.showListviewFilter === true) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Família') }}
              </h4>
            </div>
            <div
              v-if="formModeView===false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click.stop.prevent="createNewSW029"
              >
                <span class="align-middle">{{ $t('Adicionar') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <div
          v-if="data.showListviewFilter === true"
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTxt"
                value=""
                :placeholder="txt.searchPlaceholder"
              />
            </b-input-group>
          </div>
          <div
            v-if="fieldsOrder.length > 0"
            class="dropdown"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  :size="sizeIconsAction"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-for="oField in fieldsOrder"
                :key="`field-order-sw029-${oField.name}`"
                @click="listviewFilterOrderBy(oField.name)"
              >
                <feather-icon
                  :icon="listviewIconOrderBy(oField.name)"
                  :class="listviewIconOrderBy(oField.name, true)"
                />
                {{ oField.txt }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <ul
          v-if="data.showListview() === true"
          class="email-media-list overflow-hidden"
        >
          <b-media
            v-for="(sw029, index) in listviewData"
            :key="sw029.id"
            tag="li"
            no-body
          >
            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5
                    v-if="sw029.nome"
                    class="mb-25"
                  >
                    {{ sw029.nome }}
                  </h5>
                  <span
                    v-if="sw029.email"
                    class="text-truncate"
                    :href="`mailto:${sw029.email}`"
                  >
                    <feather-icon
                      icon="MailIcon"
                    />
                    {{ sw029.email }}
                  </span>
                  <span
                    v-if="sw029.telefone"
                    :href="`tel:${sw029.telefone}`"
                    :class="[{'text-truncate d-none d-sm-inline': true, 'px-2': ((sw029.email) ? true : false)}]"
                  >
                    <feather-icon
                      icon="PhoneCallIcon"
                    />
                    {{ sw029.telefone }}
                  </span>
                  <span
                    v-if="sw029.telefone"
                    :href="`tel:${sw029.telefone}`"
                    :class="[{'text-truncate d-inline d-sm-none': true}]"
                  >
                    <feather-icon
                      icon="PhoneCallIcon"
                    />
                    {{ sw029.telefone }}
                  </span>
                </div>
              </div>

              <div
                v-if="sw029.tipo"
                class="mail-message"
              >
                <p class="text-truncate mb-0">
                  <b>{{ $t('Tipo') }}:&nbsp;</b>
                  {{ sw029.tipo }}
                </p>
              </div>

              <div
                v-if="sw029.grau"
                class="mail-message"
              >
                <p class="text-truncate mb-0">
                  <b>{{ $t('Grau parentesco') }}:&nbsp;</b>
                  {{ sw029.grau }}
                </p>
              </div>

              <div
                v-if="(sw029.can_edit===true) || (sw029.can_delete===true)"
                class="mail-message mt-2 text-center text-sm-left"
              >
                <b-button-group>
                  <b-button
                    v-if="sw029.can_edit===true"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    @click.stop.prevent="editFormSW029(sw029, index)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Editar') }}</span>
                  </b-button>
                  <b-button
                    v-if="sw029.can_delete===true"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    @click.stop.prevent="deleteFormSW029(sw029, index)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Eliminar') }}</span>
                  </b-button>
                </b-button-group>
              </div>

            </b-media-body>
          </b-media>
        </ul>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(formModeView===true) && (data.totalFieldsFilter===0) && (data.init === 0)"
            class="mb-0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

    </div>

    <form-create-new-sw029
      v-if="showFormCreateNew===true"
      ref="modalFormSW029"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormInput, BContainer, BButton, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BMedia, BMediaBody, BOverlay, VBTooltip, BButtonGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { showModalDeleteMessage } from '@core/utils/utils'
import { onUnmounted } from '@vue/composition-api'
import modelSW029 from '@store-modules/crm/leads/sw029'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BContainer,
    BButton,
    BPagination,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BMediaBody,
    BOverlay,
    BAlert,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ '../footerStepNavTop.vue'),
    BButtonGroup,
    FormCreateNewSw029: () => import(/* webpackChunkName: "crm-leads-new-sw029-form" */ /* webpackPrefetch: true */ './form.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  computed:
  {
    ...mapGetters('crm_leads_form', ['formModeView']),
    ...mapGetters('sw029', ['listviewData', 'data', 'txt', 'fieldsOrder', 'showFormCreateNew']),
    searchTxt: {
      get() { return this.$store.getters['sw029/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('sw029/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['sw029/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('sw029/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  methods: {
    async listviewFilterOrderBy(field) {
      this.$store.dispatch('sw029/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        await this.$store.dispatch('sw029/loadListviewData', { init: true }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
    async createNewSW029() {
      this.$store.dispatch('sw029/createNewSW029')
    },
    async deleteFormSW029(row, pIndex) {
      const self = this

      try {
        if (row.id) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar o familiar?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)
              this.$store.dispatch('sw029/deleteSW029', {
                sw029s01: row.id,
                index: pIndex,
              }).then(res => {
                self.$root.$emit('app::loading', false)
                self.showMsgSuccessRequest(res)
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    async editFormSW029(row, pIndex) {
      const self = this

      try {
        if (row.id) {
          self.$root.$emit('app::loading', true)
          await this.$store.dispatch('sw029/editSW029', {
            sw029s01: row.id,
            index: pIndex,
          }).then(res => {
            self.$store.dispatch('sw029/setShowFormCreateNew', true)
            self.$nextTick(() => {
              self.$refs.modalFormSW029.loadDataForm(res)
            })
            self.$root.$emit('app::loading', false)
          }).catch(error => {
            self.$root.$emit('app::loading', false)
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
      //
      }
    },
  },
  setup() {
    const MODEL_SW029 = 'sw029'

    if (!store.hasModule(MODEL_SW029)) {
      store.registerModule(MODEL_SW029, modelSW029)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW029)) store.unregisterModule(MODEL_SW029)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
