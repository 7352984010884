import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(27),
  totalRows: ref(0),
  currentPage: ref(1),
  searchTxt: ref(''),
  totalFieldsFilter: ref(0),
  showListviewFilter: ref(false),
  orderBy: ref({
    column: 'dateCreate',
    sort: 'desc',
  }),
  txtSearchPlaceholder: i18n.t('ZMID'),
  txtLoading: i18n.t('A carregar registos ...'),
  txtDefault: i18n.t('Não tem nenhum imóvel de interesse registado'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  fieldsOrder: [
    {
      name: 'dateCreate',
      txt: i18n.t('Ordenar por data de registo'),
    },
    {
      name: 'status',
      txt: i18n.t('Ordenar por estado'),
    },
    {
      name: 'id',
      txt: i18n.t('Ordenar por ZMID'),
    },
    {
      name: 'nickname',
      txt: i18n.t('Ordenar por nickname do imóvel'),
    },
    {
      name: 'price',
      txt: i18n.t('Ordenar por preço'),
    },
  ],
  showFormCreateNew: ref(false),
  typeList: ref(''),
})

export default defaultState
