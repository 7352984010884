import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
      currentPage: state.currentPage.value,
      searchTxt: state.searchTxt.value,
      totalFieldsFilter: state.totalFieldsFilter.value,
      orderBy: state.orderBy.value,
      showListviewFilter: state.showListviewFilter.value,
      showListview: () => {
        if (state.totalRows.value > 0) {
          return true
        }

        return false
      },
      showPager: () => {
        if (state.totalRows.value > state.perPage.value) {
          return true
        }

        return false
      },
    }),
    txt: state => ({
      searchPlaceholder: state.txtSearchPlaceholder,
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    fieldsOrder: state => state.fieldsOrder,
    showFormCreateNew: state => state.showFormCreateNew.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.orderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    addFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value = 0
    },
    setCreateNewSW029(state, payload) {
      state.showFormCreateNew.value = payload
    },
    removeSW029(state, payload) {
      if (payload in state.listviewData.value) {
        state.listviewData.value.splice(payload, 1)
        state.totalRows.value -= 1

        if (state.totalRows.value === 0) {
          state.showListviewFilter.value = false
        }
      }
    },
    setShowListviewFilter(state, payload) {
      state.showListviewFilter.value = payload
    },
  },
  actions: {

    async loadListviewData({
      state, commit, dispatch, rootGetters,
    }, payload) {
      if (rootGetters['crm_leads_form/loaded'] === true) {
        await dispatch('request/cancelTokensPendingByUrl', 'leads/loadListFamilia', { root: true })
      }

      const request = await new Promise((resolve, reject) => {
        commit('resetFilterSelectedTotalFields')

        const formData = new FormData()
        formData.append('sw029s02', rootGetters['crm_leads_form/keyReg'])
        formData.append('sw029s22', rootGetters['crm_leads_form/dynamicIDForm'])
        formData.append('modeView', rootGetters['crm_leads_form/modeFormAction'])
        formData.append('start', ((state.currentPage.value - 1) * state.perPage.value))
        formData.append('length', state.perPage.value)

        if (state.searchTxt.value !== '') {
          formData.append('fSearch', state.searchTxt.value)
          commit('addFilterSelectedTotalFields')
        }

        if (state.orderBy.value.column !== undefined) {
          formData.append('orderByColumn', state.orderBy.value.column)
        }

        if (state.orderBy.value.sort !== undefined) {
          formData.append('orderBySort', state.orderBy.value.sort)
        }

        if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
          commit('setListviewInit', 1)
        } else {
          commit('setListviewData', [])
          commit('setTotalRows', 0)
          commit('setLoadingListview', true)
          commit('setListviewInit', 1)
        }

        api.post(`${apiConfig.url_base_api}leads/loadListFamilia`, formData)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setListviewData', response.data.data)
                commit('setTotalRows', response.data.recordsTotal)
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                if (!!payload && (payload.init !== undefined) && (payload.init === true) && (response.data.recordsTotal > 0)) {
                  commit('setShowListviewFilter', true)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os contactos familiares')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os contactos familiares')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os contactos familiares')))
            }
          })
      })

      return request
    },

    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.orderBy.value.column) && (state.orderBy.value.column === field)) {
        if (state.orderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('loadListviewData')
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('loadListviewData')
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true })
    },

    setShowFormCreateNew({ commit }, value) {
      commit('setCreateNewSW029', value)
    },

    createNewSW029({ commit }) {
      commit('setCreateNewSW029', true)
    },

    async saveSW029({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        let urlSave = ''
        const formData = new FormData()
        formData.append('sw029s02', rootGetters['crm_leads_form/keyReg'])
        formData.append('sw029s22', rootGetters['crm_leads_form/dynamicIDForm'])

        if (payload.type.id === '1') {
          formData.append('sw029s03', payload.lead)
          formData.append('sw029s04', payload.grau)

          urlSave = 'leads/addLeadFamiliar'
        } else if (payload.type.id === '2') {
          formData.append('sw029s04', payload.grau)
          formData.append('sw029s05', payload.nome)
          formData.append('sw029s06', payload.apelido)
          formData.append('sw029s07', payload.genero)
          formData.append('sw029s08', payload.telefone)
          formData.append('sw029s09', payload.data_aniversario)
          formData.append('sw029s10', payload.email)
          formData.append('sw029s18', payload.nif)
          formData.append('sw029s19', payload.bi_cc)
          formData.append('sw029s20', payload.data_validade_bi_cc)
          formData.append('sw029s23', payload.nie)

          if (payload.id) {
            formData.append('sw029s01', btoa(payload.id))
          } else {
            formData.append('sw029s01', '')
          }

          urlSave = 'leads/addFamiliar'
        } else if (payload.type.id === '3') {
          formData.append('sw029s03', payload.lead)
          formData.append('sw029s04', payload.grau)

          urlSave = 'leads/addContactoFamiliar'
        }

        api.post(`${apiConfig.url_base_api}${urlSave}`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar o familiar')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar o familiar')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar o familiar')))
            }
          })
      })
    },

    async deleteSW029({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('regDel', payload.sw029s01)

        api.post(`${apiConfig.url_base_api}leads/deleteFamiliar`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('removeSW029', payload.index)
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar o familiar')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar o familiar')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar o familiar')))
            }
          })
      })
    },

    async editSW029(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw029s01', payload.sw029s01)

        api.post(`${apiConfig.url_base_api}leads/getFamiliar`, formData)
          .then(response => {
            try {
              if (typeof response.data.familiar === 'object') {
                resolve(response.data.familiar)
              } else {
                reject(new Error(i18n.t('Problema ao editar o familiar')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao editar o familiar')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao editar o familiar')))
            }
          })
      })
    },

    async checkEmailPhoneFamiliar({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/checkEmailPhoneFamiliar', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s05', payload.telefone || '')
        formData.append('sw025s06', payload.email || '')

        api.post(`${apiConfig.url_base_api}leads/checkEmailPhoneFamiliar`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação do nº de contacto / email dos familiares')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a verificação do nº de contacto / email dos familiares')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a verificação do nº de contacto / email dos familiares')))
            }
          })
      })

      return request
    },

  },
}
