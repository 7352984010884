<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content email-user-list"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((data.showListviewFilter === true) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Registo de atividade') }}
              </h4>
            </div>

          </div>
        </div>

        <div
          v-if="data.showListviewFilter === true"
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTxt"
                value=""
                :placeholder="txt.searchPlaceholder"
              />
            </b-input-group>
          </div>
          <div
            v-if="fieldsOrder.length > 0"
            class="dropdown"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  :size="sizeIconsAction"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-for="oField in fieldsOrder"
                :key="`field-order-sw072-${oField.name}`"
                @click="listviewFilterOrderBy(oField.name)"
              >
                <feather-icon
                  :icon="listviewIconOrderBy(oField.name)"
                  :class="listviewIconOrderBy(oField.name, true)"
                />
                {{ oField.txt }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div
          v-if="data.showListview() === true"
          class="p-2"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(sw072, index) in listviewData"
              :key="`sw072-item-${index}`"
              :title="sw072.data"
              :subtitle="sw072.atividade"
              variant="secondary"
            />
          </app-timeline>
        </div>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(data.totalFieldsFilter===0) && (data.init === 0)"
            class="mb-0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormInput, BContainer, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BOverlay, VBTooltip, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import modelSW072 from '@store-modules/crm/leads/sw072'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BContainer,
    BPagination,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BAlert,
    AppTimeline,
    AppTimelineItem,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  computed:
  {
    ...mapGetters('sw072', ['listviewData', 'data', 'txt', 'fieldsOrder']),
    searchTxt: {
      get() { return this.$store.getters['sw072/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('sw072/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['sw072/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('sw072/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  methods: {
    async listviewFilterOrderBy(field) {
      this.$store.dispatch('sw072/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        await this.$store.dispatch('sw072/loadListviewData', { init: true }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
  },
  setup() {
    const MODEL_SW072 = 'sw072'

    if (!store.hasModule(MODEL_SW072)) {
      store.registerModule(MODEL_SW072, modelSW072)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW072)) store.unregisterModule(MODEL_SW072)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
