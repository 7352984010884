<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content email-user-list"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((data.showListviewFilter === true) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Notas') }}
              </h4>
            </div>
            <div
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click.stop.prevent="createNewNote"
              >
                <span class="align-middle">{{ $t('Adicionar') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <div
          v-if="data.showListviewFilter === true"
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTxt"
                value=""
                :placeholder="txt.searchPlaceholder"
              />
            </b-input-group>
          </div>
          <div
            v-if="fieldsOrder.length > 0"
            class="dropdown"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  :size="sizeIconsAction"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-for="oField in fieldsOrder"
                :key="`field-order-sw072-${oField.name}`"
                @click="listviewFilterOrderBy(oField.name)"
              >
                <feather-icon
                  :icon="listviewIconOrderBy(oField.name)"
                  :class="listviewIconOrderBy(oField.name, true)"
                />
                {{ oField.txt }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div
          v-if="data.showListview() === true"
          class="p-2"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(row, index) in listviewData"
              :key="`note-item-${index}`"
              variant="secondary"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ row.sw044s05 }}</h6>
              </div>
              <p>{{ row.sw044s03 }}</p>

              <div
                v-if="((row.nomeResponsavel !== '') && (Number(row.mostraNomeResp) === 1))"
                class="d-flex flex-sm-row flex-column justify-content-between align-items-start mb-1"
              >
                <b-media>
                  <template #aside>
                    <b-avatar :src="row.photoResponsavel" />
                  </template>
                  <h6>{{ row.nomeResponsavel }}</h6>
                  <span class="text-muted">{{ $t('Responsável') }}</span>
                </b-media>
              </div>

              <b-button
                v-if="Number(row.allowEdit) === 1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click.stop.prevent="editNote(row)"
              >
                {{ $t('Editar ') }}
              </b-button>

              <b-button
                v-if="Number(row.allowDelete) === 1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-danger"
                class="ml-1"
                @click.stop.prevent="deleteNote(row)"
              >
                {{ $t('Eliminar ') }}
              </b-button>
            </app-timeline-item>
          </app-timeline>
        </div>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(data.totalFieldsFilter===0) && (data.init === 0)"
            class="mb-0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

      <form-create-new-sw044
        v-if="showFormCreateNew===true"
        ref="modalFormSW044"
        :data="loadDataSW044"
      />

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BContainer, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BOverlay, VBTooltip, BAlert, BFormInput, BButton, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { showModalDeleteMessage } from '@core/utils/utils'
import { onUnmounted } from '@vue/composition-api'
import modelSW044 from '@store-modules/crm/leads/sw044'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import checkNested from 'check-nested'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BPagination,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BAlert,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ '../footerStepNavTop.vue'),
    FormCreateNewSw044: () => import(/* webpackChunkName: "crm-leads-new-sw044-form" */ /* webpackPrefetch: true */ './form.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      showFormCreateNew: false,
      loadDataSW044: {},
    }
  },
  computed:
  {
    ...mapGetters('sw044', ['listviewData', 'data', 'txt', 'fieldsOrder', 'fieldsFilter']),
    searchTxt: {
      get() { return this.$store.getters['sw044/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('sw044/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['sw044/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('sw044/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  methods: {
    async listviewFilterOrderBy(field) {
      this.$store.dispatch('sw044/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        await this.$store.dispatch('sw044/loadListviewData', { init: true }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
    createNewNote() {
      this.showFormCreateNew = true
    },
    closeFormSW044() {
      this.loadDataSW044 = {}
      this.showFormCreateNew = false
    },
    async editNote(row) {
      if (checkNested(row, 'sw044s01') && (row.sw044s01 !== '')) {
        try {
          this.$root.$emit('app::loading', true)
          await this.$store.dispatch('sw044/editSW044', {
            sw044s01: row.sw044s01,
          }).then(res => {
            this.loadDataSW044 = res
            this.showFormCreateNew = true
            this.$root.$emit('app::loading', false)
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
        //
        }
      }
    },
    async deleteNote(row) {
      if (checkNested(row, 'sw044s01') && (row.sw044s01 !== '')) {
        try {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar a nota?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              this.$root.$emit('app::loading', true)
              this.$store.dispatch('sw044/deleteSW044', {
                sw044s01: row.sw044s01,
              }).then(async res => {
                this.$root.$emit('app::loading', false)
                this.showMsgSuccessRequest(res)
                this.init()
              }).catch(error => {
                this.$root.$emit('app::loading', false)
                this.showMsgErrorRequest(error)
              })
            }
          })
        } catch (err) {
          //
        }
      }
    },
  },
  setup() {
    const MODEL_SW044 = 'sw044'

    if (!store.hasModule(MODEL_SW044)) {
      store.registerModule(MODEL_SW044, modelSW044)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW044)) store.unregisterModule(MODEL_SW044)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
