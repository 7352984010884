<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content email-user-list"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : false }"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Seguimentos e Campanhas') }}
              </h4>
            </div>
            <div
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click.stop.prevent="createNewSeguimento"
              >
                <span class="align-middle">{{ $t('Adicionar') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
            </b-input-group>
          </div>
          <div class="d-flex align-content-left justify-content-between w-100">
            <v-select
              v-model="filterByStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="fieldsFilter.status"
              append-to-body
              :calculate-position="withPopper"
              :placeholder="$t('Filtrar por estado')"
              :clearable="false"
              label="txt"
              item-text="txt"
              item-value="tav"
              class="w-100 border-0"
            >
              <template #option="{ txt }">
                {{ txt }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </div>

          <div
            v-if="tiposSeguimentos.length > 0"
            class="d-flex"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
            </b-input-group>
          </div>
          <div
            v-if="tiposSeguimentos.length > 0"
            class="d-flex align-content-left justify-content-between w-100"
          >
            <v-select
              v-model="filterByType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tiposSeguimentos"
              append-to-body
              :calculate-position="withPopper"
              :placeholder="$t('Filtrar por tipo')"
              :clearable="false"
              label="sw090s02"
              item-text="sw090s02"
              item-value="sw090s01"
              class="w-100 border-0"
            >
              <template #option="{ sw090s02 }">
                {{ sw090s02 }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </div>
        </div>

        <div
          v-if="data.showListview() === true"
          class="p-2"
        >
          <list-data
            :data="listviewData"
            :view-seguimento="viewSeguimento"
            :with-name-lead="showNameLead"
          />
        </div>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(data.totalFieldsFilter===0) && (data.init === 0)"
            class="mb-0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

      <form-create-new-seguimento
        v-if="showFormCreateNew===true"
        ref="modalFormSeguimento"
        :key-reg="keyRegAssoc"
        :dynamic-reg="dynamicRegAssoc"
        :type-lead="typeLeadAssoc"
        :refresh-listview="init"
      />

      <view-seguimento
        v-if="showInfo===true"
        ref="modalInfoSeguimento"
        :row-data="infoSeguimento"
        :with-name-lead="showNameLead"
        :create-new="createNewSeguimento"
        :refresh-listview="init"
      />

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BContainer, BPagination, BInputGroupPrepend, BInputGroup, BOverlay, VBTooltip, BAlert, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import vSelect from 'vue-select'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import ListData from './listData.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BPagination,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BAlert,
    BButton,
    vSelect,
    ListData,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ '../footerStepNavTop.vue'),
    FormCreateNewSeguimento: () => import(/* webpackChunkName: "crm-leads-new-seguimento-form" */ /* webpackPrefetch: true */ './form.vue'),
    ViewSeguimento: () => import(/* webpackChunkName: "crm-leads-new-seguimento-view" */ /* webpackPrefetch: true */ './view.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      showFormCreateNew: false,
      showInfo: false,
      infoSeguimento: {},
      showNameLead: false,
      keyRegAssoc: null,
      dynamicRegAssoc: null,
      typeLeadAssoc: null,
    }
  },
  computed:
  {
    ...mapGetters('seguimentos', ['listviewData', 'data', 'txt', 'fieldsOrder', 'fieldsFilter', 'tiposSeguimentos']),
    ...mapGetters('crm_leads_form', ['vars', 'keyReg', 'dynamicIDForm']),
    currentPage: {
      get() { return this.$store.getters['seguimentos/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('seguimentos/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    filterByStatus: {
      get() { return this.$store.getters['seguimentos/data'].filterByStatus },
      set(newValue) {
        this.$store.dispatch('seguimentos/setFilterByStatus', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    filterByType: {
      get() { return this.$store.getters['seguimentos/data'].filterByType },
      set(newValue) {
        this.$store.dispatch('seguimentos/setFilterByType', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  mounted() {
    try {
      this.filterByStatus = [...this.fieldsFilter.status].shift()
      this.filterByType = [...this.tiposSeguimentos].shift()
    } catch (err) {
      //
    }
  },
  methods: {
    async init() {
      try {
        await this.$store.dispatch('seguimentos/loadListviewData', { init: true }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
    createNewSeguimento() {
      this.keyRegAssoc = this.keyReg || ''
      this.dynamicRegAssoc = this.dynamicIDForm || ''
      this.typeLeadAssoc = Number(this.vars.typeUserCreateLead) || 0
      this.showFormCreateNew = true
    },
    closeFormSeguimento() {
      this.showFormCreateNew = false
      this.keyRegAssoc = null
      this.dynamicRegAssoc = null
      this.typeLeadAssoc = null
    },
    viewSeguimento(row) {
      this.showInfo = true
      this.infoSeguimento = row
    },
    closeView() {
      this.showInfo = false
      this.infoSeguimento = {}
    },
  },
  setup() {
    const MODEL_SEGUIMENTOS = 'seguimentos'

    if (!store.hasModule(MODEL_SEGUIMENTOS)) {
      store.registerModule(MODEL_SEGUIMENTOS, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SEGUIMENTOS)) store.unregisterModule(MODEL_SEGUIMENTOS)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
