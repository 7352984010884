import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import btoa from 'btoa'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async previewModal({ rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s01', payload.sw025s01 || '')
        formData.append('options', payload.options || '')
        formData.append('optionsNovoSeguimento', payload.optionsNovoSeguimento || '')
        formData.append('forceAuth', payload.forceAuth || '')

        const currentUser = rootGetters['auth/currentUser']

        api.post(`${apiConfig.url_base_api}leads/previewModalList`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                resolve(response.data.data)
              } else {
                if ((currentUser !== null) && (currentUser !== undefined)) {
                  if ('extras' in currentUser && 'idLeader' in currentUser.extras && currentUser.extras.idLeader !== '') {
                    reject(new Error(i18n.t('Neste momento não tem permissões para visualizar este Lead/Contacto. Fale com o seu Agente Líder!')))
                  }
                }

                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação')))
            }
          })
      })
    },

    async checkRemoveSW025(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        let totalRegs = 0

        if (Array.isArray(payload.sw025s01) && (payload.sw025s01.length > 0)) {
          totalRegs = payload.sw025s01.length
          payload.sw025s01.forEach(row => {
            formData.append('regDel[]', row || '')
          })
        } else {
          totalRegs = 1
          formData.append('regDel', payload.sw025s01 || '')
        }

        api.post(`${apiConfig.url_base_api}leads/checkLeadToDelete`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.tc('Problema ao eliminar o {type}', totalRegs, { type: payload.type })))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.tc('Problema ao eliminar o {type}', totalRegs, { type: payload.type })))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.tc('Problema ao eliminar o {type}', totalRegs, { type: payload.type })))
            }
          })
      })
    },

    async removeSW025(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        let totalRegs = 0

        if (Array.isArray(payload.sw025s01) && (payload.sw025s01.length > 0)) {
          totalRegs = payload.sw025s01.length
          payload.sw025s01.forEach(row => {
            formData.append('regDel[]', row || '')
          })
        } else {
          totalRegs = 1
          formData.append('regDel', payload.sw025s01 || '')
        }

        api.post(`${apiConfig.url_base_api}leads/delete`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.tc('Problema ao eliminar o {type}', totalRegs, { type: payload.type })))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.tc('Problema ao eliminar o {type}', totalRegs, { type: payload.type })))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.tc('Problema ao eliminar o {type}', totalRegs, { type: payload.type })))
            }
          })
      })
    },

    async restoreSW025(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s01', payload.sw025s01 || '')

        api.post(`${apiConfig.url_base_api}leads/restoreSW025`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao restaurar o {type}', { type: payload.type })))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao restaurar o {type}', { type: payload.type })))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao restaurar o {type}', { type: payload.type })))
            }
          })
      })
    },

    async chooseConsultorSendLead({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/chooseConsultorSendLead', { root: true })

      const keyReg = payload.keyReg || ''
      const txtFind = payload.txt || ''

      const request = new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/chooseConsultorSendLead${(keyReg !== '') ? `/${keyReg}` : ''}?q=${txtFind}`)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                if ('UsersMkt' in response.data.data) {
                  resolve(response.data.data.UsersMkt)
                } else {
                  reject(new Error(i18n.t('Não tem consultores para partilhar')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao carregar a lista de consultores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar a lista de consultores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os separadores dos consultor')))
            }
          })
      })

      return request
    },

    async saveChooseConsultorSendLead({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/chooseConsultorSendLead', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('lead', payload.keyReg || '')
        formData.append('consultor', payload.consultor)

        api.post(`${apiConfig.url_base_api}leads/chooseConsultorSendLead`, formData)
          .then(response => {
            try {
              if (checkNested(response, 'data')) {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao partilhar a Lead com o outro consultor')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao partilhar a Lead com o outro consultor')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao partilhar a Lead com o outro consultor')))
            }
          })
      })

      return request
    },

    async chooseConsultorSendRef({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/chooseConsultorSendRef', { root: true })

      const keyReg = payload.keyReg || ''
      const txtFind = payload.txt || ''

      const request = new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/chooseConsultorSendRef${(keyReg !== '') ? `/${keyReg}` : ''}?q=${txtFind}`)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                if ('UsersMkt' in response.data.data) {
                  let sourceHubs = []

                  if ('aMoradas' in response.data.data) {
                    sourceHubs = response.data.data.aMoradas
                  }

                  resolve({
                    agents: response.data.data.UsersMkt,
                    hubs: sourceHubs,
                  })
                } else {
                  reject(new Error(i18n.t('Não tem consultores para enviar a Lead como referência')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter os consultores para enviar a Lead como referência')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter os consultores para enviar a Lead como referência')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter os consultores para enviar a Lead como referência')))
            }
          })
      })

      return request
    },

    async saveChooseConsultorSendRef({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/chooseConsultorSendRef', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('lead', payload.keyReg || '')
        formData.append('consultor', payload.consultor || '')
        formData.append('valornegocio', payload.valornegocio || '')
        formData.append('intencaoRef', payload.intencaoRef || '')
        formData.append('HUBOrigem', payload.HUBOrigem || '')
        formData.append('observacoes', payload.observacoes || '')

        api.post(`${apiConfig.url_base_api}leads/chooseConsultorSendRef`, formData)
          .then(response => {
            try {
              if (checkNested(response, 'data')) {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao enviar a Lead como referência para outro consultor')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao enviar a Lead como referência para outro consultor')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao enviar a Lead como referência para outro consultor')))
            }
          })
      })

      return request
    },

    async getNumberRspByLead({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/getNumberRspByLead', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        let totalRegs = 0

        if (Array.isArray(payload.keyReg) && (payload.keyReg.length > 0)) {
          totalRegs = payload.keyReg.length
          payload.keyReg.forEach(row => {
            formData.append('leads[]', row || '')
          })
        } else {
          totalRegs = 1
          formData.append('sw025s01', payload.keyReg || '')
        }

        api.post(`${apiConfig.url_base_api}leads/getNumberRspByLead`, formData)
          .then(response => {
            try {
              if (checkNested(response, 'data')) {
                resolve(response.data)
              } else {
                reject(new Error(i18n.tc('Problema ao obter os responsáveis da Lead', totalRegs)))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.tc('Problema ao obter os responsáveis da Lead', totalRegs)))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.tc('Problema ao obter os responsáveis da Lead', totalRegs)))
            }
          })
      })

      return request
    },

    async getListUsersTeam({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/getListUsersTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('tab', payload.tab || '')
        formData.append('createHtml', 1)

        if (Array.isArray(payload.idLeadForm) && (payload.idLeadForm.length > 0)) {
          payload.idLeadForm.forEach(row => {
            formData.append('idLeadForm[]', row || '')
          })
        } else {
          formData.append('idLeadForm', payload.idLeadForm || '')
        }

        api.post(`${apiConfig.url_base_api}leads/getListUsersTeam`, formData)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                resolve(response.data.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter os consultores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter os consultores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter os consultores')))
            }
          })
      })

      return request
    },

    async getSeparadoresByUserTeam({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/getSeparadoresByUserTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('user', payload.user)
        formData.append('lead', payload.lead || '')

        api.post(`${apiConfig.url_base_api}leads/getSeparadoresByUserTeam`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                const data = {
                  tabs: [],
                  bookmarks: [],
                }

                if ('tabs' in response.data) {
                  try {
                    if (Array.isArray(response.data.tabs)) {
                      data.tabs = response.data.tabs
                    } else if (Object.keys(response.data.tabs).length > 0) {
                      Object.keys(response.data.tabs).forEach(index => {
                        data.tabs.push({
                          tab: index,
                          name: response.data.tabs[index] || '',
                        })
                      })
                    }
                  } catch (err) {
                  //
                  }
                }

                if ('bookmarks' in response.data) {
                  data.bookmarks = response.data.bookmarks
                }

                resolve(data)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os separadores do consultor')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os separadores do consultor')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os separadores dos consultor')))
            }
          })
      })

      return request
    },

    async saveChangeRspByLead({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/saveChangeRspByLead', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        let totalRegs = 0

        if (Array.isArray(payload.sw025s01) && (payload.sw025s01.length > 0)) {
          totalRegs = payload.sw025s01.length
          payload.sw025s01.forEach(row => {
            formData.append('sw025s01[]', row || '')
          })
        } else {
          totalRegs = 1
          formData.append('sw025s01', payload.sw025s01 || '')
        }

        formData.append('consultor', payload.consultor || '')
        formData.append('separador', payload.separador || '')
        formData.append('mode', payload.mode || '')
        formData.append('userChange', payload.userChange || '')
        formData.append('bookmarks', payload.bookmarks || '')

        let msgErrorHttp = ''
        if (payload.mode === 'change') {
          msgErrorHttp = i18n.tc('Problema ao trocar responsável do Lead', totalRegs)
        }

        if (payload.mode === 'new') {
          msgErrorHttp = i18n.tc('Problema ao adicionar responsável ao Lead', totalRegs)
        }

        api.post(`${apiConfig.url_base_api}leads/saveChangeRspByLead`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(msgErrorHttp))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(msgErrorHttp))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(msgErrorHttp))
            }
          })
      })

      return request
    },

    async deleteUserRspLeadByTeam({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/deleteUserRspLeadByTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        let totalRegs = 0

        if (Array.isArray(payload.leads) && (payload.leads.length > 0)) {
          totalRegs = payload.leads.length
          formData.append('leads', JSON.stringify(payload.leads))
        } else {
          totalRegs = 1
          formData.append('leads', payload.leads || '')
        }

        formData.append('regDel', payload.regDel || '')

        api.post(`${apiConfig.url_base_api}leads/deleteUserRspLeadByTeam`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.tc('Problema ao eliminar o responsável do Lead', totalRegs)))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.tc('Problema ao eliminar o responsável do Lead', totalRegs)))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.tc('Problema ao eliminar o responsável do Lead', totalRegs)))
            }
          })
      })

      return request
    },

    async changeTabUsersRspLeadByTeam({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/changeTabUsersRspLeadByTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        let totalRegs = 0

        if (Array.isArray(payload.sw025s01) && (payload.sw025s01.length > 0)) {
          totalRegs = payload.sw025s01.length
          payload.sw025s01.forEach(row => {
            formData.append('leads[]', row || '')
          })
        } else {
          totalRegs = 1
          formData.append('lead', payload.sw025s01 || '')
        }

        api.post(`${apiConfig.url_base_api}leads/changeTabUsersRspLeadByTeam`, formData)
          .then(response => {
            try {
              if (checkNested(response, 'data')) {
                resolve(response.data)
              } else {
                reject(new Error(i18n.tc('Problema ao obter os separadores e responsáveis do {type}', totalRegs, { type: payload.type || '' })))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.tc('Problema ao obter os separadores e responsáveis do {type}', totalRegs, { type: payload.type || '' })))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.tc('Problema ao obter os separadores e responsáveis do {type}', totalRegs, { type: payload.type || '' })))
            }
          })
      })

      return request
    },

    async saveChangeTabUsersRspLeadByTeam({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/saveChangeTabUsersRspLeadByTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()

        let totalSelected = 0
        if (Array.isArray(payload.sw025s01)) {
          if (payload.sw025s01.length > 0) {
            totalSelected = payload.sw025s01.length

            if (checkNested(payload, 'fields')) {
              formData.append('idsSelection', JSON.stringify(payload.sw025s01 || ''))

              if (payload.fields.length > 0) {
                payload.fields.forEach((o, index) => {
                  formData.append(`changeTab[${index}]`, o.tab)
                })
              }
            }

            if (checkNested(payload, 'usersShare')) {
              if (payload.usersShare.length > 0) {
                payload.usersShare.forEach(val => {
                  formData.append('chgTab_sw069s03[]', val)
                })
              }
            }
          }
        } else if (payload.sw025s01 !== '') {
          totalSelected = 1

          if (checkNested(payload, 'fields')) {
            formData.append('chgTab_sw069s04', payload.sw025s01)

            if (payload.fields.length > 0) {
              payload.fields.forEach((o, index) => {
                formData.append(`changeTab[${index}]`, o.tab)
              })
            }
          }
        }

        if (checkNested(payload, 'sw025s188')) {
          if (Array.isArray(payload.sw025s01)) {
            formData.append('idsSelection', JSON.stringify(payload.sw025s01))
          } else if (payload.sw025s01 !== '') {
            formData.append('sw025s01', payload.sw025s01)
          }

          formData.append('sw025s188', payload.sw025s188)
        }

        api.post(`${apiConfig.url_base_api}leads/saveChangeTabUsersRspLeadByTeam`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.tc('Problema ao transferir o {type} para outro separador', totalSelected, { type: payload.type })))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.tc('Problema ao transferir o {type} para outro separador', totalSelected, { type: payload.type })))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.tc('Problema ao transferir o {type} para outro separador', totalSelected, { type: payload.type })))
            }
          })
      })

      return request
    },

    async convertLeadToContacto({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/convertLeadToContacto', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('lead', payload.sw025s01 || '')

        api.post(`${apiConfig.url_base_api}leads/convertLeadToContacto`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao converter o Lead para contacto')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao converter o Lead para contacto')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao converter o Lead para contacto')))
            }
          })
      })

      return request
    },

    async convertContactoToLead({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/convertContactoToLead', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('lead', payload.sw025s01 || '')

        api.post(`${apiConfig.url_base_api}leads/convertContactoToLead`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao converter o contacto para Lead')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao converter o contacto para Lead')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao converter o contacto para Lead')))
            }
          })
      })

      return request
    },

    async chooseTabLeads({ dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/chooseTab', { root: true })

      const request = new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/chooseTab`)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                const data = {
                  tabs: [],
                  bookmarks: [],
                }

                if ('tabsTeamLead' in response.data.data) {
                  try {
                    if (Array.isArray(response.data.data.tabsTeamLead)) {
                      data.tabs = response.data.data.tabsTeamLead
                    } else if (Object.keys(response.data.data.tabsTeamLead).length > 0) {
                      Object.keys(response.data.data.tabsTeamLead).forEach(index => {
                        data.tabs.push({
                          tab: index,
                          name: response.data.data.tabsTeamLead[index] || '',
                        })
                      })
                    }
                  } catch (err) {
                  //
                  }
                }

                if ('bookmarks' in response.data.data) {
                  data.bookmarks = response.data.data.bookmarks
                }

                resolve(data)
              } else {
                reject(new Error(i18n.t('Problema ao obter os separadores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter os separadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter os separadores')))
            }
          })
      })

      return request
    },

    async saveTabLeads({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/saveTab', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('idLead', payload.sw025s01 || '')
        formData.append('tab', payload.tab || '')
        formData.append('bookmarks', payload.bookmarks || '')

        api.post(`${apiConfig.url_base_api}leads/saveTab`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao transferir o contacto para Lead')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao transferir o contacto para Lead')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao transferir o contacto para Lead')))
            }
          })
      })

      return request
    },

    async generateLinkConsentimento({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/modalPedidoConsentimento', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('idLead', payload.idLead || '')
        formData.append('idLeadTemp', payload.idLeadTemp || '')
        formData.append('onlyRead', payload.onlyRead || '')

        api.post(`${apiConfig.url_base_api}leads/modalPedidoConsentimento`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                if ('sw266' in response.data.data) {
                  resolve(response.data.data.sw266)
                } else if (payload.onlyRead === true) {
                  resolve([])
                } else {
                  reject(new Error(i18n.t('Problema ao gerar o link do consentimento')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao gerar o link do consentimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao gerar o link do consentimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao gerar o link do consentimento')))
            }
          })
      })

      return request
    },

    async generateLinkShortConsentimento({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/gerarLinkCurto', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw266s01', payload.sw266s01)

        api.post(`${apiConfig.url_base_api}leads/gerarLinkCurto`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if ((response.data.error === 0) && (response.data.url !== '')) {
                  resolve(response.data.url)
                } else {
                  reject(new Error(i18n.t('Problema ao gerar o link curto do consentimento')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao gerar o link curto do consentimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao gerar o link curto do consentimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao gerar o link curto do consentimento')))
            }
          })
      })

      return request
    },

    async checkExistAllMyLeads({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/checkExistAllMyLeads', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s01', payload.sw025s01 || '')
        formData.append('sw025s05', payload.sw025s05 || '')
        formData.append('sw025s06', payload.sw025s06 || '')
        formData.append('sw025s26', payload.sw025s26 || '')
        formData.append('swShare', payload.swShare || '')

        api.post(`${apiConfig.url_base_api}leads/checkExistAllMyLeads`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o registo na base de dados')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o registo na base de dados')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a verificação se já existe o registo na base de dados')))
            }
          })
      })

      return request
    },

    async saveLeadShareToUser({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/saveLeadShareToUser', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('lead', payload.sw025s01 || '')

        api.post(`${apiConfig.url_base_api}leads/saveLeadShareToUser`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a Lead')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a Lead')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a Lead')))
            }
          })
      })

      return request
    },

    async saveLeadRefToUser({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/saveLeadRefToUser', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('lead', payload.sw025s01 || '')

        api.post(`${apiConfig.url_base_api}leads/saveLeadRefToUser`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao aceitar a referência')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao aceitar a referência')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao aceitar a referência')))
            }
          })
      })

      return request
    },

    async deleteRef({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/deleteRef', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('regDel', payload.sw025s01 || '')

        api.post(`${apiConfig.url_base_api}leads/deleteRef`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao rejeitar a referência')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao rejeitar a referência')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao rejeitar a referência')))
            }
          })
      })

      return request
    },

    async modalAddBookmarks({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/modalAddBookmarks', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.sw025s01) && (payload.sw025s01.length > 0)) {
          payload.sw025s01.forEach(row => {
            formData.append('leads[]', row || '')
          })
        } else {
          formData.append('leads[]', payload.sw025s01 || '')
        }

        api.post(`${apiConfig.url_base_api}leads/modalAddBookmarks`, formData)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                resolve(response.data.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter os marcadores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter os marcadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter os marcadores')))
            }
          })
      })

      return request
    },

    async addBookmarksByMultiLeads({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/addBookmarksByMultiLeads', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('idsSelection', JSON.stringify(payload.sw025s01 || ''))

        if (Array.isArray(payload.sw076s03) && (payload.sw076s03.length > 0)) {
          payload.sw076s03.forEach(row => {
            formData.append('sw076s03[]', btoa(row.sw075s01))
          })
        }

        api.post(`${apiConfig.url_base_api}leads/addBookmarksByMultiLeads`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.msg)
                } else {
                  reject(new Error(response.data.msg))
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a atribuição dos marcadores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a atribuição dos marcadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a atribuição dos marcadores')))
            }
          })
      })

      return request
    },

  },
}
