import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {},
  getters: {
  },
  mutations: {
  },
  actions: {

    async searchAllUsersAuth({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload.text || '')
      formData.append('includeDelAndSusp', true)
      formData.append('limit', 50)
      formData.append('idUser', payload.idUser || '')

      await dispatch('request/cancelTokensPendingByUrl', 'users/searchUsersAuth', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}users/searchUsersAuth`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os consultores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os consultores')))
            }
          })
      })
    },

    async searchAllAgents({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload.text || '')
      formData.append('limit', 50)
      formData.append('idUser', payload.idUser || '')
      formData.append('sw001s13[]', 4)
      formData.append('sw001s13[]', 7)

      if ((payload !== null) && (payload !== undefined) && ('includeDelAndSusp' in payload)) {
        formData.append('includeDelAndSusp', payload.includeDelAndSusp)
      } else {
        formData.append('includeDelAndSusp', true)
      }

      await dispatch('request/cancelTokensPendingByUrl', 'users/searchUsersAuth', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}users/searchUsersAuth`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os consultores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os consultores')))
            }
          })
      })
    },

    async searchConsultoresByLocationMktAuth({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload)
      formData.append('sw001s13[]', 4)
      formData.append('sw001s13[]', 7)
      formData.append('limit', 10)

      await dispatch('request/cancelTokensPendingByUrl', 'users/searchConsultoresByLocationMktAuth', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}users/searchConsultoresByLocationMktAuth`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os consultores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os consultores')))
            }
          })
      })
    },
  },
}
