<template>
  <div>
    <app-timeline
      v-if="data.length > 0"
    >
      <app-timeline-item
        v-for="(row, index) in data"
        :key="`seguimento-item-${index}`"
        :variant="checkStatusFinish(row)"
        :icon="checkIconFinish(row)"
        :class="{ 'cursor-pointer': true }"
        @click.stop.prevent="viewSeguimento(row)"
      >
        <list-data-item
          :row="row"
          :with-name-lead="withNameLead"
          :view-seguimento="viewSeguimento"
        />
      </app-timeline-item>
    </app-timeline>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ListDataItem from './listDataItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    ListDataItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    withNameLead: {
      type: Boolean,
      default: false,
      required: false,
    },
    viewSeguimento: {
      type: Function,
      required: true,
    },
  },
  methods: {
    checkIconFinish(row) {
      if (Number(row.sw030s09) === 1) {
        return 'CheckIcon'
      }

      if (Number(row.sw030s09) === 2) {
        return 'XIcon'
      }

      return ' '
    },
    checkStatusFinish(row) {
      if (Number(row.sw030s09) === 1) {
        return 'success'
      }
      if (Number(row.sw030s09) === 2) {
        return 'danger'
      }

      return 'secondary'
    },
  },
}
</script>
