import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  searchTxt: ref(''),
  totalFieldsFilter: ref(0),
  showListviewFilter: ref(false),
  orderBy: ref({
    column: 'lastChange',
    sort: 'desc',
  }),
  txtSearchPlaceholder: i18n.t('Nome, telemóvel, email'),
  txtLoading: i18n.t('A carregar registos ...'),
  txtDefault: i18n.t('Não tem nenhum familiar registado'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  fieldsOrder: [
    {
      name: 'lastChange',
      txt: i18n.t('Ordenar pela última alteração'),
    },
    {
      name: 'name',
      txt: i18n.t('Ordenar por nome'),
    },
    {
      name: 'phone',
      txt: i18n.t('Ordenar por telemóvel'),
    },
    {
      name: 'email',
      txt: i18n.t('Ordenar por email'),
    },
  ],
  showFormCreateNew: ref(false),
})

export default defaultState
