import { ContactPicker } from '@calvinckho/capacitor-contact-picker'

export const eventsDeviceImport = {
  data() {
    return {
      contactsDevice: [],
      searchContactsDevice: false,
    }
  },
  methods: {
    async getContactsDevice() {
      try {
        await this.$root.$emit('app::loading', true)

        if (this.$store.getters['capacitor/isNative'] === true) {
          try {
            const contact = await ContactPicker.open()

            await new Promise(resolve => {
              const {
                contactId, displayName, emailAddresses, phoneNumbers, photoThumbnail, birthday,
              } = contact

              const aEmailsContact = []
              if ((emailAddresses !== undefined) && (emailAddresses.length > 0)) {
                emailAddresses.forEach(o => {
                  if ((o.emailAddress !== undefined) && (o.emailAddress !== '')) {
                    aEmailsContact.push({
                      label: o.type || '',
                      address: o.emailAddress || '',
                    })
                  }
                })
              }

              const aPhonesContact = []
              if ((phoneNumbers !== undefined) && (phoneNumbers.length > 0)) {
                phoneNumbers.forEach(o => {
                  if ((o.phoneNumber !== undefined) && (o.phoneNumber !== '')) {
                    aPhonesContact.push({
                      label: o.type || '',
                      number: o.phoneNumber || '',
                    })
                  }
                })
              }

              if (contactId !== undefined) {
                const oInfoContact = {
                  id: contactId || '',
                  name: displayName || '',
                  birthday: birthday || '',
                  emails: aEmailsContact || [],
                  phones: aPhonesContact || [],
                  photo: photoThumbnail || '',
                }

                this.chooseContact(oInfoContact, false)
              }

              this.$root.$emit('app::loading', false)
              resolve(true)
            })
          } catch (err) {
            this.$root.$emit('app::loading', false)

            const { errorMessage } = err
            if ((errorMessage !== undefined) && (errorMessage !== '')) {
              this.showMsgErrorRequest({ message: `${this.$t('Problema ao aceder aos contactos no dispositivo.')} (${errorMessage})` })
            }
          }
        } else {
          await this.getContactsDeviceWeb()
        }
      } catch (err) {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest({ message: this.$t('Problema ao aceder aos contactos no dispositivo.') })
      }
    },
    async getContactsDeviceWeb() {
      this.contactsDevice = []

      // Para simulação web
      const aContactos = []

      for (let i = 1; i <= 100; i += 1) {
        aContactos.push({
          contactId: i,
          displayName: `Nome completo ${i}`,
          organizationName: `Empresa ${i}`,
          emails: [{ address: `${i}@signed.pt` }],
          phoneNumbers: [{ label: `Nome ${i}`, number: '910 000 111' }],
        })
      }

      const resultContactsDevice = {
        contacts: aContactos,
      }

      this.contactsDevice = this.formatContactsToList(resultContactsDevice)

      if (this.contactsDevice.length > 0) {
        this.$root.$emit('app::loading', false)
        this.searchContactsDevice = true
      } else {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest({ message: this.$t('Não tem contactos no dispositivo.') })
      }
    },
    formatContactsToList(data) {
      const resultData = []

      try {
        const { contacts } = data

        if (contacts.length > 0) {
          contacts.forEach(o => {
            const txtPhones = []
            const aPhones = []
            if ('phoneNumbers' in o) {
              o.phoneNumbers.forEach(row => {
                if (row.number) {
                  aPhones.push({
                    label: row.label || '',
                    number: row.number.replace(/ /g, ''),
                  })
                  txtPhones.push(row.number.replace(/ /g, ''))
                }
              })
            }

            let txtEmails = ''
            const aEmails = []
            if ('emails' in o) {
              o.emails.forEach(row => {
                if (row.address) {
                  aEmails.push(row)
                  txtEmails += ((txtEmails) ? ' ; ' : '') + row.address
                }
              })
            }

            // So carrega os contactos que tenha algum numero ou email
            if ((aPhones.length > 0) || (aEmails.length > 0)) {
              resultData.push({
                id: o.contactId || '',
                name: o.displayName || '',
                business: o.organizationName || '',
                birthday: o.birthday || '',
                photo: o.photoThumbnail || '',
                emails: aEmails || [],
                emails_txt: txtEmails || '',
                phones: aPhones || [],
                phones_txt: txtPhones || '',
              })
            }
          })
        }
      } catch (err) {
        //
      }

      return resultData
    },
    async chooseContact(contact, replaceInfo = false) {
      try {
        const oVars = this.$store.getters[`${this.NAMESPACE_SW025_FORM}/vars`]
        const oLoadDataGeral = this.$store.getters[`${this.NAMESPACE_SW025_FORM}/load_data_geral`]

        if ((oVars.importedIsMobile === false) || (replaceInfo === true)) {
          if (oVars.importedIsMobile === true) {
            await this.$root.$emit('crmLeadsForm::uploadImageAvatar', '')
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s03', value: '' })
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s04', value: '' })
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s05', value: '' })
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s06', value: '' })
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, { field: 'sw025s14', value: '' })
            await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/clearContactAdditional`)
          }

          if ((contact !== null) && (contact !== undefined)) {
            if (('id' in contact) && (contact.id !== '')) {
              // Data de Aniversario
              if (('birthday' in contact) && (contact.birthday !== '')) {
                await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                  field: 'sw025s14',
                  value: contact.birthday,
                })
              }

              // Nome + Apelido
              if (('name' in contact) && (contact.name !== '')) {
                const aNameFull = contact.name.split(' ')

                if (aNameFull.length > 0) {
                  if (aNameFull[0] !== '') {
                    await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                      field: 'sw025s03',
                      value: aNameFull[0],
                    })
                    aNameFull.splice(aNameFull, 1)
                  }
                }

                if (aNameFull.length > 0) {
                  await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                    field: 'sw025s04',
                    value: aNameFull.join(' '),
                  })
                }
              }

              // Emails + contactos adicionais
              if (('emails' in contact) && (contact.emails.length > 0)) {
                contact.emails.forEach((o, index) => {
                  if (index === 0) {
                    this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                      field: 'sw025s06',
                      value: o.address,
                    })
                  } else {
                    const templateContact = oVars.templatesContactosAdicionais.find(tmpl => tmpl.tag === 'email')

                    if ((templateContact !== null) && (templateContact !== undefined) && ('template' in templateContact)) {
                      const componentLoad = () => import(`@/views/modules/crm/leads/form/form_data/components/${templateContact.template}`)
                      this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/addNewContactAdditionalWithData`, {
                        sw095s01: null,
                        sw095s03: oLoadDataGeral.tiposContactosAdicionais.find(oVar => Number(oVar.id) === templateContact.id),
                        sw095s04: o.address,
                        sw095s05: o.label,
                        template: componentLoad,
                      })
                    }
                  }
                })
              }

              // Contactos + contactos adicionais
              if (('phones' in contact) && (contact.phones.length > 0)) {
                contact.phones.forEach((o, index) => {
                  if (index === 0) {
                    this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/updateFieldValue`, {
                      field: 'sw025s05',
                      value: o.number,
                    })
                  } else {
                    const templateContact = oVars.templatesContactosAdicionais.find(tmpl => tmpl.tag === 'telefone')

                    if ((templateContact !== null) && (templateContact !== undefined) && ('template' in templateContact)) {
                      const componentLoad = () => import(`@/views/modules/crm/leads/form/form_data/components/${templateContact.template}`)
                      this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/addNewContactAdditionalWithData`, {
                        sw095s01: null,
                        sw095s03: oLoadDataGeral.tiposContactosAdicionais.find(oVar => Number(oVar.id) === templateContact.id),
                        sw095s04: o.number,
                        sw095s05: o.label,
                        template: componentLoad,
                      })
                    }
                  }
                })
              }

              // Avatar
              if (('photo' in contact) && (contact.photo !== '')) {
                await this.$root.$emit('crmLeadsForm::uploadImageAvatar', contact.photo)
              }
            }
          }

          // Ativa o tabulador do menu "Contactos"
          await this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setTabContentStepShow`, 2)

          // Retirar o botao de "Importar"
          await this.$store.commit(`${this.NAMESPACE_SW025_FORM}/setImportedIsMobile`, true)
        } else {
          this.$swal({
            title: '',
            html: `${this.$t('Pretende subsituir os contactos existentes por este contacto que selecionou?')}`,
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Importar'),
            cancelButtonText: this.$t('Cancelar'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.chooseContact(contact, true)
            }
          })
        }
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao importar o contacto.') })
      }

      // Fecha modal
      // this.closeSearchContactsDevice()
    },
    closeSearchContactsDevice() {
      this.searchContactsDevice = false
    },
  },
}

export const _ = null
