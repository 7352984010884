<template>
  <div>
    <b-form
      ref="formSaveLead"
      @submit.prevent
    >
      <validation-observer
        ref="formSaveLeadValidator"
        #default="{}"
      >

        <b-tabs
          ref="formSteps"
          v-model="tabContentStepShow"
          nav-class="d-none"
          @input="checkStatusArrowNavDisableFooter"
        >

          <!-- Geral -->
          <b-tab
            ref="tabContentFormGeral"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-geral />
          </b-tab>

          <!-- Dados -->
          <b-tab
            ref="tabContentFormDados"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-dados />
          </b-tab>

          <!-- Contactos adicionais -->
          <b-tab
            ref="tabContentFormContactosAdicionais"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-contactos-adicionais />
          </b-tab>

          <!-- Qualificacao Comprador -->
          <b-tab
            ref="tabContentFormQualifComprador"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-qualificacao-comprador />
          </b-tab>

          <!-- Qualificacao Vendedor -->
          <b-tab
            ref="tabContentFormQualifVendedor"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-qualificacao-vendedor />
          </b-tab>

          <!-- Imoveis de interesse -->
          <b-tab
            ref="tabContentFormImoveisInt"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-imoveis-interesse />
          </b-tab>

          <!-- Familia -->
          <b-tab
            ref="tabContentFormFamilia"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-familia />
          </b-tab>

          <!-- Morada -->
          <b-tab
            ref="tabContentFormMorada"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-morada />
          </b-tab>

          <!-- Imoveis angariados -->
          <b-tab
            ref="tabContentFormImoveisAngariados"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-imoveis-angariados />
          </b-tab>

          <!-- Imoveis pre-angariados -->
          <b-tab
            ref="tabContentFormImoveisPreAngariados"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-imoveis-pre-angariados />
          </b-tab>

          <!-- Seguimentos -->
          <b-tab
            ref="tabContentFormSeguimentos"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-seguimentos />
          </b-tab>

          <!-- Notas -->
          <b-tab
            ref="tabContentFormNotas"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-notas />
          </b-tab>

          <!-- Observacao -->
          <!--
          <b-tab
            ref="tabContentFormObservacoes"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-observacao />
          </b-tab>
          -->

          <!-- Registo de atividade -->
          <b-tab
            ref="tabContentFormActivityLog"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-log />
          </b-tab>

          <!-- Atividade de consentimento -->
          <b-tab
            ref="tabContentFormActivityConsent"
            title=""
            title-item-class="d-none"
          >
            <tabs-form-data-activity-consent />
          </b-tab>

        </b-tabs>

      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import {
  BTab, BTabs, BForm,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import TabsFormDataGeral from './form_data/geral.vue'
import TabsFormDataDados from './form_data/dados.vue'
import TabsFormDataContactosAdicionais from './form_data/contactosAdicionais.vue'
import TabsFormDataQualificacaoComprador from './form_data/sw372/list.vue'
import TabsFormDataQualificacaoVendedor from './form_data/qualificacaoVendedor.vue'
import TabsFormDataImoveisInteresse from './form_data/sw031/list.vue'
import TabsFormDataImoveisAngariados from './form_data/sw031/listAngariados.vue'
import TabsFormDataImoveisPreAngariados from './form_data/sw031/listPreAngariados.vue'
import TabsFormDataFamilia from './form_data/sw029/list.vue'
import TabsFormDataMorada from './form_data/morada.vue'
import TabsFormDataSeguimentos from './form_data/seguimentos/list.vue'
import TabsFormDataNotas from './form_data/sw044/list.vue'
// import TabsFormDataObservacao from './form_data/observacao.vue'
import TabsFormDataActivityLog from './form_data/activityLog.vue'
import TabsFormDataActivityConsent from './form_data/activityConsent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BForm,
    ValidationObserver,
    TabsFormDataGeral,
    TabsFormDataDados,
    TabsFormDataQualificacaoComprador,
    TabsFormDataQualificacaoVendedor,
    TabsFormDataImoveisInteresse,
    TabsFormDataImoveisAngariados,
    TabsFormDataImoveisPreAngariados,
    TabsFormDataFamilia,
    TabsFormDataMorada,
    TabsFormDataSeguimentos,
    TabsFormDataNotas,
    TabsFormDataContactosAdicionais,
    TabsFormDataActivityLog,
    TabsFormDataActivityConsent,
  },
  computed: {
    ...mapGetters('crm_leads_form', [
      'vars',
      'loaded',
      'perms',
    ]),
    tabContentStepShow: {
      get() { return this.$store.getters['crm_leads_form/tabContentStepShow'] },
      set(newValue) {
        this.$store.commit('crm_leads_form/setTabContentStepShow', newValue)
      },
    },
    showTabQualifComprador() {
      return this.$store.getters['crm_leads_form/perms'].showTabQualifComprador()
    },
    showTabQualifVendedor() {
      return this.$store.getters['crm_leads_form/perms'].showTabQualifVendedor()
    },
    showTabImoveisAngOrPreAng() {
      return this.$store.getters['crm_leads_form/perms'].showTabImoveisAngOrPreAng()
    },
    showTabImoveisInt() {
      return this.$store.getters['crm_leads_form/perms'].showTabImoveisInt()
    },
    isRecord() {
      return this.$store.getters['crm_leads_form/perms'].isRecord()
    },
  },
  watch: {
    showTabQualifComprador(newVal) {
      if (newVal === true) {
        this.$refs.tabContentFormQualifComprador.registerTab()
        this.initComponent(this.$refs.tabContentFormQualifComprador)
      } else {
        this.$refs.tabContentFormQualifComprador.unregisterTab()
      }
    },
    showTabQualifVendedor(newVal) {
      if (newVal === true) {
        this.$refs.tabContentFormQualifVendedor.registerTab()
        this.initComponent(this.$refs.tabContentFormQualifVendedor)
      } else {
        this.$refs.tabContentFormQualifVendedor.unregisterTab()
      }
    },
    showTabImoveisAngOrPreAng(newVal) {
      if (newVal === true) {
        this.$refs.tabContentFormImoveisAngariados.registerTab()
        this.initComponent(this.$refs.tabContentFormImoveisAngariados)

        this.$refs.tabContentFormImoveisPreAngariados.registerTab()
        this.initComponent(this.$refs.tabContentFormImoveisPreAngariados)
      } else {
        this.$refs.tabContentFormImoveisAngariados.unregisterTab()
        this.$refs.tabContentFormImoveisPreAngariados.unregisterTab()
      }
    },
    showTabImoveisInt(newVal) {
      if (newVal === true) {
        this.$refs.tabContentFormImoveisInt.registerTab()
        this.initComponent(this.$refs.tabContentFormImoveisInt)
      } else {
        this.$refs.tabContentFormImoveisInt.unregisterTab()
      }
    },
    isRecord(newVal) {
      if (newVal === true) {
        this.$refs.tabContentFormActivityLog.registerTab()
        this.initComponent(this.$refs.tabContentFormActivityLog)

        this.$refs.tabContentFormActivityConsent.registerTab()
        this.initComponent(this.$refs.tabContentFormActivityConsent)
      } else {
        this.$refs.tabContentFormActivityLog.unregisterTab()
        this.$refs.tabContentFormActivityConsent.unregisterTab()
      }
    },
  },
  mounted() {
    if (this.perms.showTabQualifComprador() === false) {
      this.$refs.tabContentFormQualifComprador.unregisterTab()
    }

    if (this.perms.showTabQualifVendedor() === false) {
      this.$refs.tabContentFormQualifVendedor.unregisterTab()
    }

    if (this.perms.showTabImoveisAngOrPreAng() === false) {
      this.$refs.tabContentFormImoveisAngariados.unregisterTab()
      this.$refs.tabContentFormImoveisPreAngariados.unregisterTab()
    }

    if (this.perms.showTabImoveisInt() === false) {
      this.$refs.tabContentFormImoveisInt.unregisterTab()
    }

    if (this.perms.isRecord() === false) {
      this.$refs.tabContentFormActivityLog.unregisterTab()
      this.$refs.tabContentFormActivityConsent.unregisterTab()
    }
  },
  methods: {
    checkStatusArrowNavDisableFooter() {
      try {
        const { currentTab } = this.$refs.formSteps
        const totalTabs = this.$refs.formSteps.getTabs().length

        this.$refs.formSteps.getTabs().forEach(o => {
          o.$children[0].$refs.footerStepNav.updateStatusArrowNavDisableFooter(currentTab, totalTabs)
        })
      } catch (err) {
        //
      }
    },
    async initComponent(oComponent) {
      try {
        if (typeof oComponent.$children[0].init === 'function') {
          if (this.loaded === true) {
            await oComponent.$children[0].init()
          }
        }
      } catch (err) {
        //
      }
    },
  },
}
</script>
