<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list">
      <tabs-form-data ref="tabsFormData" />
    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <form-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :view="formModeView"
        :save-form="saveForm"
        :edit-form="editForm"
        :share-reference-form="shareReferenceForm"
        :share-lead-form="shareLeadForm"
        :cancel-form="cancelForm"
        :redirect-page-back="redirectPageBack"
        :redirect-page-to-listview="redirectPageToListview"
        :get-contacts-device="getContactsDevice"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <component
      :is="SearchContactsDevice"
      v-if="searchContactsDevice===true"
      :choose-contact="chooseContact"
      :contacts="contactsDevice"
      @close-search-contacts-device="closeSearchContactsDevice"
    />

    <component
      :is="ModalChooseCampaign"
      v-if="campanhasToAssocLead.length > 0"
      ref="modalChooseCampaign"
      :data="campanhasToAssocLead"
      :total="campanhasToAssocLeadTotal"
    />

    <component
      :is="ModalShareLead"
      ref="modalShareLead"
    />

    <component
      :is="ModalShareReference"
      ref="modalShareReference"
    />

    <component
      :is="ModalAtiveCampaign"
      v-if="campanhasAtiveAssocLead.length > 0"
      ref="modalAtiveCampaign"
      :data="campanhasAtiveAssocLead"
      :total="campanhasAtiveAssocLeadTotal"
    />

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import crmLeadsFormModule from '@store-modules/crm/leads/form'
import crmLeadsEventsModule from '@store-modules/crm/leads/listview/events'
import { mapActions, mapGetters } from 'vuex'
import { useRouter, showModalMessage } from '@core/utils/utils'
import checkNested from 'check-nested'
import btoa from 'btoa'
import { VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import FormLeftSidebar from './leftSidebar.vue'
import TabsFormData from './tabs.vue'
import { mixFormGeral, mixEventsModals } from './mixins'
import { eventsDeviceImport } from './form_data/device/mixinDevice'

export default {
  components: {
    FormLeftSidebar,
    TabsFormData,
  },
  mixins: [mixFormGeral, mixEventsModals, eventsDeviceImport, VeeValidateLoadLocale],
  props: {
    typeAction: {
      type: String,
      required: false,
      default: '',
    },
    viewDetail: {
      type: Boolean,
      required: false,
      default: false,
    },
    contactToLead: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      prevRoute: null,
      errorLoadingPage: false,
      campanhasToAssocLead: [],
      campanhasToAssocLeadTotal: 0,
      campanhasAtiveAssocLead: [],
      campanhasAtiveAssocLeadTotal: 0,
    }
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['form_data', 'form_sw095', 'load_data_geral', 'vars', 'alertsRGPD', 'formModeView', 'keyReg', 'messageInfo']),
    ...mapActions('crm_leads_form', ['addNewContactAdditional']),
    mqShallShowLeftSidebar: {
      get() { return this.$store.getters['crm_leads_form/mqShallShowLeftSidebar'] },
      set(newValue) {
        this.$store.commit('crm_leads_form/setMqShallShowLeftSidebar', newValue)
      },
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.paramsUrl = to.params
        this.$store.dispatch('crm_leads_form/setParamsUrl', this.paramsUrl)
        this.renderCreated()
      }
    },
  },
  async created() {
    await this.renderCreated()
  },
  async mounted() {
    // Para atualizar os botoes de navegacao
    this.$refs.tabsFormData.checkStatusArrowNavDisableFooter()
  },
  beforeCreate() {
    this.$root.$on('crmLeadsForm::getContactsDevice', () => {
      this.getContactsDevice()
    })

    this.$root.$on('crmLeadsForm::showMsgDetailRequest', (data, resolve) => {
      this.showMsgDetailRequest(data.pIcon || '', data.pDetail || '', resolve)
    })
  },
  beforeDestroy() {
    this.$root.$off('crmLeadsForm::getContactsDevice')
    this.$root.$off('crmLeadsForm::showMsgDetailRequest')
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const self = vm
      self.prevRoute = from

      if (from.name === null) {
        if ((localStorage.getItem(vm.$router.currentRoute.name) !== null) && (localStorage.getItem(vm.$router.currentRoute.name) !== '')) {
          localStorage.removeItem(vm.$router.currentRoute.name)
        }
      } else if (to.name.includes('-view')) {
        if ((from.name !== null) && (from.name.includes('-edit'))) {
          //
        } else {
          localStorage.setItem(vm.$router.currentRoute.name, self.prevRoute.path)
        }
      }
    })
  },
  async beforeRouteLeave(to, from, next) {
    if (this.viewDetail === false) {
      await this.$store.dispatch('crm_leads_form/clearkeepAlive')
    }
    next()
    /*
   if (unsavedChanges) {
       this.nextObj = next;
       this.showModal = true;
    } else {
        next();
    }
    */
  },
  methods: {
    async renderCreated() {
      try {
        this.$root.$emit('app::loading', true)

        const { id } = this.paramsUrl

        // Faz reset a todas as variaveis do formulario
        await this.$store.commit('crm_leads_form/resetState')

        this.contactsDevice = []
        this.searchContactsDevice = false
        this.errorLoadingPage = false
        this.campanhasToAssocLead = []
        this.campanhasToAss = 0

        await this.$store.dispatch('crm_leads_form/getDataFormBase', { keyReg: id || '' }).catch(async error => {
          this.errorLoadingPage = true
          this.showMsgErrorRequest(error)
          await this.redirectPageBack()
        })

        if (this.errorLoadingPage === false) {
          await this.getDataFormExtraCurrentUser()
        }

        if (this.errorLoadingPage === false) {
          await this.$refs.tabsFormData.$refs.formSteps.getTabs().forEach(async o => {
            try {
              if (typeof o.$children[0].init === 'function') {
                await o.$children[0].init()
              }
            } catch (err) {
              //
            }
          })

          await this.fieldsLoadDataDefault()

          if ('sw025s01' in this.vars.sw025) {
            await this.renderDataEditionGeral()
            await this.renderDataEditionDados()
            await this.renderDataEditionContactos()
            await this.renderDataEditionQualifComprador()
            await this.renderDataEditionQualifVendedor()
            await this.renderDataEditionImoveisInt()
            await this.renderDataEditionMorada()
            await this.renderDataEditionObs()
            await this.renderDataEditionImoveisAngOrPreAng()
            await this.renderDataExtraParams()
          }

          await this.$store.dispatch('crm_leads_form/setLoaded')

          this.$root.$emit('app::loading', false)
        }
      } catch (err) {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(new Error(this.$t('Problema ao carregar a informação')))
        this.redirectPageBack()
      }
    },
    async fieldsLoadDataDefault() {
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s98', value: this.load_data_geral.periodos.find(o => o.id === 2) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s100', value: this.load_data_geral.periodos.find(o => o.id === 2) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s11', value: this.load_data_geral.optionsYesNo.find(o => Number(o.value) === 2) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s197', value: this.load_data_geral.elegibilidadeContactoComercial.find(o => Number(o.id) === 0) })
    },
    async renderDataEditionGeral() {
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s66', value: this.vars.sw025.sw025s66 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s192', value: this.vars.sw025.sw025s192 })

      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s66', value: this.vars.sw025.sw025s66 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s03', value: this.vars.sw025.sw025s03 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s04', value: this.vars.sw025.sw025s04 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s08', value: this.load_data_geral.classificacaoLead.find(o => Number(o.sw021s01) === Number(this.vars.sw025.sw025s08)) })

      if (Array.isArray(this.vars.sw025.sw027s03) && (this.vars.sw025.sw027s03.length > 0)) {
        const aSW027s03 = []
        Object.keys(this.load_data_geral.tipoClienteLead).forEach(index => {
          const oValue = this.vars.sw025.sw027s03.find(valSW027 => Number(valSW027) === Number(this.load_data_geral.tipoClienteLead[index].sw022s01))
          if (oValue) {
            aSW027s03.push(this.load_data_geral.tipoClienteLead[index])
          }
        })

        await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw027s03', value: aSW027s03 })
      }

      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: this.load_data_geral.estadosLeadComprador.find(o => Number(o.id) === Number(this.vars.sw025.sw025s07)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s60', value: this.load_data_geral.estadosLeadVendedor.find(o => Number(o.id) === Number(this.vars.sw025.sw025s60)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s11', value: this.load_data_geral.optionsYesNo.find(o => Number(o.value) === Number(this.vars.sw025.sw025s11)) })

      if (this.vars.sw025.sw025s121_url !== '') {
        await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s121', value: this.vars.sw025.sw025s121_url })
      }
    },
    async renderDataEditionDados() {
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s109', value: this.vars.sw025.sw025s109 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s10', value: this.load_data_geral.generos.find(o => o.id === this.vars.sw025.sw025s10) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s13', value: this.load_data_geral.fonteContactos.find(o => o.sw023s01 === this.vars.sw025.sw025s13) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s14', value: this.vars.sw025.sw025s14 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s87', value: this.load_data_geral.tiposNotificacaoDataAniversario.find(o => Number(o.id) === Number(this.vars.sw025.sw025s87)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s15', value: this.vars.sw025.sw025s15 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s26', value: this.vars.sw025.sw025s26 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s27', value: this.vars.sw025.sw025s27 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s86', value: this.vars.sw025.sw025s86 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s120', value: this.vars.sw025.sw025s120 })

      // Empresa
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s113', value: this.vars.sw025.sw025s113 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s114', value: this.load_data_geral.empresasCRM.find(o => Number(o.sw101s01) === Number(this.vars.sw025.sw025s114)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s115', value: this.vars.sw025.sw025s115 })

      // RGPD
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s197', value: this.load_data_geral.elegibilidadeContactoComercial.find(o => Number(o.id) === Number(this.vars.sw025.sw025s197)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s152', value: this.vars.sw025.sw025s152 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s205', value: this.vars.sw025.sw025s205 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s206', value: this.vars.sw025.sw025s206 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s153', value: this.vars.sw025.sw025s153 })
      await this.$refs.tabsFormData.$refs.tabContentFormDados.$children[0].loadDocumentosConsentimentoExplicito()
      await this.$refs.tabsFormData.$refs.tabContentFormDados.$children[0].loadConsentimentoLink()

      // Fonte de contacto
      if (checkNested(this.form_data, 'sw025s13')) {
        if ((this.form_data.sw025s13 !== null) && (this.form_data.sw025s13 !== undefined)) {
          if ('sw023s06' in this.form_data.sw025s13) {
            await this.$refs.tabsFormData.$refs.tabContentFormDados.$children[0].fieldsExtrasFonteContacto(this.form_data.sw025s13)

            if (this.form_data.sw025s13.sw023s06 === 'AO') {
              await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s221', value: this.load_data_geral.fonteContactoTiposAnuncio.find(o => Number(o.id) === Number(this.vars.sw025.sw025s221)) })
              await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s222', value: this.vars.sw025.sw025s222 })
            }

            if (this.form_data.sw025s13.sw023s06 === 'C') {
              if ('sw025s43' in this.vars.sw025) {
                await this.$refs.tabsFormData.$refs.tabContentFormDados.$children[0].selectedConsultoresApp(this.vars.sw025.sw025s43)
              }
            }

            if (this.form_data.sw025s13.sw023s06 === 'M') {
              if ('sw025s44' in this.vars.sw025) {
                await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s44', value: this.load_data_geral.fonteContactoHUBs.find(o => Number(o.sw002s01) === Number(this.vars.sw025.sw025s44)) })
              }
            }

            if (this.form_data.sw025s13.sw023s06 === 'L') {
              if ('sw025s45' in this.vars.sw025) {
                await this.$refs.tabsFormData.$refs.tabContentFormDados.$children[0].selectedLeadsContactsApp(this.vars.sw025.sw025s45)
              }
            }

            if (this.form_data.sw025s13.sw023s06 === 'SI') {
              if ('sw025s63' in this.vars.sw025) {
                await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s63', value: this.load_data_geral.fonteContactoSitesImobiliarios.find(o => Number(o.sw066s01) === Number(this.vars.sw025.sw025s63)) })
              }
            }
          }
        }
      }
    },
    async renderDataEditionContactos() {
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s05', value: this.vars.sw025.sw025s05 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s06', value: this.vars.sw025.sw025s06 })

      if (this.vars.sw095.length > 0) {
        this.vars.sw095.forEach(sw095 => {
          if (sw095.sw095s01 && sw095.sw095s03) {
            const templateContact = this.vars.templatesContactosAdicionais.find(o => Number(o.id) === Number(sw095.sw095s03))

            if ((templateContact !== null) && (templateContact !== undefined) && ('template' in templateContact)) {
              const componentLoad = () => import(`@/views/modules/crm/leads/form/form_data/components/${templateContact.template}`)

              this.$store.dispatch('crm_leads_form/addNewContactAdditionalWithData', {
                ...sw095,
                sw095s03: this.load_data_geral.tiposContactosAdicionais.find(oVar => Number(oVar.id) === Number(sw095.sw095s03)),
                template: componentLoad,
              })
            }
          }
        })
      }
    },
    async renderDataEditionQualifComprador() {
      await this.$refs.tabsFormData.$refs.tabContentFormQualifComprador.$children[0].init()
    },
    async renderDataEditionQualifVendedor() {
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s122', value: this.vars.sw025.sw025s122 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s29', value: this.vars.sw025.sw025s29 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s97', value: this.vars.sw025.sw025s97 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s98', value: this.load_data_geral.periodos.find(o => Number(o.id) === Number(this.vars.sw025.sw025s98)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s99', value: this.vars.sw025.sw025s99 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s100', value: this.load_data_geral.periodos.find(o => Number(o.id) === Number(this.vars.sw025.sw025s100)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s102', value: this.load_data_geral.optionsYesNo.find(o => Number(o.value) === Number(this.vars.sw025.sw025s102)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s103', value: this.vars.sw025.sw025s103 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s101', value: this.vars.sw025.sw025s101 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s104', value: this.load_data_geral.optionsYesNo.find(o => Number(o.value) === Number(this.vars.sw025.sw025s104)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s105', value: this.vars.sw025.sw025s105 })
    },
    async renderDataEditionImoveisInt() {
      await this.$refs.tabsFormData.$refs.tabContentFormImoveisInt.$children[0].init()
    },
    async renderDataEditionMorada() {
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s90', value: this.vars.sw025.sw025s90 })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s91', value: this.load_data_geral.countries.find(o => Number(o.id) === Number(this.vars.sw025.sw025s91)) })
      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s92', value: this.vars.sw025.sw025s92 })

      await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s25', value: this.vars.sw025.sw025s25 })

      if ((this.$store.getters['auth/countryApp'] === 'pt') && (Number(this.vars.sw025.sw025s90) !== 1)) {
        const oDataLocalization = {
          codigoPostal: this.vars.sw025.sw025s19 || null,
          distrito: this.vars.sw025.sw025s20 || null,
          concelho: this.vars.sw025.sw025s21 || null,
          freguesia: this.vars.sw025.sw025s22 || null,
          zona: this.vars.sw025.sw025s78 || null,
          rua: this.vars.sw025.sw025s79 || null,
          numeroPorta: this.vars.sw025.sw025s88 || null,
          andar: this.vars.sw025.sw025s89 || null,
          divisao: this.vars.sw025.sw025s23 || null,
          ajudaMorada: this.vars.sw025.sw025s24 || null,
          nomeEdificio: this.vars.sw025.sw025s108 || null,
          ruaManual: this.vars.sw025.sw025s46 || null,
        }

        await this.$refs.tabsFormData.$refs.tabContentFormMorada.$children[0].$refs.location.loadDataForm(oDataLocalization)
      }

      if ((this.$store.getters['auth/countryApp'] === 'es') && (Number(this.vars.sw025.sw025s90) !== 1)) {
        const oDataLocalization = {
          codigoPostal: this.vars.sw025.sw025s19 || null,
          comunidade: this.vars.sw025.sw025s20 || null,
          provincia: this.vars.sw025.sw025s21 || null,
          comarca: this.vars.sw025.sw025s22 || null,
          municipio: this.vars.sw025.sw025s119 || null,
          zona: this.vars.sw025.sw025s78 || null,
          numeroPorta: this.vars.sw025.sw025s88 || null,
          andar: this.vars.sw025.sw025s89 || null,
          fracao: this.vars.sw025.sw025s23 || null,
          ajudaMorada: this.vars.sw025.sw025s24 || null,
          nomeEdificio: this.vars.sw025.sw025s108 || null,
          ruaManual: this.vars.sw025.sw025s46 || null,
        }

        await this.$refs.tabsFormData.$refs.tabContentFormMorada.$children[0].$refs.location.loadDataForm(oDataLocalization)
      }
    },
    async renderDataEditionObs() {
      // await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s28', value: this.vars.sw025.sw025s28 })
    },
    async renderDataEditionImoveisAngOrPreAng() {
      await this.$refs.tabsFormData.$refs.tabContentFormImoveisAngariados.$children[0].init()
      await this.$refs.tabsFormData.$refs.tabContentFormImoveisPreAngariados.$children[0].init()
    },
    async renderDataExtraParams() {
      if (this.vars.convertContactToLead === true) {
        let nameFull = ''
        if (this.vars.sw025.sw025s03 !== '') {
          nameFull += ((nameFull) ? ' ' : ' ') + this.vars.sw025.sw025s03
        }
        if (this.vars.sw025.sw025s04 !== '') {
          nameFull += ((nameFull) ? ' ' : ' ') + this.vars.sw025.sw025s04
        }

        this.$store.dispatch('crm_leads_form/setTypeAction', 'new-lead')
        this.$store.dispatch('crm_leads_form/setTypeUserCreateLead', 0)
        this.$store.dispatch('crm_leads_form/setSW029Assoc', {
          id: btoa(this.vars.sw025.sw025s01),
          name: nameFull,
        })
      }
    },

    async saveForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        await this.$store.dispatch('crm_leads_form/setIsSavingData', true)
        await self.$refs.tabsFormData.$refs.formSaveLeadValidator.validateWithInfo()
          .then(async ({
            isValid, errors,
          }) => {
            if (isValid) {
              this.$root.$emit('app::loading', {
                status: true,
                zIndex: 5,
                txt: this.$t('Aguarde, a processar...'),
              })

              await this.checkSaveRGPD()

              this.$store.commit('crm_leads_form/setExecuteActionShare', '')

              await this.checkSaveContactFamiliar().catch(error => {
                throw error
              })

              await this.saveFormFinish()
            } else {
              throw errors
            }
          })
          .catch(async error => {
            const oError = error
            let bHavePhoneEmail = false
            let showMsgFieldsRequired = false

            if (checkNested(this.form_data, 'sw025s05') && (this.form_data.sw025s05 !== '')) {
              bHavePhoneEmail = true
            }

            if (checkNested(this.form_data, 'sw025s06') && (this.form_data.sw025s06 !== '')) {
              bHavePhoneEmail = true
            }

            if (bHavePhoneEmail === false) {
              oError.EmailOrPhone = []
              oError.EmailOrPhone.push(this.$t('O campo do {phone} ou {email} é obrigatório', { phone: `<b>${this.$t('telemóvel')}</b>`, email: `<b>${this.$t('email')}</b>` }))
              showMsgFieldsRequired = true
            }

            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequestForm(oError, showMsgFieldsRequired)
            await this.$store.dispatch('crm_leads_form/setIsSavingData', false)
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async checkSaveRGPD() {
      return new Promise(resolve => {
        if (this.alertsRGPD.length > 0) {
          this.$swal({
            title: '',
            html: `${this.$t('Resolveu os pedidos solicitados ao abrigo da lei de proteção de dados?')}`,
            icon: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Sim'),
            cancelButtonText: this.$t('Não'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              this.$store.commit('crm_leads_form/setUpdateRGPD', 1)
            } else {
              this.$store.commit('crm_leads_form/setUpdateRGPD', '')
            }
            resolve(true)
          })
        } else {
          resolve(true)
        }
      })
    },
    async checkSaveContactFamiliar() {
      return new Promise((resolve, reject) => {
        if (this.vars.sw029s01Assoc !== '') {
          this.$swal({
            title: '',
            html: `${this.$t('O seu contacto passará agora a Lead e portanto, já não vai encontrar o {nome} nos contactos?', { nome: `<b>${this.vars.sw029s01Nome}</b>` })}`,
            icon: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Sim'),
            cancelButtonText: this.$t('Não'),
            customClass: {
              confirmButton: 'btn btn-primary ml-0 ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              resolve(true)
            } else {
              reject(new Error(this.$t('Tem de aceitar a conversão do contacto para Lead')))
            }
          })
        } else {
          resolve(true)
        }
      })
    },
    async checkSaveCampanhasAdd() {
      return new Promise((resolve, reject) => {
        if (([0, 1, 7].includes(this.vars.typeUserCreateLead)) && (this.vars.executeActionShare === '')) {
          this.$store.dispatch('crm_leads_form/getTotalCampanhas', { sw025s01: this.$store.getters['crm_leads_form/keyReg'] }).then(total => {
            if (total > 0) {
              this.$swal({
                title: '',
                html: `${this.$t('Deseja adicionar o Lead/Contacto numa campanha?')}`,
                icon: 'info',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('Sim'),
                cancelButtonText: this.$t('Não'),
                customClass: {
                  confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
                  cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',

                },
                buttonsStyling: false,
              }).then(async result => {
                if (result.value) {
                  resolve(true)
                } else {
                  resolve(false)
                }
              })
            } else {
              resolve(false)
            }
          }).catch(error => {
            reject(error)
          })
        } else {
          resolve(false)
        }
      })
    },
    async checkCampanhasAtiveByLead() {
      return new Promise((resolve, reject) => {
        if ((this.$store.getters['crm_leads_form/keyReg'] !== '') && ([0, 1, 7].includes(this.vars.typeUserCreateLead)) && (this.vars.executeActionShare === '')) {
          this.$store.dispatch('crm_leads_form/getTotalCampanhas', { sw025s01: this.$store.getters['crm_leads_form/keyReg'], flagCancel: true }).then(total => {
            if (total > 0) {
              resolve(true)
            } else {
              resolve(false)
            }
          }).catch(error => {
            reject(error)
          })
        } else {
          resolve(false)
        }
      })
    },
    async checkSaveCampanhasModalChoose() {
      await this.$store.dispatch('crm_leads_form/getCampanhas', { start: 0 }).then(async result => {
        this.campanhasToAssocLead = result.campanhas || []
        this.campanhasToAssocLeadTotal = result.total || 0
      }).catch(error => {
        throw error
      })

      await this.$nextTick()

      await this.ModalChooseCampaign()

      await this.$nextTick()

      if (this.campanhasToAssocLead.length > 0) {
        await this.$refs.modalChooseCampaign.showModal().then(result => {
          if (result === true) {
            this.campanhasToAssocLead = []
            this.campanhasToAssocLeadTotal = 0
          }
        })
      }
    },
    async getCampanhasAtiveModal() {
      await this.$store.dispatch('crm_leads_form/getCampanhasAtive').then(async result => {
        this.campanhasAtiveAssocLead = result.campanhas || []
        this.campanhasAtiveAssocLeadTotal = result.total || 0
      }).catch(error => {
        throw error
      })

      await this.$nextTick()

      await this.ModalAtiveCampaign()

      await this.$nextTick()

      if (this.campanhasAtiveAssocLead.length > 0) {
        await this.$refs.modalAtiveCampaign.showModal().then(result => {
          if (result === true) {
            this.campanhasAtiveAssocLead = []
            this.campanhasAtiveAssocLeadTotal = 0
          }
        })
      }
    },

    async saveFormFinish() {
      // Verifica se o numero de telefone e email estao preenchidos
      let bHavePhoneEmail = false

      if (checkNested(this.form_data, 'sw025s05') && (this.form_data.sw025s05 !== '')) {
        bHavePhoneEmail = true
      }

      if (checkNested(this.form_data, 'sw025s06') && (this.form_data.sw025s06 !== '')) {
        bHavePhoneEmail = true
      }

      if (bHavePhoneEmail === false) {
        throw new Error(this.$t('O campo do {phone} ou {email} é obrigatório', { phone: `<b>${this.$t('telemóvel')}</b>`, email: `<b>${this.$t('email')}</b>` }))
      }

      // Verifica se a lead esta partilhada com alguem em modo de equipa e que tipo de utilizador
      if (this.load_data_geral.accessUserCreateLeadShareTipoRule !== '') {
        let aAllTiposClienteInUse = []
        const aAllTiposClienteSelected = []

        let aAccessUserCreateLeadShareTipoRule = this.load_data_geral.accessUserCreateLeadShareTipoRule.split('#') || []
        aAccessUserCreateLeadShareTipoRule = aAccessUserCreateLeadShareTipoRule.filter(val => (val !== '' && val !== null))

        if (aAccessUserCreateLeadShareTipoRule.length > 0) {
          aAccessUserCreateLeadShareTipoRule.forEach(tipo => {
            const aTipoCliente = tipo.split(';') || []
            if (aTipoCliente.length > 0) {
              aTipoCliente.forEach(row => {
                aAllTiposClienteInUse.push(Number(row))
              })
            }
          })

          aAllTiposClienteInUse = aAllTiposClienteInUse.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
        }

        let vHaveComp = 0
        let vHaveVend = 0

        if (this.form_data.sw027s03.length > 0) {
          this.form_data.sw027s03.forEach(row => {
            if (row.sw022s01) {
              aAllTiposClienteSelected.push(Number(row.sw022s01))
            }
          })

          if (aAllTiposClienteSelected.includes(2) || aAllTiposClienteSelected.includes(4)) {
            vHaveComp += 1
          }

          if (aAllTiposClienteSelected.includes(1) || aAllTiposClienteSelected.includes(3)) {
            vHaveVend += 1
          }
        }

        if (
          (aAllTiposClienteInUse.includes(3))
            || (aAllTiposClienteInUse.includes(1) && aAllTiposClienteInUse.includes(2))
        ) {
          if ((vHaveComp === 0) || (vHaveVend === 0)) {
            if (vHaveComp === 0) {
              throw new Error(this.$t('Tem de escolher um tipo de cliente comprador (Arrendatário ou Comprador), porque existem responsáveis associados'))
            }

            if (vHaveVend === 0) {
              throw new Error(this.$t('Tem de escolher um tipo de cliente vendedor (Senhorio ou Vendedor), porque existem responsáveis associados'))
            }
          }
        } else {
          if (aAllTiposClienteInUse.includes(1)) {
            if (vHaveComp === 0) {
              throw new Error(this.$t('Tem de escolher um tipo de cliente comprador (Arrendatário ou Comprador), porque existem responsáveis associados'))
            }
          }

          if (aAllTiposClienteInUse.includes(2)) {
            if (vHaveVend === 0) {
              throw new Error(this.$t('Tem de escolher um tipo de cliente vendedor (Senhorio ou Vendedor), porque existem responsáveis associados'))
            }
          }
        }
      }

      // Verifica se este registo já se encontra duplicado
      await this.$store.dispatch('crm_leads_form/checkExistAllMyLeads', this.form_data).then(async res => {
        if (res.error === 1) {
          if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
            const actionModal = new Promise(resolve => {
              this.$root.$emit('crmLeadsForm::showMsgDetailRequest', { pIcon: 'warning', pDetail: res.alerts }, resolve)
            })

            await actionModal.then(result => {
              if (result === false) {
                throw new Error('')
              }
            })
          }
        }
      }).catch(error => {
        throw error
      })

      if (this.vars.executeActionShare === 1) {
        await this.shareLeadFormModal()
      } else if (this.vars.executeActionShare === 2) {
        await this.shareReferenceFormModal()
      } else if (this.vars.executeActionShare === '') {
        // Verifica se o email está preenchido
        if ((this.form_data !== undefined) && (this.form_data !== null) && ('sw025s06' in this.form_data) && (this.form_data.sw025s06 !== '')) {
          //
        } else {
          await this.$swal({
            title: `${this.$t('A sua base de dados é o seu negócio!')}`,
            html: `${this.$t('Não preencheu o email.')}<br>${this.$t('Tem a certeza que pretende continuar?')}`,
            icon: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Guardar sem email'),
            cancelButtonText: this.$t('Voltar'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',

            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value !== true) {
              throw new Error('')
            }
          })
        }

        await this.checkCampanhasAtiveByLead().then(async chooseModal => {
          if (chooseModal === true) {
            await this.getCampanhasAtiveModal()
          }
        }).catch(error => {
          throw error
        })

        await this.checkSaveCampanhasAdd().then(async chooseModal => {
          if (chooseModal === true) {
            await this.checkSaveCampanhasModalChoose()
          }
        }).catch(error => {
          throw error
        })
      }

      // Obtem a informacao da morada como nao existe em variavel reativa
      if (('sw025s90' in this.form_data) && (Number(this.form_data.sw025s90) === 1)) {
        //
      } else {
        if (this.$store.getters['auth/countryApp'] === 'pt') {
          await this.$refs.tabsFormData.$refs.tabContentFormMorada.$children[0].$refs.location.getDataSaveForm().then(async loc => {
            const fieldsLocalization = []
            fieldsLocalization.push({ field: 'sw025s19', value: loc.codigoPostal })
            fieldsLocalization.push({ field: 'sw025s20', value: loc.distrito })
            fieldsLocalization.push({ field: 'sw025s21', value: loc.concelho })
            fieldsLocalization.push({ field: 'sw025s22', value: loc.freguesia })
            fieldsLocalization.push({ field: 'sw025s78', value: loc.zona })
            fieldsLocalization.push({ field: 'sw025s79', value: loc.rua })
            fieldsLocalization.push({ field: 'sw025s88', value: loc.numeroPorta })
            fieldsLocalization.push({ field: 'sw025s89', value: loc.andar })
            fieldsLocalization.push({ field: 'sw025s23', value: loc.divisao })
            fieldsLocalization.push({ field: 'sw025s24', value: loc.ajudaMorada })
            fieldsLocalization.push({ field: 'sw025s108', value: loc.nomeEdificio })
            fieldsLocalization.push({ field: 'sw025s46', value: loc.ruaManual })
            await this.$store.dispatch('crm_leads_form/updateFieldValue', fieldsLocalization)
          })
        }

        if (this.$store.getters['auth/countryApp'] === 'es') {
          await this.$refs.tabsFormData.$refs.tabContentFormMorada.$children[0].$refs.location.getDataSaveForm().then(async loc => {
            const fieldsLocalization = []
            fieldsLocalization.push({ field: 'sw025s19', value: loc.codigoPostal })
            fieldsLocalization.push({ field: 'sw025s20', value: loc.comunidade })
            fieldsLocalization.push({ field: 'sw025s21', value: loc.provincia })
            fieldsLocalization.push({ field: 'sw025s22', value: loc.comarca })
            fieldsLocalization.push({ field: 'sw025s119', value: loc.municipio })
            fieldsLocalization.push({ field: 'sw025s78', value: loc.zona })
            fieldsLocalization.push({ field: 'sw025s88', value: loc.numeroPorta })
            fieldsLocalization.push({ field: 'sw025s89', value: loc.andar })
            fieldsLocalization.push({ field: 'sw025s23', value: loc.fracao })
            fieldsLocalization.push({ field: 'sw025s24', value: loc.ajudaMorada })
            fieldsLocalization.push({ field: 'sw025s108', value: loc.nomeEdificio })
            fieldsLocalization.push({ field: 'sw025s46', value: loc.ruaManual })
            await this.$store.dispatch('crm_leads_form/updateFieldValue', fieldsLocalization)
          })
        }
      }

      await this.$store.dispatch('crm_leads_form/save').then(async resSave => {
        if (resSave.save === true) {
          this.$root.$emit('app::loading', false)

          let txtMsgSuccess = resSave.msgSuccessSave || this.$t('Informação guardada com sucesso')

          if (this.vars.executeActionShare === 1) {
            txtMsgSuccess = this.$t('Informação guardada com sucesso e partilhada a Lead com o consultor')
          }

          if (this.vars.executeActionShare === 2) {
            txtMsgSuccess = this.$t('Informação guardada com sucesso e enviada a Lead com referência ao consultor')
          }

          await this.showMsgSuccessRequest({ message: txtMsgSuccess }).then(async () => {
            if (this.messageInfo !== '') {
              await this.showMsgSuccessRequest({ message: this.messageInfo }).then(async () => {
                await this.redirectPageCreateListing(resSave)
                this.redirectPageBack()
              })
            } else {
              await this.redirectPageCreateListing(resSave)
              this.redirectPageBack()
            }
          })
        }
      }).catch(error => {
        throw error
      })
    },

    async redirectPageCreateListing(result) {
      if (result.addNewListing === true) {
        await this.$router.push({ path: `/listings/new/${result.idUserCreateAng}/${result.idLead}` }).then(() => {
          this.$root.$emit('app::loading', false)
        })
      }
    },

    async cancelForm() {
      const self = this

      const actionModal = new Promise(resolve => {
        showModalMessage(resolve)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          self.redirectPageBack()
        }
      })
    },

    async redirectPageBack() {
      let urlPrevRoute = this.prevRoute.path
      if (urlPrevRoute === '/') {
        urlPrevRoute = '/dashboard'
      }

      this.$router.push({ path: urlPrevRoute }).then(() => {
        // this.$root.$emit('app::loading', false)
      })
    },

    async redirectPageToListview() {
      let urlPrevRoute = this.prevRoute.path
      if (urlPrevRoute === '/') {
        urlPrevRoute = '/dashboard'
      }

      if ((localStorage.getItem(this.$router.currentRoute.name) !== null) && (localStorage.getItem(this.$router.currentRoute.name) !== '')) {
        urlPrevRoute = localStorage.getItem(this.$router.currentRoute.name)
      }

      this.$router.replace({ path: urlPrevRoute }).then(() => {
        this.$root.$emit('app::loading', false)
      })
    },

    async getDataFormExtraCurrentUser() {
      const self = this
      const { tab } = this.paramsUrl

      if ((tab !== null) && (tab !== undefined)) {
        const currentUser = this.$store.getters['auth/currentUser']

        if (checkNested(currentUser, 'extras.basic') && (currentUser.extras.basic === 1)) {
          if (currentUser.id_role === 4) {
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s188', value: tab })
          }
        } else {
          await this.$store.dispatch('crm_leads_form/getListUsersTeam', { tabSelected: tab }).then(res => {
            if (res === true) {
              if (tab !== 'partilhadas') {
                this.$store.dispatch('crm_leads_form/updateFieldValue', {
                  field: 'separadorLead',
                  value: {
                    tab,
                    name: this.load_data_geral.teamNameTabSelected || '',
                  },
                })
              }

              // Verificar se so existe um consultor para ficar logo selecionado
              if (this.load_data_geral.teamUsersCreate.length === 1) {
                const userSelected = this.load_data_geral.teamUsersCreate.find(o => o.id !== undefined)
                this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'idConsultorShare', value: userSelected })
              }

              // Se for criado no tabulador "Leads do leader" nao mostra o bloco do responsavel
              if (tab !== 'leads_do_leader') {
                this.$store.dispatch('crm_leads_form/setShowBlockTeam', true)
              }
            } else {
              self.errorLoadingPage = true
              self.showMsgErrorRequest(res)
              self.$router.push({ path: this.prevRoute.path })
            }
          }).catch(async error => {
            self.errorLoadingPage = true
            self.showMsgErrorRequest(error)
            await self.redirectPageBack()
          })
        }
      }
    },

    async shareLeadForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        if ((this.$store.getters['crm_leads_form/keyReg'] !== '') && (this.$store.getters['crm_leads_form/formModeView'] === true)) {
          this.$root.$emit('app::loading', {
            status: true,
            zIndex: 5,
            txt: this.$t('Aguarde, a processar...'),
          })

          await this.shareLeadFormModal().then(() => {
            this.$root.$emit('app::loading', false)
          }).catch(async error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } else {
          await this.$store.dispatch('crm_leads_form/setIsSavingData', true)
          await self.$refs.tabsFormData.$refs.formSaveLeadValidator.validateWithInfo()
            .then(async ({
              isValid, errors,
            }) => {
              if (isValid) {
                this.$root.$emit('app::loading', {
                  status: true,
                  zIndex: 5,
                  txt: this.$t('Aguarde, a processar...'),
                })

                this.$store.commit('crm_leads_form/setExecuteActionShare', 1)

                await this.saveFormFinish()
              } else {
                throw errors
              }
            })
            .catch(async error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequestForm(error)
              await this.$store.dispatch('crm_leads_form/setIsSavingData', false)
            })
        }

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },

    async shareLeadFormModal() {
      let nameLead = ''

      if (checkNested(this.form_data, 'sw025s03')) {
        nameLead += ((nameLead !== '') ? ' ' : '')
        nameLead += this.form_data.sw025s03
      }

      if (checkNested(this.form_data, 'sw025s04')) {
        nameLead += ((nameLead !== '') ? ' ' : '')
        nameLead += this.form_data.sw025s04
      }

      let sKeyReg = ''
      if ((this.$store.getters['crm_leads_form/keyReg'] !== '') && (this.$store.getters['crm_leads_form/formModeView'] === true)) {
        sKeyReg = this.$store.getters['crm_leads_form/keyReg']
      }

      await this.$store.dispatch('crm_leads_events/chooseConsultorSendLead', { keyReg: sKeyReg, txt: '' }).then(async agents => {
        await this.$refs.modalShareLead.showModal({
          agents,
          nameLead,
          keyReg: sKeyReg,
        }).then(async resModal => {
          await this.$refs.modalShareLead.hideModal()
          if (resModal.share === true) {
            await this.showMsgSuccessRequest({ message: resModal.message })
          } else if (resModal.consultor !== '') {
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'idConsultorSend', value: resModal.consultor })
          }
        }).catch(error => {
          throw error
        })
      }).catch(error => {
        throw error
      })
    },

    async shareReferenceForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        if ((this.$store.getters['crm_leads_form/keyReg'] !== '') && (this.$store.getters['crm_leads_form/formModeView'] === true)) {
          this.$root.$emit('app::loading', {
            status: true,
            zIndex: 5,
            txt: this.$t('Aguarde, a processar...'),
          })

          await this.shareReferenceFormModal().then(() => {
            this.$root.$emit('app::loading', false)
          }).catch(async error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } else {
          await this.$store.dispatch('crm_leads_form/setIsSavingData', true)
          await self.$refs.tabsFormData.$refs.formSaveLeadValidator.validateWithInfo()
            .then(async ({
              isValid, errors,
            }) => {
              if (isValid) {
                this.$root.$emit('app::loading', {
                  status: true,
                  zIndex: 5,
                  txt: this.$t('Aguarde, a processar...'),
                })

                this.$store.commit('crm_leads_form/setExecuteActionShare', 2)

                await this.saveFormFinish()
              } else {
                throw errors
              }
            })
            .catch(async error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequestForm(error)
              await this.$store.dispatch('crm_leads_form/setIsSavingData', false)
            })
        }

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },

    async shareReferenceFormModal() {
      let nameLead = ''

      if (checkNested(this.form_data, 'sw025s03')) {
        nameLead += ((nameLead !== '') ? ' ' : '')
        nameLead += this.form_data.sw025s03
      }

      if (checkNested(this.form_data, 'sw025s04')) {
        nameLead += ((nameLead !== '') ? ' ' : '')
        nameLead += this.form_data.sw025s04
      }

      let sKeyReg = ''
      if ((this.$store.getters['crm_leads_form/keyReg'] !== '') && (this.$store.getters['crm_leads_form/formModeView'] === true)) {
        sKeyReg = this.$store.getters['crm_leads_form/keyReg']
      }

      await this.$store.dispatch('crm_leads_events/chooseConsultorSendRef', { keyReg: sKeyReg, txt: '' }).then(async res => {
        await this.$refs.modalShareReference.showModal({
          agents: res.agents,
          hubs: res.hubs,
          nameLead,
          keyReg: sKeyReg,
        }).then(async resModal => {
          await this.$refs.modalShareReference.hideModal()
          if (resModal.share === true) {
            await this.showMsgSuccessRequest({ message: resModal.message })

            if (resModal.messageInfo !== '') {
              await this.showMsgSuccessRequest({ message: resModal.messageInfo })
            }
          } else if (resModal.consultor !== '') {
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'idConsultorRef', value: resModal.consultor })
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'refValue', value: resModal.valornegocio })
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'refObservacao', value: resModal.observacoes })
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'refIntencao', value: resModal.intencaoRef })
            await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'refHUBOrigem', value: resModal.HUBOrigem })

            if (resModal.messageInfo !== '') {
              this.$store.commit('crm_leads_form/setMessageInfo', resModal.messageInfo)
            }
          }
        }).catch(error => {
          throw error
        })
      }).catch(error => {
        throw error
      })
    },

    editForm() {
      if (this.vars.sw025.sw025s01 !== '') {
        this.$router.push({ name: 'crm-leads-edit', params: { id: btoa(this.vars.sw025.sw025s01) } })
      }
    },
  },
  setup(props) {
    const NAMESPACE_SW025_FORM = 'crm_leads_form'
    const CRM_LEADS_MODULE_NAME_EVENTS = 'crm_leads_events'

    if (!store.hasModule(NAMESPACE_SW025_FORM)) {
      store.registerModule(NAMESPACE_SW025_FORM, crmLeadsFormModule)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM)) store.unregisterModule(NAMESPACE_SW025_FORM)
      })
    }

    if (!store.hasModule(CRM_LEADS_MODULE_NAME_EVENTS)) {
      store.registerModule(CRM_LEADS_MODULE_NAME_EVENTS, crmLeadsEventsModule)
    }

    const { route } = useRouter()
    const paramsUrl = route.value.params
    store.dispatch('crm_leads_form/setParamsUrl', paramsUrl)
    store.dispatch('crm_leads_form/setTypeAction', props.typeAction || '')

    if (props.contactToLead === true) {
      store.dispatch('crm_leads_form/setConvertContactToLead', props.contactToLead)
    } else {
      store.dispatch('crm_leads_form/setConvertContactToLead', false)
    }

    if (props.viewDetail === true) {
      store.dispatch('crm_leads_form/setModeViewDetail', 1)
    } else {
      store.dispatch('crm_leads_form/setModeViewDetail', '')
    }

    const SearchContactsDevice = () => import(/* webpackChunkName: "crm-leads-new-search-contacts-device" */ /* webpackPrefetch: true */ './form_data/device/searchContactsDevice.vue')
    const ModalChooseCampaign = () => import(/* webpackChunkName: "crm-leads-new-modal-campaign" */ /* webpackPrefetch: true */ './form_data/modal/chooseCampaign.vue')
    const ModalShareLead = () => import(/* webpackChunkName: "crm-leads-new-modal-share-lead" */ /* webpackPrefetch: true */ './form_data/modal/shareLead.vue')
    const ModalShareReference = () => import(/* webpackChunkName: "crm-leads-new-modal-share-reference" */ /* webpackPrefetch: true */ './form_data/modal/shareReference.vue')
    const ModalAtiveCampaign = () => import(/* webpackChunkName: "crm-leads-new-modal-campaign-ative" */ /* webpackPrefetch: true */ './form_data/modal/modalAtiveCampaign.vue')

    return {
      NAMESPACE_SW025_FORM,
      CRM_LEADS_MODULE_NAME_EVENTS,

      paramsUrl,
      SearchContactsDevice,
      ModalChooseCampaign,
      ModalShareLead,
      ModalShareReference,
      ModalAtiveCampaign,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
