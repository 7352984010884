<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true,
                   'sws-not-button-create': ( (
                     ((perms.disableButtonSave()===false) && (view===false)) ||
                     (view===true) ||
                     ((vars.typeActionForm === 'new-reference') && (perms.canSendReference()===true))
                   ) ? false : true),
          }"
        >
          <template
            v-if="(vars.typeActionForm === 'new-reference') && (perms.canSendReference()===true)"
          >
            <div
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                block
                :disabled="vars.isSavingData"
                class="sw-button-action"
                @click.stop.prevent="shareReferenceForm(); $emit('close-left-sidebar');"
              >
                <span class="align-middle">{{ $t('Enviar referência') }}</span>
              </b-button>
            </div>
          </template>
          <template v-else>
            <div
              v-if="(perms.disableButtonSave()===false) && (view===false)"
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                block
                :disabled="vars.isSavingData"
                class="sw-button-action"
                @click.stop.prevent="saveForm(); $emit('close-left-sidebar');"
              >
                <span
                  v-if="showButtonSaveCreateListing===true"
                  class="align-middle"
                >{{ $t('Guardar e criar angariação') }}</span>
                <span
                  v-else
                  class="align-middle"
                >{{ $t('Guardar') }}</span>
              </b-button>
            </div>
          </template>

          <div
            v-if="view===true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              variant="outline-info"
              block
              class="sw-button-action"
              @click.stop.prevent="redirectPageToListview(); $emit('close-left-sidebar');"
            >
              <span
                class="align-middle"
              >{{ $t('Voltar') }}</span>
            </b-button>
          </div>

          <vue-perfect-scrollbar
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <!-- Botoes acoes secundarias -->
            <b-list-group>

              <!-- Cancelar -->
              <b-list-group-item
                v-if="view===false"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="cancelForm(); $emit('close-left-sidebar')"
                >
                  <span class="align-middle">{{ $t('Cancelar') }}</span>
                </b-button>
              </b-list-group-item>

              <!-- Editar-->
              <b-list-group-item
                v-if="perms.canEdit()===true"
              >
                <b-button
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="outline-primary"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="editForm(); $emit('close-left-sidebar');"
                >
                  <span class="align-middle">{{ $t('Editar') }}</span>
                </b-button>
              </b-list-group-item>

              <!-- Importar do dispositivo -->
              <b-list-group-item
                v-if="(keyReg==='' && view===false && (showButtonImportContactsDevice === true))"
              >
                <b-button
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="outline-info"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="getContactsDevice(); $emit('close-left-sidebar')"
                >
                  <span class="align-middle">{{ $t('Importar do dispositivo') }}</span>
                </b-button>
              </b-list-group-item>

              <!-- Partilhar lead-->
              <b-list-group-item
                v-if="perms.canShareLead()===true"
              >
                <b-button
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="outline-info"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="shareLeadForm(); $emit('close-left-sidebar')"
                >
                  <span class="align-middle">{{ $t('Partilhar Lead') }}</span>
                </b-button>
              </b-list-group-item>

              <!-- Enviar referencia -->
              <b-list-group-item
                v-if="(perms.canSendReference()===true) && (vars.typeActionForm !== 'new-reference')"
              >
                <b-button
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="outline-info"
                  block
                  :disabled="vars.isSavingData"
                  class="sw-button-action"
                  @click.stop.prevent="shareReferenceForm(); $emit('close-left-sidebar')"
                >
                  <span class="align-middle">{{ $t('Enviar referência') }}</span>
                </b-button>
              </b-list-group-item>

            </b-list-group>

            <!-- Tabs de edição -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Perfil do Lead') }}
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="(tab, index) in getTabsItens.filter((oTab) => (oTab.visible === true && oTab.category==='perfil'))"
                :key="`tab-item-${index}`"
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === index) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=index; $emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="tab.icon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ tab.txt }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tabs de edição -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Seguimento') }}
            </h6>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="(tab, index) in getTabsItens.filter((oTab) => (oTab.visible === true && oTab.category==='seguimento'))"
                :key="`tab-item-${lastIndexBlockPerfil + index}`"
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === (lastIndexBlockPerfil + index)) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=(lastIndexBlockPerfil + index); $emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="tab.icon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ tab.txt }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tabs de edição -->
            <h6
              v-if="totalItensBlockAtividade > 0"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Atividade') }}
            </h6>
            <b-list-group
              v-if="totalItensBlockAtividade > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="(tab, index) in getTabsItens.filter((oTab) => (oTab.visible === true && oTab.category==='atividade'))"
                :key="`tab-item-${lastIndexBlockSeguimento + index}`"
                :class="{'cursor-pointer': true, 'active' : ((tabContentStepShow === (lastIndexBlockSeguimento + index)) ? true : false) }"
                @click.stop.prevent="tabContentStepShow=(lastIndexBlockSeguimento + index); $emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="tab.icon"
                  :size="sizeIconsAction"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ tab.txt }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Extra space -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  mixins: [sizeIconsAction],
  props: {
    saveForm: {
      type: Function,
      required: true,
    },
    editForm: {
      type: Function,
      required: true,
    },
    shareLeadForm: {
      type: Function,
      required: true,
    },
    shareReferenceForm: {
      type: Function,
      required: true,
    },
    cancelForm: {
      type: Function,
      required: true,
    },
    redirectPageBack: {
      type: Function,
      required: true,
    },
    redirectPageToListview: {
      type: Function,
      required: true,
    },
    getContactsDevice: {
      type: Function,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      tabContentStepIndex: 0,
    }
  },
  computed: {
    ...mapGetters('crm_leads_form', ['perms', 'form_data', 'vars', 'keyReg']),
    tabContentStepShow: {
      get() { return this.$store.getters['crm_leads_form/tabContentStepShow'] },
      set(newValue) {
        this.$store.commit('crm_leads_form/setTabContentStepShow', newValue)
      },
    },
    showButtonSaveCreateListing() {
      /*
      Neste momento na aplicação mobile não tem a criação de angariações
      try {
        // Só mostra o botão caso seja do tipo de utilizador "Consultor ou Leader"
        const currentUser = this.$store.getters['auth/currentUser']
        if ((currentUser !== null) && (currentUser !== undefined)) {
          if ((currentUser.id_role === 4) || (currentUser.id_role === 7)) {
            if ((this.form_data !== null) && (this.form_data !== undefined)) {
              if ('sw025s11' in this.form_data) {
                if (this.form_data.sw025s11 !== null) {
                  if (('value' in this.form_data.sw025s11) && (this.form_data.sw025s11.value === 1)) {
                    return true
                  }
                }
              }
            }
          }
        }
      } catch (err) {
        //
      }
      */

      return false
    },
    totalItensBlockPerfil() {
      return this.getTabsItens.filter(oTab => (oTab.visible === true && oTab.category === 'perfil')).length || 0
    },
    totalItensBlockSeguimento() {
      return this.getTabsItens.filter(oTab => (oTab.visible === true && oTab.category === 'seguimento')).length || 0
    },
    totalItensBlockAtividade() {
      return this.getTabsItens.filter(oTab => (oTab.visible === true && oTab.category === 'atividade')).length || 0
    },
    lastIndexBlockPerfil() {
      if (this.totalItensBlockPerfil > 0) {
        return this.totalItensBlockPerfil
      }

      return 0
    },
    lastIndexBlockSeguimento() {
      let lastIndex = 0

      if (this.totalItensBlockPerfil > 0) {
        lastIndex += this.totalItensBlockPerfil
      }

      if (this.totalItensBlockSeguimento > 0) {
        lastIndex += this.totalItensBlockSeguimento
      }

      return lastIndex
    },
    getTabsItens() {
      const { t } = useI18nUtils()

      const aTabItens = []

      // Geral
      aTabItens.push({
        txt: t('Geral'),
        icon: 'UserIcon',
        visible: true,
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Dados'),
        icon: 'BookOpenIcon',
        visible: true,
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Contactos'),
        icon: 'PhoneCallIcon',
        visible: true,
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Qualificação comprador'),
        icon: 'TagIcon',
        visible: this.perms.showTabQualifComprador(),
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Qualificação vendedor'),
        icon: 'TagIcon',
        visible: this.perms.showTabQualifVendedor(),
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Imóveis de interesse'),
        icon: 'HomeIcon',
        visible: this.perms.showTabImoveisInt(),
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Família'),
        icon: 'UsersIcon',
        visible: true,
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Morada'),
        icon: 'MapPinIcon',
        visible: true,
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Imóveis angariados'),
        icon: 'HomeIcon',
        visible: this.perms.showTabImoveisAngOrPreAng(),
        category: 'perfil',
      })
      aTabItens.push({
        txt: t('Imóveis pré-angariados'),
        icon: 'HomeIcon',
        visible: this.perms.showTabImoveisAngOrPreAng(),
        category: 'perfil',
      })

      // Seguimento
      aTabItens.push({
        txt: t('Seguimento'),
        icon: 'BookIcon',
        visible: true,
        category: 'seguimento',
      })
      aTabItens.push({
        txt: t('Notas'),
        icon: 'BookOpenIcon',
        visible: true,
        category: 'seguimento',
      })
      /*
      aTabItens.push({
        txt: t('Observações'),
        icon: 'MessageSquareIcon',
        visible: true,
        category: 'seguimento',
      })
      */

      // Historico
      aTabItens.push({
        txt: t('Registo'),
        icon: 'ListIcon',
        visible: this.perms.isRecord(),
        category: 'atividade',
      })
      aTabItens.push({
        txt: t('Consentimento'),
        icon: 'ListIcon',
        visible: this.perms.isRecord(),
        category: 'atividade',
      })

      return aTabItens
    },
    showButtonImportContactsDevice() {
      if (this.$store.state.capacitor.isNative === true) {
        return true
      }
      if (process.env.VUE_APP_SHOW_ACTION_DEVICE === 'true') {
        return true
      }

      return false
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list{
  height: calc(100%);
}
</style>
