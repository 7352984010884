import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {},
  getters: {
  },
  mutations: {
  },
  actions: {

    async searchLeadsByUserAuth({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload)

      await dispatch('request/cancelTokensPendingByUrl', 'leads/searchLeadsByUserAuth', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/searchLeadsByUserAuth`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os Leads')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os Leads')))
            }
          })
      })
    },

    async searchLeadsByUserAuthEncode({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload)

      await dispatch('request/cancelTokensPendingByUrl', 'leads/searchLeadsByUserAuthEncode', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/searchLeadsByUserAuthEncode`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os Leads')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os Leads')))
            }
          })
      })
    },

    async searchContactosByUserAuth({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload)

      await dispatch('request/cancelTokensPendingByUrl', 'leads/searchContactosByUser', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/searchContactosByUser`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os contactos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os contactos')))
            }
          })
      })
    },

    async getAllGrausParentesco({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('sw024s03', payload || '')

      await dispatch('request/cancelTokensPendingByUrl', 'leads/getGrauParentesco', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/getGrauParentesco`, formData)
          .then(response => {
            if (typeof response.data.graus === 'object') {
              resolve(response.data.graus)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os graus de parentesco')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os graus de parentesco')))
            }
          })
      })
    },

    async searchLeadsContactoByUserAuth({ dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload.text || '')
      formData.append('sw025s01', payload.sw025s01 || '')

      await dispatch('request/cancelTokensPendingByUrl', 'leads/searchLeadsContactoByUserAuth', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/searchLeadsContactoByUserAuth`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os Leads / Contactos')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os Leads / Contactos')))
            }
          })
      })
    },

  },
}
