<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">
          <footer-step-nav ref="footerStepNav" />
        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((data.showListviewFilter === true) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Imóveis pré-angariados') }}
              </h4>
            </div>

          </div>
        </div>

        <div
          v-if="data.showListviewFilter === true"
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTxt"
                value=""
                :placeholder="txt.searchPlaceholder"
              />
            </b-input-group>
          </div>
          <div
            v-if="fieldsOrder.length > 0"
            class="dropdown"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  :size="sizeIconsAction"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-for="oField in fieldsOrder"
                :key="`field-order-sw031-${oField.name}`"
                @click="listviewFilterOrderBy(oField.name)"
              >
                <feather-icon
                  :icon="listviewIconOrderBy(oField.name)"
                  :class="listviewIconOrderBy(oField.name, true)"
                />
                {{ oField.txt }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div class="ecommerce-application">
          <div
            v-if="data.showListview() === true"
            class="grid-view m-0 p-1"
          >
            <preview-Item
              v-for="(sw031, index) in listviewData"
              :key="sw031.id"
              :listing="{
                pid: sw031.pid,
                photo: sw031.imagem,
                price: sw031.preco,
                tipologia: sw031.tipologia,
                nickname: sw031.nickname,
                status: [{
                  id: sw031.estado,
                  txt: sw031.estado_txt
                }],
                agent: sw031.agent
              }"
              :index="index"
            >
              <template #blockActions>
                <div
                  class="item-options text-center"
                >
                  <b-button
                    variant="primary"
                    tag="div"
                    class="btn-cart"
                    :style="{cursor: 'default' }"
                  >
                    <span v-if="sw031.pid">{{ sw031.pid }}</span>
                    <span v-else>{{ $t('Sem ZMID') }}</span>
                  </b-button>
                </div>
              </template>
            </preview-Item>
          </div>
          <div
            v-if="listviewData.length === 0"
            class="no-results text-center p-2"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
              {{ txt.notFind }}
            </h5>
            <h5 v-if="data.init === 1">
              {{ txt.loading }}
            </h5>
            <b-alert
              v-if="(data.totalFieldsFilter === 0) && (data.init === 0)"
              variant="primary"
              show
            >
              <div class="alert-body">
                <span>{{ $t('Nenhum imóvel encontrado') }}</span>
              </div>
            </b-alert>
          </div>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormInput, BContainer, BButton, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BOverlay, VBTooltip, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import modelSW031 from '@store-modules/crm/leads/sw031'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BContainer,
    BButton,
    BPagination,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ '../footerStepNavTop.vue'),
    PreviewItem: () => import(/* webpackChunkName: "crm-leads-new-preview-listing" */ '@/views/modules/components/cardListingSimple.vue'),
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
    }
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['perms']),
    ...mapGetters('sw031_PreAngariados', ['listviewData', 'data', 'txt', 'fieldsOrder', 'showFormCreateNew']),
    searchTxt: {
      get() { return this.$store.getters['sw031_PreAngariados/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('sw031_PreAngariados/setSearchTxt', { txt: newValue }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['sw031_PreAngariados/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('sw031_PreAngariados/setCurrentPage', { page: newValue }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  methods: {
    async listviewFilterOrderBy(fieldSort) {
      this.$store.dispatch('sw031_PreAngariados/listviewFilterOrderBy', { field: fieldSort }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        if (this.perms.showTabImoveisAngOrPreAng() === true) {
          await this.$store.dispatch('sw031_PreAngariados/setTypeList', 'pre-angariados')
          await this.$store.dispatch('sw031_PreAngariados/loadListviewData', { init: true }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
  },
  setup() {
    const MODEL_SW031 = 'sw031_PreAngariados'

    if (!store.hasModule(MODEL_SW031)) {
      store.registerModule(MODEL_SW031, modelSW031)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW031)) store.unregisterModule(MODEL_SW031)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
