<template>
  <div
    :class="{'mb-2' : modeView }"
  >

    <div
      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between"
      :class="{'mb-1 mb-sm-0': !modeView, 'mb-1': modeView }"
    >
      <h6 v-if="typeof row.extra !== 'undefined' && row.extra !== null">{{ row.extra.txtDate }}
        <template v-if="row.extra.txtDateExtra !== ''">
          <small>{{ row.extra.txtDateExtra }}</small>
        </template>
      </h6>
      <small
        v-if="(typeof row.extra !== 'undefined' && row.extra.strAbbrState!=='') && (Number(row.sw030s10) !== 1)"
        :class="[colorStatus(row)]"
      >
        {{ row.extra.strAbbrState }}
        <template v-if="(Number(row.sw030s09) === 1) && (row.sw030s11 !== '')">
          {{ '\xa0' }}|{{ '\xa0' }}{{ formateDate(row.sw030s11) }}
        </template>
      </small>
    </div>

    <p>
      <template v-if="row.sw090s02 !== ''">
        <b>{{ row.sw090s02 }}:{{ '\xa0' }}</b>
      </template>
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="formatTxt(row.sw030s05)" />
      <!--eslint-enable-->
    </p>

    <div
      v-if="(withNameLead === true) && (row.nome_lead !== '')"
      class="d-flex flex-sm-row flex-column justify-content-between align-items-start mb-1"
    >
      <b-media>
        <template #aside>
          <b-avatar :src="row.photoLead" />
        </template>
        <h6>{{ row.nome_lead }}</h6>
        <span
          v-if="row.typeLead!==''"
          class="text-muted"
        >{{ row.typeLead }}</span>
      </b-media>
    </div>

    <div
      v-if="((row.nomeResponsavel !== '') && (Number(row.mostraNomeResp) === 1))"
      class="d-flex flex-sm-row flex-column justify-content-between align-items-start mb-1"
    >
      <b-media>
        <template #aside>
          <b-avatar :src="row.photoResponsavel" />
        </template>
        <h6>{{ row.nomeResponsavel }}</h6>
        <span class="text-muted">{{ $t('Responsável') }}</span>
      </b-media>
    </div>

    <b-button
      v-if="showButtonView===true"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      size="sm"
      variant="outline-primary"
      @click.stop.prevent="viewSeguimento(row)"
    >
      {{ $t('Mais ações') }}
    </b-button>

  </div>
</template>

<script>
import {
  BMedia, BAvatar, BButton,
} from 'bootstrap-vue'
import checkNested from 'check-nested'
import Ripple from 'vue-ripple-directive'
import { format, parseISO } from 'date-fns'

export default {
  components: {
    BMedia,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    withNameLead: {
      type: Boolean,
      default: false,
      required: false,
    },
    viewSeguimento: {
      type: Function,
      default: () => {},
      required: false,
    },
    showButtonView: {
      type: Boolean,
      default: true,
      required: false,
    },
    modeView: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    formatTxt(txt) {
      return txt.replace(/\n/g, '<br />')
    },
    colorStatus(row) {
      if ((checkNested(row, 'extra.classElm')) && (row.extra.classElm === 'dateOld')) {
        return 'text-danger'
      }

      if ((checkNested(row, 'extra.classElm')) && (row.extra.classElm === 'regNaoEfetuado')) {
        return 'text-danger'
      }

      if ((checkNested(row, 'extra.classElm')) && (row.extra.classElm === 'now')) {
        return 'text-danger'
      }

      if ((checkNested(row, 'extra.classElm')) && (row.extra.classElm === 'next')) {
        return 'text-success'
      }

      if ((checkNested(row, 'extra.classElm')) && (row.extra.classElm === 'regEfetuado')) {
        return 'text-success'
      }

      return 'text-muted'
    },
    formateDate(date) {
      return `${format(parseISO(date), 'yyyy-MM-dd')} ${format(parseISO(date), 'HH')}h${format(parseISO(date), 'mm')}`
    },
  },
}
</script>
