<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="formModeView===false"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="createNewSW372"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content email-user-list"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((data.showListviewFilter === true) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Qualificação como comprador') }}
              </h4>
            </div>
            <div
              v-if="formModeView===false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click.stop.prevent="createNewSW372"
              >
                <span class="align-middle">{{ $t('Adicionar') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <div
          v-if="data.showListviewFilter === true"
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTxt"
                value=""
                :placeholder="txt.searchPlaceholder"
              />
            </b-input-group>
          </div>
          <div
            v-if="fieldsOrder.length > 0"
            class="dropdown"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  :size="sizeIconsAction"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-for="oField in fieldsOrder"
                :key="`field-order-sw372-${oField.name}`"
                @click="listviewFilterOrderBy(oField.name)"
              >
                <feather-icon
                  :icon="listviewIconOrderBy(oField.name)"
                  :class="listviewIconOrderBy(oField.name, true)"
                />
                {{ oField.txt }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <ul
          v-if="data.showListview() === true"
          class="email-media-list overflow-hidden"
        >
          <b-media
            v-for="(sw372, index) in listviewData"
            :key="sw372.id"
            tag="li"
            no-body
          >
            <b-media-body>
              <div
                v-if="sw372.tipoImovel"
                class="mail-message"
              >
                <p class="mb-0">
                  <b>{{ $t('Tipo') }}:&nbsp;</b>
                  {{ sw372.tipoImovel }}
                </p>
              </div>
              <div
                v-if="sw372.imovel"
                class="mail-message"
              >
                <p class="mb-0">
                  <b>{{ $t('Imóvel') }}:&nbsp;</b>
                  {{ sw372.imovel }}
                </p>
              </div>
              <div
                v-if="sw372.tipologias"
                class="mail-message"
              >
                <p class="mb-0">
                  <b>{{ $t('Tipologias') }}:&nbsp;</b>
                  {{ sw372.tipologias }}
                </p>
              </div>
              <div
                v-if="sw372.intervalo_precos"
                class="mail-message"
              >
                <p class="mb-0">
                  <b>{{ $t('Intervalo de preço') }}:&nbsp;</b>
                  {{ sw372.intervalo_precos }}
                </p>
              </div>
              <div
                v-if="sw372.loc_level1"
                class="mail-message"
              >
                <p class="mb-0">
                  <b v-if="$store.getters['auth/countryApp']==='pt'">{{ $t('Distrito') }}:&nbsp;</b>
                  <b v-if="$store.getters['auth/countryApp']==='es'">{{ $t('Comunidad Autónoma') }}:&nbsp;</b>
                  {{ sw372.loc_level1 }}
                </p>
              </div>
              <div
                v-if="sw372.loc_level2"
                class="mail-message"
              >
                <p class="mb-0">
                  <b v-if="$store.getters['auth/countryApp']==='pt'">{{ $t('Concelho') }}:&nbsp;</b>
                  <b v-if="$store.getters['auth/countryApp']==='es'">{{ $t('Provincia') }}:&nbsp;</b>
                  {{ sw372.loc_level2 }}
                </p>
              </div>
              <div
                v-if="sw372.loc_level3"
                class="mail-message"
              >
                <p class="mb-0">
                  <b v-if="$store.getters['auth/countryApp']==='pt'">{{ $t('Freguesia') }}:&nbsp;</b>
                  <b v-if="$store.getters['auth/countryApp']==='es'">{{ $t('Comarca') }}:&nbsp;</b>
                  {{ sw372.loc_level3 }}
                </p>
              </div>

              <div
                v-if="!sw372.tipoImovel && !sw372.imovel && !sw372.tipologias && !sw372.intervalo_precos && !sw372.loc_level1 && !sw372.loc_level2 && !sw372.loc_level3"
                class="mail-message"
              >
                <p class="mb-0">
                  <b>{{ $t('Sem qualificação disponível') }}</b>
                </p>
              </div>

              <div class="mail-message mt-2 text-center text-sm-left">
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    @click.stop.prevent="viewFormSW372(sw372, index)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Visualizar') }}</span>
                  </b-button>
                  <b-button
                    v-if="sw372.can_edit===true"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    @click.stop.prevent="editFormSW372(sw372, index)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Editar') }}</span>
                  </b-button>
                  <b-button
                    v-if="sw372.can_delete===true"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    @click.stop.prevent="deleteFormSW372(sw372, index)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Eliminar') }}</span>
                  </b-button>
                </b-button-group>
              </div>

            </b-media-body>
          </b-media>
        </ul>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(formModeView===true) && (data.totalFieldsFilter===0) && (data.init === 0)"
            class="mb-0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

      <form-create-new-sw372
        v-if="showFormCreateNew===true"
        v-show="loadingFormCreateNew===false"
        ref="modalFormSW372"
        :data="loadSW372"
      />

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormInput, BContainer, BButton, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BMedia, BMediaBody, BOverlay, VBTooltip, BButtonGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { showModalDeleteMessage } from '@core/utils/utils'
import { onUnmounted } from '@vue/composition-api'
import modelSW372 from '@store-modules/crm/leads/sw372'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BContainer,
    BButton,
    BPagination,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BMediaBody,
    BOverlay,
    BAlert,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ '../footerStepNavTop.vue'),
    BButtonGroup,
    FormCreateNewSw372: () => import(/* webpackChunkName: "crm-leads-new-sw372-form" */ /* webpackPrefetch: true */ './form.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      loadSW372: {},
    }
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['perms', 'formModeView', 'form_data', 'load_data_geral']),
    ...mapGetters('sw372', ['listviewData', 'data', 'txt', 'fieldsOrder', 'showFormCreateNew', 'loadingFormCreateNew']),
    searchTxt: {
      get() { return this.$store.getters['sw372/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('sw372/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['sw372/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('sw372/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  methods: {
    async listviewFilterOrderBy(field) {
      this.$store.dispatch('sw372/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        if (this.perms.showTabQualifComprador() === true) {
          await this.$store.dispatch('sw372/loadListviewData', { init: true }).then(async () => {
            await this.updateEstadoComprador()
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async createNewSW372() {
      this.loadSW372 = {}
      this.$store.dispatch('sw372/createNewSW372')
    },
    async deleteFormSW372(row, pIndex) {
      const self = this

      try {
        if (row.id) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar a qualificação?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)
              this.$store.dispatch('sw372/deleteSW372', {
                sw372s01: row.id,
                index: pIndex,
              }).then(async res => {
                self.$root.$emit('app::loading', false)
                self.showMsgSuccessRequest(res)
                await this.updateEstadoComprador(true)
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
      //
      }
    },
    async editFormSW372(row, pIndex) {
      const self = this

      try {
        if (row.id) {
          self.$root.$emit('app::loading', true)
          await this.$store.dispatch('sw372/editSW372', {
            sw372s01: row.id,
            index: pIndex,
          }).then(res => {
            self.loadSW372 = {}
            self.loadSW372 = res
            self.$store.dispatch('sw372/setFormModeView', false)
            self.$store.dispatch('sw372/setLoadingDataSW372', true)
            self.$store.dispatch('sw372/setShowFormCreateNew', true)
          }).catch(error => {
            self.$root.$emit('app::loading', false)
            self.$store.dispatch('sw372/setFormModeView', false)
            self.$store.dispatch('sw372/setLoadingDataSW372', false)
            self.$store.dispatch('sw372/setShowFormCreateNew', false)
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
      //
      }
    },
    async viewFormSW372(row, pIndex) {
      const self = this

      try {
        if (row.id) {
          self.$root.$emit('app::loading', true)
          await this.$store.dispatch('sw372/editSW372', {
            sw372s01: row.id,
            index: pIndex,
          }).then(res => {
            self.loadSW372 = {}
            self.loadSW372 = res
            self.$store.dispatch('sw372/setFormModeView', true)
            self.$store.dispatch('sw372/setLoadingDataSW372', true)
            self.$store.dispatch('sw372/setShowFormCreateNew', true)
          }).catch(error => {
            self.$root.$emit('app::loading', false)
            self.$store.dispatch('sw372/setFormModeView', false)
            self.$store.dispatch('sw372/setLoadingDataSW372', false)
            self.$store.dispatch('sw372/setShowFormCreateNew', false)
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
      //
      }
    },
    async updateEstadoComprador(confirm = false) {
      if (confirm === true) {
        if ((this.form_data !== null) && (this.form_data !== undefined) && ('sw025s07' in this.form_data)) {
          if (this.listviewData.length > 0) {
            if (('nivel' in this.form_data.sw025s07) && (Number(this.form_data.sw025s07.nivel) <= 2)) {
              this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: this.load_data_geral.estadosLeadComprador.find(o => Number(o.id) === 2) })
              this.$store.commit('crm_leads_form/setNivelBlockEstadoComprador', 2)
            } else {
              this.$store.commit('crm_leads_form/setNivelBlockEstadoComprador', 2)
            }
          } else {
            this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: this.load_data_geral.estadosLeadComprador.find(o => Number(o.id) === 1) })
            this.$store.commit('crm_leads_form/setNivelBlockEstadoComprador', 0)
          }
        } else {
          this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: this.load_data_geral.estadosLeadComprador.find(o => Number(o.id) === 1) })
          this.$store.commit('crm_leads_form/setNivelBlockEstadoComprador', 0)
        }
      }
    },
  },
  setup() {
    const MODEL_SW372 = 'sw372'

    if (!store.hasModule(MODEL_SW372)) {
      store.registerModule(MODEL_SW372, modelSW372)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW372)) store.unregisterModule(MODEL_SW372)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
