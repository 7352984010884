<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">
          <footer-step-nav ref="footerStepNav" />
        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Morada') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row v-if="formModeView===false">
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw025s90"
                  value="1"
                  @change="clearFieldsLocOther"
                >
                  {{ $t('Estrangeiro') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">

              <!-- Morada estrangeiro -->
              <b-row v-if="form_data.sw025s90 === '1'">
                <b-col md="12">
                  <b-row
                    v-if="formModeView===true"
                  >
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Estrangeiro')"
                      >
                        <p class="text-primary mb-0">
                          {{ $t('Sim') }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('País')"
                      >
                        <v-select
                          v-if="formModeView===false"
                          v-model="form_data.sw025s91"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="load_data_geral.countries"
                          :value="form_data.sw025s91"
                          append-to-body
                          :calculate-position="withPopper"
                          label="txt"
                          item-text="txt"
                          item-value="id"
                        >
                          <template #option="{ txt }">
                            {{ txt }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                        <p
                          v-else
                          class="text-primary mb-0"
                        >
                          {{ form_data.sw025s91 | getTxt }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Morada')"
                      >
                        <b-form-textarea
                          v-if="formModeView===false"
                          v-model="form_data.sw025s92"
                          rows="3"
                          no-resize
                        />
                        <!-- eslint-disable vue/no-v-html -->
                        <p
                          v-else
                          class="text-primary mb-0"
                          :inner-html.prop="renderHtmlToTxt(form_data.sw025s92)"
                        />
                        <!--eslint-enable-->
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Morada completa pais base -->
              <b-row
                v-if="(formModeView===true) && (form_data.sw025s90 !== '1')"
              >
                <b-col md="12">
                  <b-form-group
                    :label="$t('Estrangeiro')"
                  >
                    <p class="text-primary mb-0">
                      {{ $t('Não') }}
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="form_data.sw025s90 !== '1'">
                <b-col md="12">
                  <component
                    :is="blockLocalization"
                    ref="location"
                    :view="formModeView"
                    :fill-maps-lat-long="fillMapsLatLong"
                  />
                </b-col>
              </b-row>

            </b-col>
            <b-col md="6">
              <google-map
                v-if="form_data.sw025s90 !== '1'"
                ref="googleMaps"
                :center="mapsLatLong"
                :zoom="15"
                map-type-id="roadmap"
                class="vue-google-maps"
                :options="{
                  keyboardShortcuts: false,
                  gestureHandling:'cooperative',
                  rotateControl: false,
                  tilt:0,
                }"
              >
                <google-map-marker
                  :position="mapsLatLong"
                  :clickable="((formModeView === true) ? false : true)"
                  :draggable="((formModeView === true) ? false : true)"
                  @dragend="updateDragMapsLatLong"
                />
              </google-map>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormGroup, BContainer, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import vSelect from 'vue-select'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_GOOGLE_MAPS,
    region: store.getters['auth/countryApp'],
    language: store.getters['auth/countryApp'],
  },
  installComponents: false,
})

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BContainer,
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    'google-map': VueGoogleMaps.Map,
    'google-map-marker': VueGoogleMaps.Marker,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  computed:
    {
      ...mapGetters('crm_leads_form', ['form_data', 'load_data_geral', 'formModeView']),
      mapsLatLong() {
        try {
          if ((this.form_data.sw025s25 !== null) && (this.form_data.sw025s25 !== undefined) && (this.form_data.sw025s25 !== '')) {
            const [sLat, sLong] = this.form_data.sw025s25.split('###')
            return {
              lat: Number(sLat),
              lng: Number(sLong),
            }
          }

          return {
            lat: Number(0),
            lng: Number(0),
          }
        } catch (err) {
          return {
            lat: Number(0),
            lng: Number(0),
          }
        }
      },
    },
  methods: {
    async fillMapsLatLong() {
      await this.$gmapApiPromiseLazy().then(res => {
        const geocoder = new res.maps.Geocoder()
        let address = ''

        if (typeof this.$refs.location.getNameSearchGoogleMaps() !== 'undefined') {
          address = this.$refs.location.getNameSearchGoogleMaps()
        }

        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            const latitude = results[0].geometry.location.lat()
            const longitude = results[0].geometry.location.lng()
            this.$store.dispatch('crm_leads_form/setMapLatLong', { lat: latitude, lng: longitude })
          }
        })
      }).catch(() => {
        this.$store.dispatch('crm_leads_form/setMapLatLong', { lat: '', lng: '' })
      })
    },
    updateDragMapsLatLong(location) {
      try {
        if (location.latLng.lat() && location.latLng.lng()) {
          this.$store.dispatch('crm_leads_form/setMapLatLong', { lat: location.latLng.lat(), lng: location.latLng.lng() })
        } else {
          this.$store.dispatch('crm_leads_form/setMapLatLong', { lat: '', lng: '' })
        }
      } catch (err) {
        this.$store.commit('crm_leads_form/setMapLatLong', { lat: '', lng: '' })
      }
    },
    async clearFieldsLocOther() {
      if (this.form_data.sw025s90 === false) {
        await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s91', value: null })
        await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s92', value: null })
      } else {
        await this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s25', value: null })
        await this.$refs.location.clearForm()
      }
    },
  },
  setup() {
    const countryApp = store.getters['auth/countryApp']
    const blockLocalization = () => import(`@/views/modules/components/localization/country/form/${countryApp}.vue`)

    return {
      blockLocalization,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.vue-google-maps {
  width: 100%;
  height: 100vh;
}
</style>
