<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="formModeView===false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="$store.dispatch('crm_leads_form/addNewContactAdditional')"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <b-button
            v-if="(formModeView===false) && (keyReg==='') && (showButtonImportContactsDevice === true)"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
            @click.stop.prevent="getContactsDevice"
          >
            <feather-icon
              icon="SmartphoneIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Contactos') }}
              </h4>
            </div>

          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="{numberVueTelInput: numberPhoneValidate }"
                mode="eager"
                :name="$t('Telemóvel') | lowerBold"
              >
                <b-form-group
                  :label="$t('Telemóvel')"
                >
                  <vue-tel-input
                    v-if="formModeView===false"
                    ref="sw025s05"
                    v-model="form_data.sw025s05"
                    class="form-control"
                    inputmode="tel"
                    :mode="configPhone.mode"
                    :valid-characters-only="configPhone.validCharactersOnly"
                    :auto-format="configPhone.autoFormat"
                    :default-country="configPhone.defaultCountry"
                    :auto-default-country="configPhone.autoDefaultCountry"
                    :input-options="configPhone.inputOptions"
                    :dropdown-options="configPhone.dropdownOptions"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    @input="checkValidateNumberPhone"
                    @open="onDropdownOpenTelInput"
                    @keydown.native.tab.prevent="$event.target.blur()"
                    @blur.native.capture.prevent="() => {
                      inputValidateNumberPhone($refs.sw025s05);
                      checkPhoneExist($event)
                    }"
                  >
                    <template slot="arrow-icon">
                      {{ '\xa0' }}
                    </template>
                    <template slot="icon-right">
                      {{ '\xa0' }}
                    </template>
                  </vue-tel-input>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    <template v-if="form_data.sw025s05">
                      <b-link :href="`tel:${form_data.sw025s05}`">
                        {{ form_data.sw025s05 }}
                      </b-link>
                    </template>
                    <template v-else>
                      -----
                    </template>
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="email"
                :name="$t('email') | lowerBold"
              >
                <b-form-group
                  :label="$t('Email')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    ref="email"
                    v-model="form_data.sw025s06"
                    inputmode="email"
                    placeholder=""
                    autocomplete="off"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    @blur.native.capture.prevent="checkEmailExist"
                    @keydown.native.tab.prevent="$event.target.blur()"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    <template v-if="form_data.sw025s06">
                      <b-link :href="`mailto:${form_data.sw025s06}`">
                        {{ form_data.sw025s06 }}
                      </b-link>
                    </template>
                    <template v-else>
                      -----
                    </template>
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-container>

        <div
          v-if="(formModeView===false) || ((formModeView===true) && (form_sw095.length > 0))"
          class="app-fixed-search d-flex align-items-center border-top-theme"
          :class="{'border-bottom-0' : ((form_sw095.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Contactos Adicionais') }}
              </h4>
            </div>
            <div
              v-if="formModeView===false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-button
                variant="outline-primary"
                @click.stop.prevent="$store.dispatch('crm_leads_form/addNewContactAdditional')"
              >
                <span class="align-middle">{{ $t('Adicionar') }}</span>
              </b-button>
            </div>

          </div>
        </div>

        <b-container
          v-if="form_sw095.length > 0"
          class="p-2"
          fluid
        >

          <b-row
            v-for="(sw095, index) in form_sw095"
            :key="`contacto-adicional-item-${index}`"
          >
            <b-col
              v-if="index > 0"
              cols="12"
            >
              <hr>
            </b-col>
            <b-col
              v-if="formModeView===false"
              md="4"
            >
              <b-form-group
                :label="$t('Tipo')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="sw095.sw095s03"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.tiposContactosAdicionais"
                  append-to-body
                  :calculate-position="withPopper"
                  label="txt"
                  item-text="txt"
                  item-value="id"
                  :placeholder="$t('Escolha um tipo')"
                  @input="loadTypeFormContact($event, index)"
                  @change="loadTypeFormContact($event, index)"
                >
                  <template #option="{ txt }">
                    {{ txt }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ sw095.sw095s03 | getTxt }}
                </p>
              </b-form-group>
            </b-col>
            <b-col
              v-if="sw095.sw095s03!==null"
              md="8"
            >
              <component
                :is="sw095.template"
                :index="index"
                :view="formModeView"
                :remove-contact-additional="removeContactAdditional"
              />
            </b-col>
            <b-col
              v-if="(sw095.sw095s03===null) && (formModeView === false)"
              md="8"
            >
              <div
                class="d-none d-sm-block"
                style="margin-top: 1.90rem"
              />
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click.stop.prevent="removeContactAdditional(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ $t('Eliminar') }}</span>
              </b-button>
            </b-col>

          </b-row>

          <b-row v-if="form_sw095.length===0">
            <b-col cols="12">
              <h5 class="text-center">
                {{ $t('Nenhum contacto adicional disponível') }}
              </h5>
            </b-col>
          </b-row>

        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BContainer, BButton, BFormInput, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import vSelect from 'vue-select'
import { configPhone } from '@core-custom/utils/phone'
import { numberVueTelInput } from '@validations-custom'
import { ValidationProvider } from 'vee-validate'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral, mixEventsInputPhone, mixValidateContactsForm } from '../mixins'

const oVueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BContainer,
    BButton,
    BFormInput,
    BLink,
    ValidationProvider,
    VueTelInput: oVueTelInput,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral, mixEventsInputPhone, mixValidateContactsForm],
  data() {
    return {
      configPhone,
      numberVueTelInput,
    }
  },
  computed:
      {
        ...mapGetters('crm_leads_form', ['load_data_geral', 'form_data', 'form_sw095', 'vars', 'formModeView', 'keyReg']),
        ...mapActions('crm_leads_form', ['addNewContactAdditional']),
        numberPhoneValidate() {
          try {
            if (this.form_data.sw025s05) {
              return this.$refs.sw025s05.phoneObject
            }
          } catch (err) {
            //
          }

          return null
        },
        showButtonImportContactsDevice() {
          if (this.$store.state.capacitor.isNative === true) {
            return true
          }
          if (process.env.VUE_APP_SHOW_ACTION_DEVICE === 'true') {
            return true
          }

          return false
        },
      },
  methods: {
    async removeContactAdditional(index) {
      const self = this

      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, this.$t('Pretende eliminar este contacto?'))
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          self.removeContactAdditionalConfirm(index)
        }
      })
    },
    removeContactAdditionalConfirm(index) {
      this.$store.commit('crm_leads_form/removeContactAdditional', index)
    },
    loadTypeFormContact(event, index) {
      try {
        if ((event !== null) && (event !== undefined) && ('id' in event)) {
          const templateContact = this.vars.templatesContactosAdicionais.find(o => Number(o.id) === Number(event.id))

          if ((templateContact !== null) && (templateContact !== undefined) && ('template' in templateContact)) {
            const componentLoad = () => import(`@/views/modules/crm/leads/form/form_data/components/${templateContact.template}`)
            this.$store.dispatch('crm_leads_form/resetContactAdditional', {
              index,
              template: componentLoad,
              sw095s03: event,
            })
          } else {
            this.$store.dispatch('crm_leads_form/resetContactAdditional', { index })
          }
        } else {
          this.$store.dispatch('crm_leads_form/resetContactAdditional', { index })
        }
      } catch (err) {
        //
      }
    },
    getContactsDevice() {
      this.$root.$emit('crmLeadsForm::getContactsDevice')
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
.vti__search_box {
  width: 100% !important;
}
</style>
