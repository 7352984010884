<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">
          <footer-step-nav ref="footerStepNav" />
        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >

        <!-- So aparece em modo de equipa -->
        <template v-if="(vars.showBlockTeam===true) && (formModeView===false)">
          <div
            class="app-fixed-search d-flex align-items-center"
          >
            <div class="d-flex flex-column align-items-center">
              <div class="d-flex align-items-center justify-content-start w-100">
                <h4 class="p-1 m-0">
                  {{ $t('Responsável') }}
                </h4>
              </div>
            </div>
          </div>
          <b-container
            class="p-2"
            fluid
          >
            <b-row v-if="showTabSharesSelected===false">
              <b-col md="6">
                <b-alert
                  variant="info"
                  show
                >
                  <div class="alert-body">
                    <!-- eslint-disable vue/no-v-html -->
                    <span v-html="$t('Lead vai ser criado no separador {tab}', { tab: '<b>' + getNameTabLeads + '</b>' })" />
                    <!--eslint-enable-->
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row v-if="load_data_geral.teamUsersCreate.length > 0">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Consultor') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Consultor')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="form_data.idConsultorShare"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.teamUsersCreate"
                      :value="form_data.idConsultorShare"
                      append-to-body
                      :calculate-position="withPopper"
                      label="name"
                      item-text="name"
                      item-value="id"
                      @input="getSeparadoresByUserTeam"
                    >
                      <template #option="{ name }">
                        {{ name }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="showTabSharesSelected===true">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Separador') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Separador')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="form_data.separadorLead"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.teamTabsCreate"
                      :value="form_data.separadorLead"
                      append-to-body
                      :calculate-position="withPopper"
                      label="name"
                      item-text="name"
                      item-value="tab"
                    >
                      <template #option="{ name }">
                        {{ name }}
                      </template>
                      <div
                        v-if="form_data.idConsultorShare"
                        slot="no-options"
                      >
                        {{ $t('Sem resultados') }}
                      </div>
                      <div
                        v-if="!form_data.idConsultorShare"
                        slot="no-options"
                      >
                        {{ $t('Escolha um consultor') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="load_data_geral.teamBookmarksCreate.length > 0">
              <b-col md="6">
                <b-form-group
                  :label="$t('Marcadores')"
                >
                  <v-select
                    v-model="form_data.bookmarksCreate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.teamBookmarksCreate"
                    :value="form_data.bookmarksCreate"
                    append-to-body
                    :calculate-position="withPopper"
                    :multiple="true"
                    label="sw075s03"
                    item-text="sw075s03"
                    item-value="sw075s01"
                  >
                    <template #option="{ sw075s03, sw075s04 }">
                      <feather-icon
                        icon="BookmarkIcon"
                        size="18"
                        class="mr-75"
                        :style="{color: sw075s04}"
                      />
                      {{ sw075s03 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </template>

        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-top-theme' : vars.showBlockTeam }"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Geral') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >

          <b-row v-if="alertsRGPD.length > 0">
            <b-col md="12">
              <b-alert
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  {{ $t('RGPD') }}
                </h4>
                <div class="alert-body">
                  <b-container
                    class="p-1"
                    fluid
                  >
                    <template
                      v-for="(alert, index) in alertsRGPD"
                    >
                      <b-row
                        :key="`alert-rgpd-${index}`"
                        :class="{'mt-1': ((index===0) ? false : true)}"
                      >
                        <b-col md="8">
                          <div class="d-flex align-items-center h-100">
                            <p>{{ $t('Na data {data} foi efetuado um pedido de {tipo}', { data: truncateTxt(alert.sw254s12, 16), tipo: "'" + txtTipoPedidoRGPD(alert.sw256s504) + "'" }) }}</p>
                          </div>
                        </b-col>
                        <b-col md="4">
                          <div class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
                            <b-button-group>
                              <b-button
                                v-if="( (((alert.sw256s504==='oposicao') || (alert.sw256s504==='dadospessoais')) && (formModeView === true)) ? true : false)"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                size="sm"
                                @click.stop.prevent="modalConfirmRequestRGPD(alert)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-50"
                                  :style="{'top': '0px'}"
                                />
                                <span class="align-middle">{{ $t('Resolvido') }}</span>
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                size="sm"
                                @click.stop.prevent="modalViewRequestRGPD(alert.sw254s01)"
                              >
                                <feather-icon
                                  icon="EyeIcon"
                                  class="mr-50"
                                  :style="{'top': '0px'}"
                                />
                                <span class="align-middle">{{ $t('Ver pedido') }}</span>
                              </b-button>
                            </b-button-group>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-container>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewAvatar"
                        rounded
                        :src="imagePreviewUser"
                        height="80"
                      />
                    </b-link>
                  </b-media-aside>
                  <b-media-body
                    v-if="formModeView===false"
                    class="mt-75 ml-75"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.uploadImageAvatar.$el.click()"
                    >
                      {{ $t('Carregar') }}
                    </b-button>
                    <b-form-file
                      ref="uploadImageAvatar"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @input="inputImageRenderer"
                    />
                  </b-media-body>
                </b-media>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="(formModeView===true) && (vars.typeUserCreateLead === 0)"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('ID')"
              >
                <p
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s66 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="(formModeView===true) && (vars.typeUserCreateLead === 1)"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('ID')"
              >
                <p
                  v-if="form_data.sw025s192 !== ''"
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s192 || '-----' }}
                </p>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s66 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Nome') | lowerBold"
              >
                <b-form-group
                  :label="$t('Nome')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw025s03"
                    placeholder=""
                    autocomplete="off"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s03 || '-----' }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Apelido') | lowerBold"
              >
                <b-form-group
                  :label="$t('Apelido')"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    v-model="form_data.sw025s04"
                    placeholder=""
                    autocomplete="off"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s04 || '-----' }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Para tipo de cliente / contacto  -->
          <template v-if="vars.typeUserCreateLead===0 || vars.typeUserCreateLead===1">
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Classificação') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Classificação')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw025s08"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.classificacaoLead"
                      :value="form_data.sw025s08"
                      append-to-body
                      :calculate-position="withPopper"
                      label="sw021s03"
                      item-text="sw021s03"
                      item-value="sw021s01"
                    >
                      <template #option="{ sw021s02, sw021s03 }">

                        <template v-if="sw021s02">
                          {{ sw021s02 }} -
                        </template>

                        {{ sw021s03 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderObjToTxt(form_data.sw025s08, 'sw021s03') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :rules="{required : ((vars.typeUserCreateLead===0) ? true : false) }"
                  :name="$t('Tipo de cliente') | lowerBold"
                >
                  <b-form-group
                    :label="$t('Tipo de cliente')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="formModeView===false"
                      ref="sw027s03"
                      v-model="form_data.sw027s03"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.tipoClienteLead"
                      :value="form_data.sw027s03"
                      append-to-body
                      :calculate-position="withPopper"
                      :multiple="true"
                      label="sw022s02"
                      item-text="sw022s02"
                      item-value="sw022s01"
                      :selectable="checkOptionSelectedTipoCliente"
                      :close-on-select="false"
                      @input="fillValuesDefaultStateTypeClient();setUpdateFormData();"
                    >
                      <template #option="{ sw022s02 }">
                        {{ sw022s02 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                      <template #selected-option-container="{ option, deselect, multiple, disabled }">
                        <span
                          :key="vSelectGetOptionKey(option)"
                          class="vs__selected"
                        >
                          <slot
                            name="selected-option"
                            v-bind="vNormalizeOptionForSlot(option)"
                          >
                            {{ vSelectGetOptionLabel(option) }}
                          </slot>
                          <button
                            v-if="multiple && (option.disabled!==true)"
                            ref="deselectButtons"
                            :disabled="disabled"
                            type="button"
                            class="vs__deselect"
                            :title="`Deselect ${vSelectGetOptionLabel(option)}`"
                            :aria-label="`Deselect ${ vSelectGetOptionLabel(option)}`"
                            @click="deselect(option)"
                          >
                            <v-select-deselect />
                          </button>
                        </span>
                      </template>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ renderArrayToTxt(form_data.sw027s03, 'sw022s02') }}
                    </p>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <template v-if="vars.typeUserCreateLead===0">
              <b-row
                v-if="vars.showFieldComprador===true"
              >
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Estado comprador') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Estado comprador')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="form_data.sw025s07"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="load_data_geral.estadosLeadComprador"
                        :value="form_data.sw025s07"
                        append-to-body
                        :calculate-position="withPopper"
                        label="designacao"
                        item-text="designacao"
                        item-value="id"
                        :selectable="option => option.id > vars.nivelBlockEstadoComprador"
                      >
                        <template #option="{ designacao }">
                          {{ designacao }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ renderObjToTxt(form_data.sw025s07, 'designacao') }}
                      </p>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="vars.showFieldVendedor===true"
              >
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Estado vendedor') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Estado vendedor')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="form_data.sw025s60"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="load_data_geral.estadosLeadVendedor"
                        :value="form_data.sw025s60"
                        append-to-body
                        :calculate-position="withPopper"
                        label="designacao"
                        item-text="designacao"
                        item-value="id"
                        :selectable="option => option.id > vars.nivelBlockEstadoVendedor"
                      >
                        <template #option="{ designacao }">
                          {{ designacao }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ renderObjToTxt(form_data.sw025s60, 'designacao') }}
                      </p>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="(vars.showFieldVendedor===true) && (perms.canCreateListing() === true)"
              >
                <b-col md="6">
                  <b-form-group
                    :label="$t('Angariado')"
                  >
                    <v-select
                      v-if="formModeView===false"
                      v-model="form_data.sw025s11"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="load_data_geral.optionsYesNo"
                      :value="form_data.sw025s11"
                      append-to-body
                      :calculate-position="withPopper"
                      label="txt"
                      item-text="txt"
                      item-value="value"
                    >
                      <template #option="{ txt }">
                        {{ txt }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                    <p
                      v-else
                      class="text-primary mb-0"
                    >
                      {{ form_data.sw025s11 | getTxt }}
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </template>

        </b-container>
      </vue-perfect-scrollbar>

    </div>

    <view-request-rgpd
      v-if="showModalViewRequestRGPD===true"
      ref="modalViewRequestRGPD"
      :data="dataModalViewRequestRGPD"
      :close="closeModalViewRequestRGPD"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormGroup, BFormInput, BContainer, BMedia, BMediaAside, BMediaBody, BImg, BButton, BLink, BFormFile, BAlert, BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import atob from 'atob'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import btoa from 'btoa'
import checkNested from 'check-nested'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral, mixFormTabGeral, mixEventsSelectCustom } from '../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    ValidationProvider,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BButton,
    BLink,
    BFormFile,
    BAlert,
    BButtonGroup,
    ViewRequestRgpd: () => import(/* webpackChunkName: "crm-leads-new-modal-view-request-rgpd" */ /* webpackPrefetch: true */ './modal/viewRequestRGPD.vue'),
    vSelectDeselect: vSelect.components.Deselect,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral, mixFormTabGeral, mixEventsSelectCustom],
  data() {
    return {
      showModalViewRequestRGPD: false,
      dataModalViewRequestRGPD: [],
    }
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['form_data', 'load_data_geral', 'vars', 'perms', 'formModeView', 'alertsRGPD']),
    imagePreviewUser() {
      try {
        if (this.form_data !== null) {
          if (('sw025s121' in this.form_data) && (this.form_data.sw025s121 !== '')) {
            return this.form_data.sw025s121
          }
          if (('sw025s10' in this.form_data) && (Number(this.form_data.sw025s10.id) === 2)) {
            return this.vars.previewPhotos.fem
          }
        }
      } catch (err) {
        //
      }

      return this.vars.previewPhotos.masc
    },
    showTabSharesSelected() {
      try {
        if (checkNested(this.vars.paramsUrl, 'tab') && (this.vars.paramsUrl.tab === 'partilhadas')) {
          return true
        }
      } catch (err) {
        //
      }

      return false
    },
    getNameTabLeads() {
      let nameTab = ''

      if (checkNested(this.form_data, 'separadorLead.name')) {
        nameTab = this.form_data.separadorLead.name
      }

      return nameTab
    },
  },
  beforeCreate() {
    this.$root.$on('crmLeadsForm::uploadImageAvatar', imageBase64 => {
      try {
        this.$refs.previewAvatar.src = this.imagePreviewUser
        this.$refs.uploadImageAvatar.reset()

        if (imageBase64 !== '') {
          const blobFile = this.dataURItoBlob(imageBase64)
          this.$refs.uploadImageAvatar.setFiles([blobFile])
        }
      } catch (err) {
        //
      }
    })
  },
  beforeDestroy() {
    this.$root.$off('crmLeadsForm::uploadImageAvatar')
  },
  methods: {
    setUpdateFormData() {
      this.$store.commit('crm_leads_form/setUpdateFormData', this.form_data)
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(arrayBuffer)
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i)
      }

      const dataView = new DataView(arrayBuffer)
      const blob = new Blob([dataView], { type: mimeString })
      return blob
    },
    txtTipoPedidoRGPD(tipo) {
      let txt = ''

      if (tipo === 'eliminacao') {
        txt = this.t('Remoção de dados')
      }

      if (tipo === 'oposicao') {
        txt = this.t('Direito de oposição')
      }

      if (tipo === 'dadospessoais') {
        txt = this.t('Alteração de dados')
      }

      return txt
    },
    async modalViewRequestRGPD(id) {
      const self = this

      try {
        self.$root.$emit('app::loading', true)
        await this.$store.dispatch('crm_leads_form/modalViewRequestRGPD', { sw254s01: btoa(id) }).then(async res => {
          await self.$root.$emit('app::loading', false)
          this.showModalViewRequestRGPD = true
          this.dataModalViewRequestRGPD = res
        }).catch(error => {
          self.$root.$emit('app::loading', false)
          self.showMsgErrorRequest(error)
        })
      } catch (err) {
      //
      }
    },
    closeModalViewRequestRGPD() {
      this.showModalViewRequestRGPD = false
      this.dataModalViewRequestRGPD = []
    },
    modalConfirmRequestRGPD(row) {
      const self = this
      try {
        this.$swal({
          title: '',
          html: `${this.$t('Confirma que resolveu os pedidos solicitados ao abrigo da lei de proteção de dados?')}<br>${this.$t('Esta ação irá submeter um email de confirmação ao autor da reclamação.')}`,
          icon: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Confirmo'),
          cancelButtonText: this.$t('Cancelar'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            self.$root.$emit('app::loading', true)
            this.$store.dispatch('crm_leads_form/confirmRequestRGPD', {
              sw254s01: btoa(row.sw254s01),
              sw256s01: btoa(row.sw256s01),
              sw025s01: row.sw025s01,
            }).then(res => {
              self.$root.$emit('app::loading', false)
              self.showMsgSuccessRequest(res)
            }).catch(error => {
              self.$root.$emit('app::loading', false)
              self.showMsgErrorRequest(error)
            })
          }
        })
      } catch (err) {
      //
      }
    },
    getSeparadoresByUserTeam(user) {
      if (this.showTabSharesSelected === true) {
        this.$store.commit('crm_leads_form/setTeamTabsCreate', [])
        this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'separadorLead', value: null })
      }

      this.$store.commit('crm_leads_form/setTeamBookmarksCreate', [])
      this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'bookmarksCreate', value: null })

      if (checkNested(user, 'id') && (user.id !== '')) {
        try {
          this.$root.$emit('app::loading', true)
          this.$store.dispatch('crm_leads_form/getSeparadoresByUserTeam', { user: btoa(user.id) }).then(() => {
            this.$root.$emit('app::loading', false)

            // Caso so tenha um separador automaticamente seleciona-o
            if (this.load_data_geral.teamTabsCreate.length === 1) {
              const teamTabsCreateSelected = this.load_data_geral.teamTabsCreate.find(o => o.tab !== undefined)
              this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'separadorLead', value: teamTabsCreateSelected })
            }
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          //
        }
      }
    },
    fillValuesDefaultStateTypeClient() {
      if (this.vars.typeUserCreateLead === 0) {
        if (checkNested(this.form_data, 'sw027s03')) {
          let vHaveComp = 0
          let vHaveVend = 0
          const aAllTiposClienteSelected = []

          if (this.form_data.sw027s03.length > 0) {
            this.form_data.sw027s03.forEach(row => {
              if (row.sw022s01) {
                aAllTiposClienteSelected.push(Number(row.sw022s01))
              }
            })

            if (aAllTiposClienteSelected.includes(2) || aAllTiposClienteSelected.includes(4)) {
              vHaveComp += 1
            }

            if (aAllTiposClienteSelected.includes(1) || aAllTiposClienteSelected.includes(3)) {
              vHaveVend += 1
            }
          }

          // Comprador
          if (vHaveComp > 0) {
            if (!checkNested(this.form_data, 'sw025s07.nivel')) {
              this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: this.load_data_geral.estadosLeadComprador.find(o => Number(o.nivel) === Number(1)) })
            }
          } else {
            this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: null })
          }

          // Vendedor
          if (vHaveVend > 0) {
            if (!checkNested(this.form_data, 'sw025s60.nivel')) {
              this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s60', value: this.load_data_geral.estadosLeadVendedor.find(o => Number(o.nivel) === Number(1)) })
            }
          } else {
            this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s60', value: null })
          }
        } else {
          this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s07', value: null })
          this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s60', value: null })
        }
      }
    },
  },
  setup() {
    const uploadImageAvatar = ref(null)
    const previewAvatar = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(uploadImageAvatar, base64 => {
      previewAvatar.value.src = base64
      store.dispatch('crm_leads_form/updateFieldValue', { field: 'linkImageLoadedUser', value: base64 })
    })
    const { t } = useI18nUtils()

    return {
      uploadImageAvatar,
      previewAvatar,
      inputImageRenderer,
      t,

      configSettingsScroll,
    }
  },
}
</script>
