<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content email-user-list"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((data.showListviewFilter === true) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Atividade de consentimento') }}
              </h4>
            </div>

          </div>
        </div>

        <div
          v-if="data.showListviewFilter === true"
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
            </b-input-group>
          </div>

          <div class="d-flex align-content-left justify-content-between w-100">
            <v-select
              v-model="searchTxt"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="fieldsFilter.consentimento"
              append-to-body
              :calculate-position="withPopper"
              :placeholder="txt.searchPlaceholder"
              label="txt"
              item-text="txt"
              item-value="id"
              class="w-100 border-0"
            >
              <template #option="{ txt }">
                {{ txt }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </div>
          <div
            v-if="fieldsOrder.length > 0"
            class="dropdown"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0 mr-1 ml-1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  :size="sizeIconsAction"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-for="oField in fieldsOrder"
                :key="`field-order-consentimento-${oField.name}`"
                @click="listviewFilterOrderBy(oField.name)"
              >
                <feather-icon
                  :icon="listviewIconOrderBy(oField.name)"
                  :class="listviewIconOrderBy(oField.name, true)"
                />
                {{ oField.txt }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div
          v-if="data.showListview() === true"
          class="p-2"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(row, index) in listviewData"
              :key="`consentimento-item-${index}`"
              :variant="colorConsentimento(row)"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ row.data }}</h6>
              </div>
              <p>{{ row.atividade }}</p>
              <div
                v-if="row.consentimento !== ''"
                class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
              >
                <div>
                  <span class="text-muted mb-50 d-block">{{ $t('Consentimento') }}</span>
                  <span>{{ row.consentimento }}</span>
                </div>
              </div>
              <div
                v-if="row.responsavel !== ''"
                class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
              >
                <div :class="{'mt-1': ((row.consentimento !== '') ? true : false) }">
                  <span class="text-muted mb-50 d-block">{{ $t('Responsável') }}</span>
                  <span>{{ row.responsavel }}</span>
                </div>
              </div>
            </app-timeline-item>
          </app-timeline>
        </div>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(data.totalFieldsFilter===0) && (data.init === 0)"
            class="mb-0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>

        <b-container
          v-if="data.showPager() === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center mt-2 mb-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="data.totalRows"
                :per-page="data.perPage"
                class="mb-0 pagination-white"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="data.loading"
        no-wrap
      />

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BContainer, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BOverlay, VBTooltip, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import modelConsentimento from '@store-modules/crm/leads/consentimentoList'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BPagination,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BAlert,
    AppTimeline,
    AppTimelineItem,
    vSelect,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  computed:
  {
    ...mapGetters('consentimentoList', ['listviewData', 'data', 'txt', 'fieldsOrder', 'fieldsFilter']),
    searchTxt: {
      get() { return this.$store.getters['consentimentoList/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('consentimentoList/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['consentimentoList/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('consentimentoList/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  methods: {
    async listviewFilterOrderBy(field) {
      this.$store.dispatch('consentimentoList/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        await this.$store.dispatch('consentimentoList/loadListviewData', { init: true }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
    colorConsentimento(row) {
      let color = 'danger'

      if ('consentimento_id' in row) {
        if (Number(row.consentimento_id) === 1) {
          color = 'warning'
        }
        if (Number(row.consentimento_id) === 2) {
          color = 'info'
        }
      }

      return color
    },
  },
  setup() {
    const MODEL_CONSENTIMENTO_LIST = 'consentimentoList'

    if (!store.hasModule(MODEL_CONSENTIMENTO_LIST)) {
      store.registerModule(MODEL_CONSENTIMENTO_LIST, modelConsentimento)

      onUnmounted(() => {
        if (store.hasModule(MODEL_CONSENTIMENTO_LIST)) store.unregisterModule(MODEL_CONSENTIMENTO_LIST)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
