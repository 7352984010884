<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img text-center pt-0">
      <b-link>
        <b-img
          v-if="listing.image !== ''"
          :alt="`${listing.pid}`"
          fluid
          class="card-img-top"
          :src="listing.image + '?v=' + new Date().getTime()"
        />
        <b-img
          v-else
          :alt="`${listing.pid}`"
          fluid
          class="card-img-top"
          :src="require('@/assets/images/zome/listingNotFoundMres.png')"
        />
      </b-link>
    </div>

    <b-card-body>

      <div class="item-wrapper text-truncate">
        <div
          v-if="(showRating===true) && ((listing.rating.perc !== null) && (listing.rating.perc !== ''))"
          class="item-rating"
        >
          <b-button
            v-if="listing.rating.btn_color === 'red'"
            variant="danger"
            class="btn-sm"
          >
            <feather-icon
              icon="FrownIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ listing.rating.perc }}%</span>
          </b-button>
          <b-button
            v-if="listing.rating.btn_color === 'orange'"
            variant="warning"
            class="btn-sm"
          >
            <feather-icon
              icon="MehIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ listing.rating.perc }}%</span>
          </b-button>
          <b-button
            v-if="listing.rating.btn_color === 'green'"
            variant="success"
            class="btn-sm"
          >
            <feather-icon
              icon="SmileIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ listing.rating.perc }}%</span>
          </b-button>
        </div>
        <div
          v-else
          class="item-rating"
        />

        <div>
          <h6
            v-if="listing.price"
            class="item-price"
          >
            {{ formatPrice(listing.price) }}
          </h6>
          <h6
            v-else
            class="item-price"
          >
            {{ '\xa0' }}
          </h6>
        </div>
      </div>

      <h6
        class="item-name mt-75 mb-75 text-truncate"
      >
        <span
          v-if="listing.tipologia"
          class="text-body"
        >
          {{ listing.tipologia }}
        </span>
        <span v-else>{{ '\xa0' }}</span>
      </h6>

      <b-card-text
        class="item-description mt-0 mb-0"
      >
        <template v-if="listing.morada">
          {{ listing.morada }}
        </template>
        <template v-else>
          {{ $t('Sem localização definida') }}
        </template>
      </b-card-text>

      <b-card-text
        class="item-description mt-0 mb-0"
      >
        <template v-if="listing.nickname">
          <b>{{ listing.nickname }}</b>
        </template>
        <template v-else>
          {{ '\xa0' }}
        </template>
      </b-card-text>

      <b-card-text
        v-if="listing.agent"
        class="item-description mt-1 mb-0"
      >
        <b-media>
          <template #aside>
            <b-avatar :src="listing.agent.imagem_300 + '?v=' + new Date().getTime()" />
          </template>
          <h6>
            {{ listing.agent.nome }}
            <template v-if="listing.agent.allLocationHUB">
              - <small>
                {{ listing.agent.allLocationHUB }}
              </small>
            </template>
          </h6>
          <p
            v-if="listing.agent.telefone"
            class="mb-0"
          >
            {{ listing.agent.telefone }}
          </p>
        </b-media>
      </b-card-text>

      <div
        v-if="listing.status.length > 0"
        class="mt-75"
      >
        <b-button
          v-for="(status, indexStatus) in listing.status"
          :key="`button-status-listing-${indexStatus}`"
          variant="outline-secondary"
          class="btn-sm mr-25"
          :style="[{ cursor: 'default' }, getColorByStatus(status.id)]"
        >
          {{ status.txt }}
          <template v-if="status.version">
            {{ '\xa0' }}{{ status.version }}
          </template>
        </b-button>
      </div>

    </b-card-body>

    <slot name="blockActions" />

  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BCardBody, BLink, BImg, BCardText, BButton,
  BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    listing: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    showRating() {
      try {
        if ((this.listing !== null) && (this.listing !== undefined)) {
          if ('rating' in this.listing) {
            return true
          }
        }
      } catch (err) {
        //
      }

      return false
    },
  },
  methods: {
    getColorByStatus(id) {
      if (Number(id) === 1) {
        return { 'border-color': '#1BBC9B!important', color: '#1BBC9B' }
      }
      if (Number(id) === 2) {
        return { 'border-color': '#9A12B3!important', color: '#9A12B3' }
      }
      if (Number(id) === 3) {
        return { 'border-color': '#C49F47!important', color: '#C49F47' }
      }
      if (Number(id) === 4) {
        return { 'border-color': '#D91E18!important', color: '#D91E18' }
      }
      if (Number(id) === 5) {
        return { 'border-color': '#F3C200!important', color: '#F3C200' }
      }
      if (Number(id) === 6) {
        return { 'border-color': '#E87E04!important', color: '#E87E04' }
      }
      if (Number(id) === 7) {
        return { 'border-color': '#999999!important', color: '#999999' }
      }
      if (Number(id) === 9) {
        return { 'border-color': '#000000!important', color: '#000000' }
      }

      return {}
    },
  },
}
</script>
