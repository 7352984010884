<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">
          <footer-step-nav ref="footerStepNav" />
        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Dados') }}
              </h4>
            </div>
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Nickname')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw025s109"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s109 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Género') | lowerBold"
              >
                <b-form-group
                  :label="$t('Género')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s10"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.generos"
                    :value="form_data.sw025s10"
                    append-to-body
                    :calculate-position="withPopper"
                    label="txt"
                    item-text="txt"
                    item-value="id"
                  >
                    <template #option="{ txt }">
                      {{ txt }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s10 | getTxt }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Fonte de contacto') | lowerBold"
              >
                <b-form-group
                  :label="$t('Fonte de contacto')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s13"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.fonteContactos"
                    :value="form_data.sw025s13"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw023s02"
                    item-text="sw023s02"
                    item-value="sw023s01"
                    @input="fieldsExtrasFonteContacto"
                    @change="fieldsExtrasFonteContacto"
                  >
                    <template #option="{ sw023s02 }">
                      {{ sw023s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw025s13, 'sw023s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Fonte contacto :: Anuncio online -->
          <b-row
            v-if="showFonteContactoAO===true"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Tipo de anúncio') | lowerBold"
              >
                <b-form-group
                  :label="$t('Tipo de anúncio')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s221"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.fonteContactoTiposAnuncio"
                    append-to-body
                    :calculate-position="withPopper"
                    label="txt"
                    item-text="txt"
                    item-value="id"
                  >
                    <template #option="{ txt }">
                      {{ txt }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s221 | getTxt }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="showFonteContactoAO===true"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('ID do anúncio')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw025s222"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s222 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Fonte contacto :: Referenciado por consultor -->
          <b-row
            v-if="showFonteContactoC===true"
          >
            <b-col md="6">
              <validation-provider
                ref="sw025s43Validation"
                #default="{ errors }"
                rules="required"
                :name="$t('Referenciado por consultor') | lowerBold"
              >
                <b-form-group
                  :label="$t('Consultor')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s43"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listConsultoresApp"
                    append-to-body
                    :calculate-position="withPopper"
                    :loading="isLoadingSW025s43"
                    :filterable="false"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @search="onSearchConsultoresAppSelect"
                  >
                    <div
                      v-if="listConsultoresApp.length > 0"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <template
                      v-if="listConsultoresApp.length === 0"
                      slot="no-options"
                    >
                      {{ $t('Digite o nome') }}
                    </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <div class="d-flex align-items-center">
                        <b-avatar
                          :src="option.photo"
                          class="mr-50"
                        />
                        <div class="detail">
                          <b-card-text class="mb-0">
                            {{ option.desc }}
                          </b-card-text>
                          <b-card-text
                            v-if="option.email"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.email }}</small>
                          </b-card-text>
                          <b-card-text
                            v-if="option.phone"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.phone }}</small>
                          </b-card-text>
                        </div>
                      </div>
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.desc }}
                    </template>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw025s43, 'desc') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Fonte contacto :: Referenciado por HUB -->
          <b-row
            v-if="showFonteContactoM===true"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Referenciado por HUB') | lowerBold"
              >
                <b-form-group
                  :label="$t('HUB')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s44"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.fonteContactoHUBs"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw002s02"
                    item-text="sw002s02"
                    item-value="sw002s01"
                  >
                    <template #option="{ sw002s02 }">
                      {{ sw002s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw025s44, 'sw002s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Fonte contacto :: Referenciado por lead / contacto -->
          <b-row
            v-if="showFonteContactoL===true"
          >
            <b-col md="6">
              <validation-provider
                ref="sw025s45Validation"
                #default="{ errors }"
                rules="required"
                :name="`${$t('Referenciado por')} ${$tc('Lead', 1)} / ${$tc('Contacto', 1)}` | lowerBold"
              >
                <b-form-group
                  :label="`${$tc('Lead', 1)} / ${$tc('Contacto', 1)}`"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s45"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listLeadsContactsApp"
                    append-to-body
                    :calculate-position="withPopper"
                    :loading="isLoadingSW025s43"
                    :filterable="false"
                    label="nome"
                    item-text="nome"
                    item-value="id"
                    @search="onSearchLeadsContactsAppSelect"
                  >
                    <div
                      v-if="listLeadsContactsApp.length > 0"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <template
                      v-if="listLeadsContactsApp.length === 0"
                      slot="no-options"
                    >
                      {{ $t('Digite o nome') }}
                    </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <div class="d-flex align-items-center">
                        <b-avatar
                          :src="option.photo"
                          class="mr-50"
                        />
                        <div class="detail">
                          <b-card-text class="mb-0">
                            {{ option.nome }}
                          </b-card-text>
                          <b-card-text
                            v-if="option.email"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.email }}</small>
                          </b-card-text>
                          <b-card-text
                            v-if="option.phone"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.phone }}</small>
                          </b-card-text>
                          <b-card-text
                            v-if="option.tipo"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.tipo }}</small>
                          </b-card-text>
                        </div>
                      </div>
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.nome }}
                    </template>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw025s45, 'nome') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Fonte contacto :: Sites imobiliarios -->
          <b-row
            v-if="showFonteContactoSI===true"
          >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Sites imobiliários') | lowerBold"
              >
                <b-form-group
                  :label="$t('Sites imobiliários')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-if="formModeView===false"
                    v-model="form_data.sw025s63"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="load_data_geral.fonteContactoSitesImobiliarios"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw066s02"
                    item-text="sw066s02"
                    item-value="sw066s01"
                  >
                    <template #option="{ sw066s02 }">
                      {{ sw066s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ renderObjToTxt(form_data.sw025s63, 'sw066s02') }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Data de aniversário')"
              >
                <date-picker
                  v-if="formModeView===false"
                  v-model="form_data.sw025s14"
                  value-type="format"
                  input-class="form-control"
                  type="date"
                  :editable="false"
                  :clearable="true"
                  :lang="langCalendar"
                  :append-to-body="true"
                  prefix-class="sw"
                >
                  <template #icon-clear>
                    <v-select-deselect />
                  </template>
                </date-picker>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s14 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Notificação')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw025s87"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.tiposNotificacaoDataAniversario"
                  :value="form_data.sw025s87"
                  append-to-body
                  :calculate-position="withPopper"
                  label="txt"
                  item-text="txt"
                  item-value="id"
                >
                  <template #option="{ txt }">
                    {{ txt }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s87 | getTxt }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Nº de filhos')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw025s15"
                  v-mask="['##']"
                  placeholder=""
                  autocomplete="off"
                  inputmode="numeric"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s15 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :rules="(($store.getters['auth/countryApp'] == 'pt') ? 'nif': '')"
                :name="(($store.getters['auth/countryApp'] == 'es') ? $t('DNI') : $t('NIF')) | lowerBold"
              >
                <b-form-group
                  :label="(($store.getters['auth/countryApp'] == 'es') ? $t('DNI') : $t('NIF'))"
                >
                  <b-form-input
                    v-if="formModeView===false"
                    ref="sw025s26"
                    v-model="form_data.sw025s26"
                    v-mask="tokenMaskNIF"
                    placeholder=""
                    autocomplete="off"
                    :type="(($store.getters['auth/countryApp'] == 'pt') ? 'number': 'text')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    @blur.native.capture.prevent="checkNIFExist"
                    @keydown.native.tab.prevent="$event.target.blur()"
                  />
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s26 || '-----' }}
                  </p>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="$store.getters['auth/countryApp'] === 'pt'"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('BI / CC')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  ref="sw025s27"
                  v-model="form_data.sw025s27"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s27 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="$store.getters['auth/countryApp'] === 'pt'"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Validade do BI / CC')"
              >
                <date-picker
                  v-if="formModeView===false"
                  v-model="form_data.sw025s86"
                  value-type="format"
                  input-class="form-control"
                  type="date"
                  :editable="false"
                  :clearable="true"
                  :lang="langCalendar"
                  :append-to-body="true"
                  prefix-class="sw"
                >
                  <template #icon-clear>
                    <v-select-deselect />
                  </template>
                </date-picker>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s86 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="$store.getters['auth/countryApp'] === 'es'">
            <b-col md="6">
              <b-form-group
                :label="$t('NIE')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  ref="sw025s120"
                  v-model="form_data.sw025s120"
                  v-mask="tokenMaskNIE"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s120 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="formModeView===false">
            <b-row>
              <b-col md="12">
                <h4 class="pt-1 pb-1">
                  {{ $t('Empresarial') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-form-checkbox
                    v-model="form_data.sw025s113"
                    value="1"
                  >
                    {{ $t('Cliente empresarial') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-row
              v-if="form_data.sw025s113==='1'"
            >
              <b-col md="12">
                <h4 class="pt-1 pb-1">
                  {{ $t('Cliente empresarial') }}
                </h4>
              </b-col>
            </b-row>
          </template>

          <b-row
            v-show="showFieldsClienteEmpresarial===true"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Empresa')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw025s114"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.empresasCRM"
                  :value="form_data.sw025s114"
                  append-to-body
                  :calculate-position="withPopper"
                  label="sw101s02"
                  item-text="sw101s02"
                  item-value="sw101s01"
                >
                  <template #option="{ sw101s02 }">
                    {{ sw101s02 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ renderObjToTxt(form_data.sw025s114, 'sw101s02') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-show="showFieldsClienteEmpresarial===true"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('Cargo')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw025s115"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s115 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <h4 class="pt-1 pb-1">
                {{ $t('Elegibilidade para contactos comerciais') }}
              </h4>
            </b-col>
          </b-row>
          <b-row
            v-if="vars.isBlacklist===true"
          >
            <b-col md="6">
              <b-alert
                variant="secondary"
                show
              >
                <div class="alert-body">
                  <span>
                    {{ $t('Está na blacklist devido a ter removido a subscrição numa das suas campanhas.') }}
                    <br>
                    {{ $t("Para voltar a ter o consentimento 'Newsletter para campanhas de email' ativo tem de gerar novo link e envia-lo para que volte a aceitar o consentimento.") }}
                  </span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label=""
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw025s197"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.elegibilidadeContactoComercial"
                  :value="form_data.sw025s197"
                  append-to-body
                  :calculate-position="withPopper"
                  label="txt"
                  item-text="txt"
                  item-value="id"
                  @input="loadDocumentosConsentimentoExplicito"
                  @change="loadDocumentosConsentimentoExplicito"
                >
                  <template #option="{ txt }">
                    {{ txt }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  <feather-icon
                    icon="CheckSquareIcon"
                    size="17"
                    class="mr-50"
                  />
                  {{ form_data.sw025s197 | getTxt }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="formModeView===false"
              md="12"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw025s152"
                  value="1"
                  :disabled="checkDesactiveOptionConsentimento"
                >
                  {{ $t('SMS') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="(form_data.sw025s152 ==='1') && (formModeView===true)"
              md="12"
            >
              <b-form-group>
                <p
                  class="text-primary mb-0"
                >
                  <feather-icon
                    :icon="((form_data.sw025s152 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                    size="17"
                    class="mr-50"
                  />
                  {{ $t('SMS') }}
                </p>
              </b-form-group>
            </b-col>

            <b-col
              v-if="formModeView===false"
              md="12"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw025s205"
                  value="1"
                  :disabled="checkDesactiveOptionConsentimento"
                >
                  {{ $t('Chamada') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="(form_data.sw025s205 ==='1') && (formModeView===true)"
              md="12"
            >
              <b-form-group>
                <p
                  class="text-primary mb-0"
                >
                  <feather-icon
                    :icon="((form_data.sw025s205 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                    size="17"
                    class="mr-50"
                  />
                  {{ $t('Chamada') }}
                </p>
              </b-form-group>
            </b-col>

            <b-col
              v-if="formModeView===false"
              md="12"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw025s206"
                  value="1"
                  :disabled="checkDesactiveOptionConsentimento"
                >
                  {{ $t('Carta Postal') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="(form_data.sw025s206 ==='1') && (formModeView===true)"
              md="12"
            >
              <b-form-group>
                <p
                  class="text-primary mb-0"
                >
                  <feather-icon
                    :icon="((form_data.sw025s206 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                    size="17"
                    class="mr-50"
                  />
                  {{ $t('Carta Postal') }}
                </p>
              </b-form-group>
            </b-col>

            <b-col
              v-if="formModeView===false"
              md="12"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="form_data.sw025s153"
                  value="1"
                  :disabled="(vars.isBlacklist===true) || (checkDesactiveOptionConsentimento===true)"
                >
                  {{ $t('Newsletter para campanhas de email') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="(form_data.sw025s153 ==='1') && (formModeView===true)"
              md="12"
            >
              <b-form-group>
                <p
                  class="text-primary mb-0"
                >
                  <feather-icon
                    :icon="((form_data.sw025s153 ==='1') ? 'CheckSquareIcon' : 'SquareIcon')"
                    size="17"
                    class="mr-50"
                  />
                  {{ $t('Newsletter para campanhas de email') }}
                </p>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row
            v-show="showDocsConsentimentoExplicito===false"
          >
            <b-col
              v-if="formModeView===false"
              md="12"
            >
              <b-button
                v-if="Object.keys(vars.infoConsentimento).length === 0"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mb-1"
                @click.stop.prevent="linkConsentimento"
              >
                <span class="align-middle">{{ $t('Gerar link para consentimento') }}</span>
              </b-button>
              <b-button
                v-if="Object.keys(vars.infoConsentimento).length > 0"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mb-1"
                @click.stop.prevent="linkConsentimento"
              >
                <span class="align-middle">{{ $t('Ver link de consentimento') }}</span>
              </b-button>
            </b-col>
            <b-col
              v-if="(Object.keys(vars.infoConsentimento).length > 0) && (formModeView===true)"
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mb-1"
                @click.stop.prevent="linkConsentimento"
              >
                <span class="align-middle">{{ $t('Ver link de consentimento') }}</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <documentos-consentimento-explicito
                ref="documentosConsentimentoExplicito"
                :create-new="showDocsConsentimentoExplicito"
                :can-delete="showDocsConsentimentoExplicito"
              />
            </b-col>
          </b-row>

        </b-container>
      </vue-perfect-scrollbar>

    </div>

    <modal-link-consentimento
      v-if="showModalLinkConsentimento===true"
      ref="modalLinkConsentimento"
      :info-consentimento="vars.infoConsentimento"
      :close="closeModalConsentimento"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormGroup, BFormInput, BContainer, BFormCheckbox, BButton, BAlert, BCardText, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { onUnmounted } from '@vue/composition-api'
import modelSW001 from '@store-modules/users/data'
import modelSW025 from '@store-modules/crm/leads/data'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral, mixFormFonteContacto } from '../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    ValidationProvider,
    vSelect,
    BFormCheckbox,
    BButton,
    BAlert,
    vSelectDeselect: vSelect.components.Deselect,
    BCardText,
    BAvatar,
    ModalLinkConsentimento: () => import(/* webpackChunkName: "crm-leads-new-modal-consentimento" */ /* webpackPrefetch: true */ './modal/consentimento.vue'),
    DocumentosConsentimentoExplicito: () => import(/* webpackChunkName: "crm-leads-new-sw281" */ /* webpackPrefetch: true */ './sw281/index.vue'),
    DatePicker: oDatepicker,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral, mixFormFonteContacto],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDateBirtday = new Date(today)
    maxDateBirtday.setFullYear(maxDateBirtday.getFullYear() - 18)

    return {
      tokenMaskNIF: {
        mask: '#########',
        tokens: {
          '#': {
            pattern: /\d/,
          },
        },
      },
      tokenMaskNIE: {
        mask: '#################',
        tokens: {
          '#': {
            pattern: /[0-9a-zA-Z]/,
          },
        },
      },
      maxDateBirtday,
      showModalLinkConsentimento: false,
      checkNIFExistInit: 0,
      langCalendar: {},
    }
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['form_data', 'load_data_geral', 'vars', 'formModeView']),
    clienteEmpresarial() {
      if ((this.vars.typeUserCreateLead === 0) || (this.vars.typeUserCreateLead === 1)) {
        return true
      }

      return false
    },
    showFieldsClienteEmpresarial() {
      try {
        if (this.form_data !== null) {
          if ('sw025s113' in this.form_data) {
            if (Number(this.form_data.sw025s113) === 1) {
              return true
            }
          }
        }
      } catch (err) {
        //
      }

      return false
    },
    showDocsConsentimentoExplicito() {
      try {
        if (this.form_data !== null) {
          if ('sw025s197' in this.form_data) {
            if (Number(this.form_data.sw025s197.id) === 2) {
              return true
            }
          }
        }
      } catch (err) {
        //
      }

      return false
    },
    checkDesactiveOptionConsentimento() {
      if (
        (this.form_data !== null)
        && (this.form_data !== undefined)
        && ('sw025s197' in this.form_data)
        && ('id' in this.form_data.sw025s197)
        && ([1, 2].includes(this.form_data.sw025s197.id))
      ) {
        return false
      }

      return true
    },
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    addNewBusinessToLeadCRM() {
    },
    async linkConsentimento() {
      const self = this

      try {
        self.$root.$emit('app::loading', true)
        await this.$store.dispatch('crm_leads_events/generateLinkConsentimento', {
          idLead: this.$store.getters['crm_leads_form/keyReg'],
          idLeadTemp: this.$store.getters['crm_leads_form/dynamicIDForm'],
        }).then(async res => {
          await self.$store.dispatch('crm_leads_form/setInfoConsentimento', res)
          await self.$root.$emit('app::loading', false)
          this.showModalLinkConsentimento = true
        }).catch(error => {
          self.$root.$emit('app::loading', false)
          self.showMsgErrorRequest(error)
        })
      } catch (err) {
      //
      }
    },
    closeModalConsentimento() {
      this.showModalLinkConsentimento = false
    },
    async loadDocumentosConsentimentoExplicito() {
      await this.$refs.documentosConsentimentoExplicito.init()
    },
    async selectedConsultoresApp(id) {
      this.form_data.sw025s43 = null
      this.isLoadingSW025s43 = true
      await this.$store.dispatch('sw001/searchAllAgents', { idUser: id }).then(res => {
        this.listConsultoresApp = [{ data: res }]
        this.isLoadingSW025s43 = false

        const selSW025s43 = res.find(o => Number(o.id) === Number(id))
        this.form_data.sw025s43 = selSW025s43
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async selectedLeadsContactsApp(id) {
      this.form_data.sw025s45 = null
      this.isLoadingSW025s45 = true
      store.dispatch('sw025/searchLeadsContactoByUserAuth', { sw025s01: id }).then(res => {
        this.listLeadsContactsApp = [{ data: res }]
        this.isLoadingSW025s45 = false

        const selSW025s45 = res.find(o => Number(o.id) === Number(id))
        this.form_data.sw025s45 = selSW025s45
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async loadConsentimentoLink() {
      const self = this
      try {
        await this.$store.dispatch('crm_leads_events/generateLinkConsentimento', {
          idLead: this.$store.getters['crm_leads_form/keyReg'],
          idLeadTemp: this.$store.getters['crm_leads_form/dynamicIDForm'],
          onlyRead: true,
        }).then(async res => {
          await self.$store.dispatch('crm_leads_form/setInfoConsentimento', res)
        }).catch(error => {
          self.showMsgErrorRequest(error)
        })
      } catch (err) {
      //
      }
    },
    async checkNIFExist(e) {
      if ((e.relatedTarget !== null) && (e.relatedTarget.classList.contains('sw-button-action'))) {
        return
      }

      if ((this.form_data.sw025s26 !== '') && (this.checkNIFExistInit === 0)) {
        this.checkNIFExistInit = 1

        let focusInputNext = true

        this.$root.$emit('app::loading', true)
        await this.$store.dispatch('crm_leads_form/checkExistAllMyLeadsByNIForDNI', this.form_data.sw025s26).then(async res => {
          this.$root.$emit('app::loading', false)

          if (res.error === 1) {
            if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
              const actionModal = new Promise(resolve => {
                this.$root.$emit('crmLeadsForm::showMsgDetailRequest', { pIcon: 'warning', pDetail: res.alerts }, resolve)
              })

              await actionModal.then(result => {
                if (result === false) {
                  focusInputNext = false
                  this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw025s26', value: '' })
                  this.$nextTick(() => {
                    this.$refs.sw025s26.focus()
                  })
                }
              })
            }
          }
        }).catch(error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest(error)
          this.$refs.email.focus()
        })

        this.checkNIFExistInit = 0

        if (focusInputNext === true) {
          if (this.$store.getters['auth/countryApp'] === 'es') {
            this.$refs.sw025s120.focus()
          } else {
            this.$refs.sw025s27.focus()
          }
        }
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    disabledMaxDateBirtday(date) {
      return date > this.maxDateBirtday
    },
  },
  setup() {
    const NAMESPACE_SW001_DATA = 'sw001'
    const NAMESPACE_SW025_FORM_DATA = 'sw025'

    if (!store.hasModule(NAMESPACE_SW001_DATA)) {
      store.registerModule(NAMESPACE_SW001_DATA, modelSW001)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW001_DATA)) store.unregisterModule(NAMESPACE_SW001_DATA)
      })
    }

    if (!store.hasModule(NAMESPACE_SW025_FORM_DATA)) {
      store.registerModule(NAMESPACE_SW025_FORM_DATA, modelSW025)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM_DATA)) store.unregisterModule(NAMESPACE_SW025_FORM_DATA)
      })
    }

    return {
      NAMESPACE_SW001_DATA,
      NAMESPACE_SW025_FORM_DATA,

      configSettingsScroll,
    }
  },
}
</script>
