import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  searchTxt: ref(''),
  totalFieldsFilter: ref(0),
  showListviewFilter: ref(false),
  orderBy: ref({
    column: 'data',
    sort: 'desc',
  }),
  txtSearchPlaceholder: i18n.t('Comentário'),
  txtLoading: i18n.t('A carregar registos ...'),
  txtDefault: i18n.t('Não existe nenhuma nota'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  fieldsOrder: [
    {
      name: 'data',
      txt: i18n.t('Ordenar pela data'),
    },
  ],
})

export default defaultState
