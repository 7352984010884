import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  searchTxt: ref(''),
  totalFieldsFilter: ref(0),
  showListviewFilter: ref(false),
  orderBy: ref({
    column: 'lastChange',
    sort: 'desc',
  }),
  txtSearchPlaceholder: i18n.t('Tipo de imóvel, imóvel'),
  txtLoading: i18n.t('A carregar qualificações ...'),
  txtDefault: i18n.t('Não tem nenhuma qualificação registada'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  fieldsOrder: [
    {
      name: 'lastChange',
      txt: i18n.t('Ordenar pela última alteração'),
    },
    {
      name: 'tipoImovel',
      txt: i18n.t('Ordenar por tipo de imóvel'),
    },
  ],
  showFormCreateNew: ref(false),
  loadingFormCreateNew: ref(false),
  formModeView: ref(false),
  formTipos: ref([]),
  formImoveis: ref([]),
  formTipologias: ref([]),
  formTipologiaEspecial: ref([]),
  formCondicaoImovel: ref([]),
  formPeriodos: ref([]),
  formRecorrerFinanciamento: ref([]),
  formObrasImovel: ref([]),
})

export default defaultState
