<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">
          <footer-step-nav ref="footerStepNav" />
        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Qualificação como vendedor') }}
              </h4>
            </div>

          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <!-- Data Qualificacao -->
          <b-row v-if="form_data.sw025s122">
            <b-col md="12">
              <b-form-group
                :label="$t('Data qualificação')"
              >
                <p class="text-primary mb-0">
                  {{ form_data.sw025s122 }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group v-if="formModeView===false">
                <b-form-checkbox
                  v-model="form_data.sw025s29"
                  value="1"
                  @change="addTipoClienteComprador"
                >
                  <span
                    v-b-tooltip.hover.righttop
                    :title="getTooltipHelp"
                  > {{ $t('Pretende comprar após venda?') }}</span>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-else
                :label="$t('Pretende comprar após venda?')"
              >
                <p class="text-primary mb-0">
                  {{ ((form_data.sw025s29 === '1') ? $t('Sim') : 'Não') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Procura vender há quanto tempo?')"
              >
                <template v-if="formModeView===false">
                  <b-input-group
                    class="input-group-merge input-with-select"
                  >
                    <b-form-input
                      v-model="form_data.sw025s97"
                      v-mask="['#####']"
                      placeholder=""
                      autocomplete="off"
                      inputmode="numeric"
                    />
                    <b-input-group-append
                      is-text
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="form_data.sw025s98"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="load_data_geral.periodos"
                        append-to-body
                        :calculate-position="withPopper"
                        :clearable="true"
                        :placeholder="$t('Escolha uma opção')"
                        label="txt"
                        item-text="txt"
                        item-value="id"
                        style="width:150px;"
                      >
                        <template #option="{ txt }">
                          {{ txt }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                    </b-input-group-append>
                  </b-input-group>
                </template>
                <template v-else>
                  <p
                    v-if="form_data.sw025s97"
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s97 }}
                    {{ form_data.sw025s98 | getTxt }}
                  </p>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    -----
                  </p>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Pretende vender dentro de quanto tempo?')"
              >
                <template v-if="formModeView===false">
                  <b-input-group
                    class="input-group-merge input-with-select"
                  >
                    <b-form-input
                      v-model="form_data.sw025s99"
                      v-mask="['#####']"
                      placeholder=""
                      autocomplete="off"
                      inputmode="numeric"
                    />
                    <b-input-group-append
                      is-text
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="form_data.sw025s100"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="load_data_geral.periodos"
                        append-to-body
                        :calculate-position="withPopper"
                        :clearable="true"
                        :placeholder="$t('Escolha uma opção')"
                        label="txt"
                        item-text="txt"
                        item-value="id"
                        style="width:150px;"
                      >
                        <template #option="{ txt }">
                          {{ txt }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                    </b-input-group-append>
                  </b-input-group>
                </template>
                <template v-else>
                  <p
                    v-if="form_data.sw025s99"
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw025s99 }}
                    {{ form_data.sw025s100 | getTxt }}
                  </p>
                  <p
                    v-else
                    class="text-primary mb-0"
                  >
                    -----
                  </p>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Está a trabalhar com alguma agência?')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw025s102"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.optionsYesNo"
                  append-to-body
                  :calculate-position="withPopper"
                  :clearable="true"
                  :placeholder="$t('Escolha uma opção')"
                  label="txt"
                  item-text="txt"
                  item-value="value"
                  @input="clearAgencyName"
                  @change="clearAgencyName"
                >
                  <template #option="{ txt }">
                    {{ txt }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s102 | getTxt }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="showAgencyName===true"
          >
            <b-col
              md="6"
            >
              <b-form-group
                :label="$t('Qual / Quais')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw025s103"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s103 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Motivo da venda')"
              >
                <b-form-input
                  v-if="formModeView===false"
                  v-model="form_data.sw025s101"
                  placeholder=""
                  autocomplete="off"
                />
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s101 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Tem ónus, penhora ou hipoteca?')"
              >
                <v-select
                  v-if="formModeView===false"
                  v-model="form_data.sw025s104"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="load_data_geral.optionsYesNo"
                  append-to-body
                  :calculate-position="withPopper"
                  :clearable="true"
                  :placeholder="$t('Escolha uma opção')"
                  label="txt"
                  item-text="txt"
                  item-value="value"
                >
                  <template #option="{ txt }">
                    {{ txt }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
                <p
                  v-else
                  class="text-primary mb-0"
                >
                  {{ form_data.sw025s104 | getTxt }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Observações face à tentativa de venda')"
              >
                <b-form-textarea
                  v-if="formModeView===false"
                  v-model="form_data.sw025s105"
                  rows="3"
                  no-resize
                />
                <!-- eslint-disable vue/no-v-html -->
                <p
                  v-else
                  class="text-primary mb-0"
                  :inner-html.prop="renderHtmlToTxt(form_data.sw025s105)"
                />
                <!--eslint-enable-->
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BFormInput, BInputGroup, BInputGroupAppend, BFormTextarea, BFormCheckbox, BContainer, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

export default {
  components: {
    VuePerfectScrollbar,
    FooterStepNav: () => import(/* webpackChunkName: "crm-footer-step-nav" */ /* webpackPrefetch: true */ './footerStepNavTop.vue'),
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BFormCheckbox,
    BContainer,
  },
  directives: {
    Ripple,
    mask,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  computed:
  {
    ...mapGetters('crm_leads_form', ['form_data', 'load_data_geral', 'formModeView', 'vars']),
    showAgencyName() {
      if ((this.form_data.sw025s102 !== null) && (this.form_data.sw025s102 !== undefined) && (this.form_data.sw025s102 !== '')) {
        if ('value' in this.form_data.sw025s102) {
          if (this.form_data.sw025s102.value === 1) {
            return true
          }
        }
      }

      return false
    },
  },
  methods: {
    clearAgencyName(row) {
      if ((row !== null) && (row !== undefined) && (row !== '')) {
        if (('value' in row) && (row !== 1)) {
          this.form_data.sw025s103 = null
        }
      }
    },
    addTipoClienteComprador(row) {
      // Adiciona o tipo de cliente "Comprador"
      if (row === '1') {
        let aSW027s03 = []
        if (Array.isArray(this.form_data.sw027s03) && (this.form_data.sw027s03.length > 0)) {
          aSW027s03 = this.form_data.sw027s03
        }
        const exists = aSW027s03.some(field => Number(field.sw022s01) === 2)

        if (!exists) {
          const oValue = this.load_data_geral.tipoClienteLead.find(o => Number(o.sw022s01) === Number(2))
          if (oValue) {
            aSW027s03.push(oValue)
          }

          this.$store.dispatch('crm_leads_form/updateFieldValue', { field: 'sw027s03', value: aSW027s03 })
        }
      }
    },
    getTooltipHelp() {
      let aSW027s03 = []
      if (Array.isArray(this.form_data.sw027s03) && (this.form_data.sw027s03.length > 0)) {
        aSW027s03 = this.form_data.sw027s03
      }
      const exists = aSW027s03.some(field => Number(field.sw022s01) === 2)

      if (!exists) {
        return this.$t('O tipo cliente COMPRADOR vai ser adicionado ao tipo de cliente')
      }

      return ''
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
