import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  searchTxt: ref(''),
  totalFieldsFilter: ref(0),
  showListviewFilter: ref(false),
  orderBy: ref({
    column: 'data',
    sort: 'desc',
  }),
  txtSearchPlaceholder: i18n.t('Filtrar por consentimento'),
  txtLoading: i18n.t('A carregar registos ...'),
  txtDefault: i18n.t('Não existe nenhuma atividade'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  fieldsOrder: [
    {
      name: 'data',
      txt: i18n.t('Ordenar pela data'),
    },
    {
      name: 'responsavel',
      txt: i18n.t('Ordenar por responsável'),
    },
    {
      name: 'consentimento',
      txt: i18n.t('Ordenar por consentimento'),
    },
  ],
  filterFieldConsentimento: [
    {
      id: 3,
      txt: i18n.t('Sem consentimento'),
    },
    {
      id: 1,
      txt: i18n.t('Com consentimento tácito'),
    },
    {
      id: 2,
      txt: i18n.t('Com consentimento explícito'),
    },
  ],
})

export default defaultState
